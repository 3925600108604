<template>
  <div class="home py-5">
    <div v-if="dataLoaded" class="sheet-container">
      <AveineWineSheet :APIbaseURL="APIbaseURL" :wine="wine" :wineUserComments="wineUserComments" :changeVintage="changeVintage" :currentUser="$auth.userProfile"/>
      <div class="text-center" v-if="wineUserComments.length">
        <v-pagination
          v-model="currentPage"
          :length="paginationLength"
          :total-visible="paginationLength < 7 ? paginationLength : 7"
        ></v-pagination>
      </div>
    </div>
    <div v-else class="d-flex justify-center align-center mt-16">
      <v-progress-circular
        indeterminate
        :size="100"
        :width="10"
        color="secondary"
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import { AveineWineSheet } from 'aveine-wine'
import { Wine, WineUserComment } from 'aveine-toolbox';
import { errorHandler } from 'aveine-toolbox'
export default {
  data () {
    return {
      wine: {},
      sortedWineVintages: [],
      wineUserComments: [],
      currentPage: this.$route.query.page,
      paginationLength: 0,
      dataLoaded: false,
      APIbaseURL: process.env.VUE_APP_API_CONSUMER_HOST
    }
  },
  name: 'Read',
  components: {
    AveineWineSheet
  },
  async created() {
    try {
      await Wine.api().get(`wines/${this.$route.params.id}?lang=${this.$i18n.locale}&origin=search`, {
        baseURL: process.env.VUE_APP_API_CONSUMER_HOST,
        params: {
          include:
            'template.grapes-composes-wine-template.grape.grape-variety,' +
            'template.appellation,' +
            'template.region,' +
            'template.country,' +
            'template.domain.region.country,' +
            'template.wines,' + 
            'grapes-composes-wine.grape.grape-variety,' +
            'wine-labels,' +
            'user-comments.user.profile,' +
            'favorite,' +
            'user-settings,' +
            'appellation',
        }
      });

      let wineUserComments = await WineUserComment.api().get(`/wines/${this.$route.params.id}/comments?lang=${this.$i18n.locale}`, {
          baseURL: process.env.VUE_APP_API_CONSUMER_HOST,
          params: {
            include: 'user.profile',
            sort: "created-at",
            page: {
              number: this.$route.params.page || 1,
              size: 50
            }
          }
        }
      );

      wineUserComments.response.data.map(async (comment) => {
        if (!comment.deleted) {
          this.wineUserComments.push(await WineUserComment.query().whereId(comment.id).withAllRecursive().first())
        }
      });
      
      let wine = await Wine.query().whereId(this.$route.params.id).withAllRecursive().first();

      let template = wine.template;
      wine.designation = template.designation;
      wine.color = template.color;
      wine.domain = template.domain;
      wine.region = template.region;
      wine.country = template.country;

      await Promise.all(wine.template.wines.map(async (vintage, index) => {
        if (!vintage.vintage) 
          wine.template.wines.splice(index, 1)
      }));

      wine.template.wines.sort((a, b) => a.vintage - b.vintage);

      this.wine = wine;
      this.dataLoaded = true;
    } catch (errors) {
      this.dataLoaded = true;
      errorHandler(errors, this)
    }
  },
  methods: {
    async changeVintage (id) {
      try {
        this.dataLoaded = false
        await Wine.api().get(`wines/${id}?lang=${this.$i18n.locale}`, {
        baseURL: process.env.VUE_APP_API_CONSUMER_HOST,
        params: {
          include:
            'template.grapes-composes-wine-template.grape.grape-variety,' +
            'template.appellation,' +
            'template.region,' +
            'template.country,' +
            'template.domain.region.country,' +
            'template.wines,' + 
            'grapes-composes-wine.grape.grape-variety,' +
            'wine-labels,' +
            'user-comments.user.profile,' +
            'favorite,' +
            'user-settings,' +
            'appellation',
        }
      });

        let wineUserComments = await WineUserComment.api().get(`/wines/${id}/comments?lang=${this.$i18n.locale}`, {
            baseURL: process.env.VUE_APP_API_CONSUMER_HOST,
            params: {
              include: 'user.profile',
              sort: "created-at",
              page: {
                number: this.$route.params.page || 1,
                size: 50
              }
            }
          }
        );

        this.wineUserComments = [];
        
        wineUserComments.response.data.map(async (comment) => {
          this.wineUserComments.push(await WineUserComment.query().whereId(comment.id).withAllRecursive().first())
        });
        
        let wine = await Wine.query().whereId(id).withAllRecursive().first();

        let template = wine.template;
        wine.designation = template.designation;
        wine.color = template.color;
        wine.domain = template.domain;
        wine.region = template.region;
        wine.country = template.country;

        await Promise.all(wine.template.wines.map(async (vintage, index) => {
          if (!vintage.vintage)
            wine.template.wines.splice(index, 1)
        }));

        wine.template.wines.sort((a, b) => a.vintage - b.vintage);

        this.wine = wine;
        this.dataLoaded = true;
      } catch (errors) {
        this.dataLoaded = true;
        errorHandler(errors, this)
      }
    }
  }
}
</script>



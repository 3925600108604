<template>
  <div class="home my-5">
		<span class="page-title">{{$t("aveine-space.title.page.mySales")}}</span>
		<v-container v-if="dataLoaded" class="wrapper">
			<div class="body">
				<div class="header">
          <v-col class="discount-code d-flex flex-column align-center mt-4">
            <p class="ma-1">{{$t("aveine-space.sales.discountCode")}}</p> <h2 class="ma-1">{{$auth.ambassador.promotion_code}}</h2>
          </v-col>
				</div>
        <div v-if="!deviceIsIos" class="d-flex flex-xl-column flex-lg-column flex-column-reverse my-10">
          <v-col class="component my-5">
            <AveineSaleCharts class="component" :APIbaseURL="APIbaseURL" :ambassadorId="$route.params.id" :discountCode="$route.params.discountCode" :selectedCurrency="selectedCurrency" />
          </v-col>
        </div>
        <div class="d-flex flex-xl-column flex-lg-column flex-column-reverse my-10">
          <v-col class="component my-5">
            <AveineSaleDetails class="component" :APIbaseURL="APIbaseURL" :selectedCurrency="selectedCurrency" :monthSalesNumber="monthSalesNumber" :yearSalesNumber="yearSalesNumber" :totalSalesNumber="totalSalesNumber" :totalIncome="totalIncome" :pendingIncome="pendingIncome" :adminMode="true"/>
          </v-col>
        </div>
			</div>
		</v-container>
    <div v-else class="d-flex justify-center align-center mt-16">
      <v-progress-circular
        indeterminate
        :size="100"
        :width="10"
        color="secondary"
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import { AveineSaleDetails, AveineSaleCharts } from 'aveine-sale'
import { errorHandler } from 'aveine-toolbox'
export default {
  data () {
    return {
      orders: [],
			/**
			 * Represent the different currencies
			 *
			 * @type {Object[]}
			 */
			currencies: [
				{
					name: 'Euros',
					code: 'EUR',
				},
				{
					name: 'Dollars',
					code: 'USD',
				}
      ],
      /**
       * The selected currency
       * 
       * @type {String}
       */
      selectedCurrency: null,
      /**
       * Flag to know if page data has been loaded
       * 
       * @type {boolean}
       */
      dataLoaded: false,
      /**
       * Store monthly sale number by product
       * 
       * @type {Object}
       */
      monthSalesNumber: Object,
      /**
       * Store yearly sale number by product
       * 
       * @type {Object}
       */
      yearSalesNumber: Object,
      /**
       * Store total sale number by product
       * 
       * @type {Object}
       */
      totalSalesNumber: Object,
      /**
       * Store the total income the current user has grossed
       * 
       * @type {Object}
       */
      totalIncome: Object,
      /**
       * Store the invoicing information of the current user
       * 
       * @type {Object}
       */
      invoiceSummary: Object,
      /**
       * Check if device is ios
       * This check is here because some iphones and ipad don't really like the chart library used for 
       * displaying graphs so i just hide them to prevent crashes
       * (also it's impossible to display the chart correctly on a tiny screen it's not a big problem it's
       * supposed to be read on a big screen)
       */
      deviceIsIos: false,
      APIbaseURL: process.env.VUE_APP_API_PRO_HOST
    }
  },
  name: 'Read',
  components: {
    AveineSaleDetails,
    AveineSaleCharts
  },
  async created() {
    try {
      this.authToken = await this.$auth.getTokenSilently();

      this.selectedCurrency = this.currencies[0].code;
      
      let startDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().split('.')[0]+"Z";
      let endDate =  new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).toISOString().split('.')[0]+"Z";

      let fetchMonthSummary = await fetch(`${process.env.VUE_APP_API_HOST}/orders/ambassadors/${this.$route.params.id}/data/${startDate}/${endDate}`, {
        method: "GET",
        headers: {
          "Authorization": `Bearer ` + this.authToken
        }
      })

      let monthSummary = await fetchMonthSummary.json();
      this.monthSalesNumber = {aveineAmount: monthSummary["total-aveine"], essentielAmount: monthSummary["total-estl"]}
      
      let currentYear = new Date().getFullYear();

      let fetchYearSummary = await fetch(`${process.env.VUE_APP_API_HOST}/orders/ambassadors/${this.$route.params.id}/data/${currentYear}`, {
        method: "GET",
        headers: {
          "Authorization": `Bearer ` + this.authToken
        }
      })

      let yearSummary = await fetchYearSummary.json();
      this.yearSalesNumber = {aveineAmount: yearSummary["total-aveine"], essentielAmount: yearSummary["total-estl"]}
      let fetchTotalSummary = await fetch(`${this.APIbaseURL}/orders/ambassadors/${this.$route.params.id}/summary`, {
        method: "GET",
        headers: {
          "Authorization": `Bearer ` + this.authToken
        }
      })

      const invoiceSummaryJson = await fetchTotalSummary.json();
      this.invoiceSummary = invoiceSummaryJson.meta;

      this.totalSalesNumber = {aveineAmount: this.invoiceSummary["total-aveine-amount"], essentielAmount: this.invoiceSummary["total-estl-amount"]}
      this.totalIncome = { 
        USD: parseInt(this.invoiceSummary["amount-paid-dollar"]),
        EUR: parseInt(this.invoiceSummary["amount-paid-euro"])
      }
      this.pendingIncome = {
        USD: parseInt(this.invoiceSummary["amount-locked-dollar"]) + parseInt(this.invoiceSummary["amount-unlocked-dollar"]) + parseInt(this.invoiceSummary["amount-pending-dollar"]),
        EUR: parseInt(this.invoiceSummary["amount-locked-euro"]) + parseInt(this.invoiceSummary["amount-unlocked-euro"]) + parseInt(this.invoiceSummary["amount-pending-euro"]),
      }

      const userAgent = navigator.userAgent || navigator.vendor || window.opera;

      if (/iPad|iPhone/.test(userAgent) && !window.MSStream) {
        this.deviceIsIos = true
      }

      this.dataLoaded = true;
    } catch (errors) {
      this.dataLoaded = true;
      errorHandler(errors, this)
    }
  }
}
</script>

<style lang="scss" scoped>
  img.currency-image {
    width: 15px;
  }
  div.wrapper {
    h1 {
      color: #1A2A4B;
      font-size: 1.6em;
    }
  
    div.body {
      background-color: #F8F8F8;
  
      div.header {
        width: 20vw;
        margin: auto;
  
        div.discount-code,
        div.currency {
          text-align: center;
        }
  
        div.discount-code {
          margin-bottom: 2vh;
          span {
            color: #E4012D;
            font-size: 2em;
          }
          @media only screen and (max-width: 600px) {
            span {
              margin-right: 35px;
            }
          }
        }
  
        div.currency {
          md-input-container {
            margin: 0;
            padding: 0;
            height: 3vh;
            font-size: 1em;
          }
        }
      }
  
  
      div.component {
        h2 {
          font-size: 1em;
        }
      }
    }
  }
</style>


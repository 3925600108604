<template>
  <div class="stepper-register-container">
    <div v-if="dataLoaded">
      <div class="d-flex align-center justify-center ma-xl-16 ma-lg-10 registration-container">
        <v-stepper class="stepper-container" v-model="currentStep">
          <v-stepper-header v-if="!errorHasOccured">
            <v-stepper-step :complete="currentStep > 1 || invitationConsumed" step="">{{$t("aveine-space.onBoarding.introduction")}}</v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="currentStep > 2 || tosAlreadySigned" step="">{{$t("aveine-space.onBoarding.tos")}}</v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="currentStep > 3 || (invitation.payment || invitation.exempted_payment) ? true : false" step="">{{$t("aveine-space.onBoarding.payment")}}</v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="currentStep > 4 || (ambassador && ambassador.name) ? true : false" step="">{{$t("aveine-space.onBoarding.accountInfo")}}</v-stepper-step>
            
            <v-divider></v-divider>
            
            <v-stepper-step :complete="currentStep > 4 || invitationConsumed" step="">{{$t("aveine-space.onBoarding.finalization")}}</v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1">
              <div class="d-flex flex-column ma-xl-16 ma-lg-10 ma-4 justify-center align-center">
                <v-icon v-if="!roleAlreadyAssigned && !invitationConsumed && !errorHasOccured" class="my-6" color="primary" size="100">mdi-note-text-outline</v-icon>
                <v-icon v-else class="my-6" color="secondary" size="100">mdi-alert-circle-outline</v-icon>
                <div class="d-flex flex-column ma-6 justify-center align-center" v-if="errorHasOccured">
                  <h3 class="text-center">
                    {{$t('aveine-space.errors.registrationError')}}<br>
                    <a href="mailto:ambassadeurs@aveine.paris">ambassadeurs@aveine.paris</a>
                  </h3>
                </div>
                <div class="d-flex flex-column ma-6 justify-center align-center" v-else-if="roleAlreadyAssigned">
                  <h3 >
                    {{$t('aveine-space.errors.roleAlreadyAssigned')}}
                  </h3>
                  <div class="text-center mx-xl-16 mx-lg-10 mb-8">
                    <a href="/">{{$t("aveine-space.onBoarding.backToIndex")}}</a>
                  </div>
                </div>
                <div class="d-flex flex-column ma-6 justify-center align-center" v-else-if="invitationConsumed">
                  <h3 >
                    {{$t('aveine-space.errors.invitationAlreadyConsumed')}}
                  </h3>
                  <div class="text-center mx-xl-16 mx-lg-10 mb-8">
                    <a href="/">{{$t("aveine-space.onBoarding.backToIndex")}}</a>
                  </div>
                </div>
                <div class="d-flex flex-column ma-6 justify-center align-center" v-else>
                  <h3 class="text-center mx-xl-16 mx-lg-10 mb-8">
                    {{$t("aveine-space.onBoarding.introText")}}
                  </h3>
                  <h3 v-if="!$auth.isAuthenticated" class="text-center mx-xl-16 mx-lg-10 mb-8">
                    {{$t("aveine-space.onBoarding.userNotLoggedIn")}}
                    <AveineLogin class="my-5"/>
                    <AveineLogin class="my-5" :signingUp="true"/>
                  </h3>
                </div>
              </div>
              <div class="d-flex justify-end" v-if="!roleAlreadyAssigned && !invitationConsumed && !errorHasOccured">
                <v-btn
                  :disabled="!$auth.isAuthenticated"
                  class="ma-6"
                  color="primary"
                  @click="currentStep += 1"
                >
                  {{$t("aveine-space.onBoarding.next")}}
                </v-btn>
              </div>
            </v-stepper-content>
            <v-stepper-content step="2">
              <div class="d-flex flex-column">
                <div class="d-flex flex-row align-center contract">
                  <iframe id="contract-iframe" :src="latestTos.name"></iframe>
                </div>
                <div class="d-flex justify-start">
                  <v-checkbox v-model="agreedToTos" :label="$t('aveine-space.onBoarding.agreeTos')"/>
                </div>
              </div>
              <div class="d-flex justify-space-between">
                <v-btn raised
                  text
                  class="ma-6"
                  color="primary"
                  @click="currentStep -= 1">
                  {{$t("aveine-space.onBoarding.back")}}
                </v-btn>

                <v-btn
                  :disabled="!agreedToTos"
                  class="ma-6 justify-end"
                  color="primary"
                  @click="agreeTos"
                >
                  {{$t("aveine-space.onBoarding.next")}}
                </v-btn>
              </div>
            </v-stepper-content>

            <v-stepper-content step="3" v-if="$auth.isAuthenticated && !invitation.exemptedPayment">
              <div class="d-flex flex-column ma-4 justify-center align-center">
                <v-icon class="my-6" color="primary" size="100">mdi-package-variant</v-icon>
                <p class="text-center">{{$t("aveine-space.onBoarding.storeSelection")}} </p>
                <div class="d-flex flex-column step-content">
                  <div class="d-flex justify-center my-6">
                    <v-btn target="_blank" :href="`${shopifyStoreDomain}/cart/${shopifyStoreItem}:1`">{{ $t("aveine-space.onBoarding.checkout") }}</v-btn>
                  </div>
                  <br>
                  <p class="text-center">{{$t("aveine-space.onBoarding.alreadyHaveAPurchaseCode")}}</p>
                  <v-form class="d-flex justify-center align-center" v-model="isFormValid">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <div class="d-flex justify-center align-center flex-shrink-0">
                          <v-text-field
                            class="mx-16"
                            :label="$t('aveine-space.onBoarding.purchaseCode')"
                            v-model="invitation.payment"
                            v-bind="attrs"
                            v-on="on"
                            :rules="[v => !!v || $t('aveine-space.errors.inputRequired')]"
                          ></v-text-field>
                        </div>

                      </template>
                      <span>{{$t("aveine-space.onBoarding.purchaseCodeToolTip")}}</span>
                    </v-tooltip>
                  </v-form>
                </div>
              </div>
              <div class="d-flex justify-space-between">
                <v-btn raised
                  text
                  class="ma-6"
                  color="primary"
                  @click="currentStep -= 1">
                  {{$t("aveine-space.onBoarding.back")}}
                </v-btn>

                <v-btn
                  class="ma-6"
                  color="primary"
                  @click="validatePurchaseCode"
                  :disabled="!isFormValid"
                >
                  {{$t("aveine-space.onBoarding.next")}}
                </v-btn>
              </div>
            </v-stepper-content>

            <v-stepper-content v-if="$auth.isAuthenticated" step="4">
              <div class="d-flex flex-column ma-xl-6">
                <div class="d-flex flex-column justify-center align-center my-6">
                  <v-icon class="my-4" color="primary" size="100">mdi-card-account-details-outline</v-icon>
                  <p class="text-center">{{$t("aveine-space.onBoarding.personalInfoText")}} </p>
                </div>
                <AveineAmbassadorForm
                  v-if="$route.params.invitation_type == 'ambassador'"
                  :APIbaseURL="APIbaseURL"
                  :ambassador="ambassador"
                  :callback="savedInformations"
                  :registerInvitation="invitation"
                />
              </div>
              <div>
                <v-btn raised
                  text
                  class="ma-6"
                  color="primary"
                  @click="currentStep -= 1">
                  {{$t("aveine-space.onBoarding.back")}}
                </v-btn>
              </div>
            </v-stepper-content>

            <v-stepper-content step="5" v-if="$auth.isAuthenticated">
              <div class="ma-8 d-flex flex-column justify-center step-content">
                <v-icon size="64" color="primary">mdi-check-circle</v-icon>
                <h4 class="text-center">{{$t("aveine-space.onBoarding.signInSuccess")}}</h4>
                <div class="d-flex flex-column justify-center">
                  <a class="text-center" href="/">{{$t("aveine-space.onBoarding.backToIndex")}}</a>
                </div>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </div>
    </div>
    <div v-else class="d-flex justify-center align-center mt-16">
      <v-progress-circular
        indeterminate
        :size="100"
        :width="10"
        color="secondary"
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
  import { Invitation, Tos, Ambassador } from 'aveine-toolbox';
  import { errorHandler } from 'aveine-toolbox'
  import { AveineLogin } from 'aveine-toolbox'
  import { AveineAmbassadorForm } from 'aveine-ambassador'
  const originalFetch = window.fetch;
  export default {
    name: 'registration',
    components: {
      AveineLogin,
      AveineAmbassadorForm
    },
    data () {
      return {
        /**
         * The invitation currently being consumed
         * 
         * @type {Object}
         */
        invitation: {},
        /**
         * The ambassador ressource associated with the invitation
         * 
         * @type {Object}
         */
        ambassador: {},
        /**
         * The latest terms of service to sign
         * 
         * @type {Object}
         */
        latestTos: {},
        /**
         * Flag determining if the user has already agreed to the tos in the past
         * 
         * @type {Boolean}
         */
        tosAlreadySigned: false,
        /**
         * Flag determining if the user has agreed to the term of services
         * 
         * @type {Boolean}
         */
        agreedToTos: false,
        /**
         * Internal state to tell if the user is trying to register for a role he already has
         *
         * @type {boolean}
         */
        roleAlreadyAssigned: false,
        /**
         * Internal state to tell if the invitation the user is trying to use has already been consumed
         *
         * @type {boolean}
         */
        invitationConsumed: false,
        /**
         * Track stepper current step
         *
         * @type {number}
         */
        currentStep: 1,
        /**
         * Flag to determine if the shopify store button is loading to display a loading animation
         * 
         * @type {Boolean}
         */
        storeButtonLoading: false,
        /**
         * Tracking current shopify store selected
         *
         * @type {String}
         */
        shopifyStore: String,
        /**
         * Internal state to know when to display the payment step
         *
         * @type {boolean}
         */
        showPaymentStep: Boolean,
        /**
         * Flag determining if the user has already payed for his ambassador kit before
         * 
         * @type {Boolean}
         */
        paymentAlreadyDone: false,
        /**
         * Stores the checkout token got from the shopify checkout process
         * 
         * @type {String}
         */
        checkoutToken: "",
        /**
         * Regular expression to extract purchase code from a checkoutCreate response
         *
         * @type {RegExp}
         */
        checkoutTokenRegex: /gid:\/\/shopify\/Checkout\/(?<checkoutToken>[^?|/]+)/,
        /**
         * Regular expression to extract purchase code from a shopify shop from checkoutCreate response
         *
         * @type {RegExp}
         */
        webUrlRegex: /https:\/\/(?<shop>[^.]+).myshopify.com/,
        /**
         * Flag to determine if the form is correcly filled
         * 
         * @type {Boolean}
         */
        isFormValid: false,
        /**
         * Internal state to tell if any error has been catched in the view create method
         * 
         * @type {Boolean}
         */
        errorHasOccured: false,
        /**
         * Flag to determine if all of the route related data has been loaded
         * 
         * @type {Boolean}
         */
        dataLoaded: false,
        APIbaseURL: process.env.VUE_APP_API_PRO_HOST
      }
    },
    computed: {
      /**
       * computed of the shopify domain name
       * @return {Object}
       */
      shopifyStoreDomain() {
        return `http://${process.env['VUE_APP_SHOPIFY_DOMAIN_AVEINE']}`
      },
      /**
       * computed of the shopify domain name
       * @return {Object}
       */
       shopifyStoreItem() {
        return process.env[`VUE_APP_SHOPIFY_PRODUCT_ID_AVEINE`]
      }
    },
    watch: {
      /**
       * Update terms of services (called on language changes)
       */
      '$i18n.locale': async function() {
        const fetchTos = await Tos.api().get(`/tos/ambassador/latest?lang=${this.$i18n.locale}`, null, {baseURL: process.env.VUE_APP_API_PRO_HOST});
        const updatedTos = await Tos.query().whereId(fetchTos.entities.tos[0].id).withAllRecursive().first();
        document.getElementById('contract-iframe').src = updatedTos.name;
        this.latestTos.name = updatedTos.name;
      },
    },

    async created () {
      try {
        if (this.$route.query.lang == "en") {
          this.i18n.locale = 'en';
        }
        if (this.$auth.isAuthenticated) {
          // in the case where the user has started the registration process for a new role but didn't finish it check if the auth0 role has been assigned or not
          if (this.$auth.ambassador && this.$auth.ambassador.id){
            this.roleAlreadyAssigned = true;
          } else {
            const invitationRequest = await Invitation.api().get(`invitations/${this.$route.params.id}?lang=${this.$i18n.locale}`, { baseURL: process.env.VUE_APP_API_PRO_HOST, params: {
              include: 'ambassador.address.country,address.spatial-information,' +
              'ambassador.company.company-contact,company.address.spatial-information'
            }});
            this.invitationConsumed = invitationRequest.response.data.meta["already-consumed"]
            this.invitation = await Invitation.query().whereId(this.$route.params.id).withAllRecursive().first();

            if (this.invitation.entity_id) {
              const ambassadorRequest = await Ambassador.api().get(`ambassadors/${this.invitation.entity_id}?lang=${this.$i18n.locale}`, { baseURL: process.env.VUE_APP_API_PRO_HOST, params: {
                include: 'address.country,address.spatial-information,' +
                'company.company-contact,company.address.spatial-information',
              }});
              this.ambassador = await Ambassador.query().whereId(ambassadorRequest.entities.ambassadors[0].id).withAllRecursive().first();
            } else {
              this.ambassador = await Ambassador.new()
              this.ambassador.$isNew = true;
            }

            const tosRequest = await Tos.api().get(`tos/ambassador/latest?lang=${this.$i18n.locale}`, {baseURL: process.env.VUE_APP_API_PRO_HOST});
            this.latestTos = await Tos.query().whereId(tosRequest.entities.tos[0].id).withAllRecursive().first();

            if (tosRequest.response.data.meta["has-signed-latest-tos"]) {
              this.tosAlreadySigned = true;
              this.agreedToTos = true;
            }

            if (!this.invitation.exemptedPayment) {
              /**
               * Soooo
               * A lot to say here
               * We're basicaly hijacking the fetch method to be specifically able de catch fetch requests done by the shopify 
               * buy button library in the checkout process
               * We're trying to catch reponses matching a response with a checkout object and decoding it to get the checkout token
               * We then add an eventlistener (the same event listener used in the buy button library) the is fired when changing pages
               * in the checkoutprocess, the event listener fires the makeFinalizePayment() function which checks if the current page
               * is the "thank you for your purchase" page, if it is it'll copy the checkout token to the payment field of the invitation
               * and will go to the next step automatically
               */
              window.fetch = (input, init) => {
                return originalFetch(input, init).then(response => {
                  return new Promise((resolve) => {
                    response.clone().json().then(json => {
                      if (json.data && json.data.checkoutCreate) {
                        try {
                          const id = json.data.checkoutCreate.checkout.id;
                          const decodedCheckoutToken = atob(id);
                          this.checkoutToken = this.checkoutTokenRegex.exec(decodedCheckoutToken).groups.checkoutToken;
                        } catch (e) {
                          console.error(e)
                        }
                      }
                      resolve(response)
                    });
                  });
                });
              };
              window.addEventListener('message', this.makeFinalizePayment());
            } else if (this.invitation.payment) {
              this.paymentAlreadyDone = true
            }
          }
        }
        this.dataLoaded = true;
      } catch (errors) {
        errorHandler(errors, this)
        this.errorHasOccured = true;
        this.dataLoaded = true;
      }
    },
    methods: {
      /**
       * Callback called when the user has agreed to the latest terms of service
       */
      async agreeTos() {
        try {
          await Tos.api().post(`/tos/${this.latestTos.id}/sign?lang=${this.$i18n.locale}`, null, {baseURL: process.env.VUE_APP_API_PRO_HOST});
          (this.invitation.payment || this.invitation.exempted_payment) ? this.currentStep += 2 : this.currentStep += 1
        } catch (errors) {
          errorHandler(errors, this)
        }
      },
      /**
       * Callback called when the user has saved his informations
       */
      async savedInformations() {
        try {
          await Invitation.api().post(`/invitations/${this.invitation.id}/finalize?lang=${this.$i18n.locale}`, null, {baseURL: process.env.VUE_APP_API_PRO_HOST});
          this.currentStep = this.currentStep + 1;
        } catch (errors) {
          errorHandler(errors, this)
        }
      },
      /**
       * Callback called when the user finalize the payment step
       */
      async validatePurchaseCode() {
        try {
          if (!this.paymentAlreadyDone) {
            await Invitation.api().post(`/invitations/${this.invitation.id}/payment/${this.invitation.payment}?lang=${this.$i18n.locale}`, null, {baseURL: process.env.VUE_APP_API_PRO_HOST});
            await Tos.api().post(`/tos/${this.latestTos.id}/sign?lang=${this.$i18n.locale}`, null, {baseURL: process.env.VUE_APP_API_PRO_HOST});
          }
          this.shopifyStore = undefined;
          this.currentStep = this.currentStep + 1;
        } catch (errors) {
          errorHandler(errors, this)
        }
      },
      /**
       * Init a callback with component context
       */
      makeFinalizePayment() {
        return async (msg) => {
          if (msg && msg.data) {
            let data;
            try {
              data = await JSON.parse(msg.data);
            } catch (err) {
              data = {};
            }
            if (data.syncCart || data.current_checkout_page === null || data.current_checkout_page === '/checkout/thank_you') {
              this.invitation.payment = this.checkoutToken;
              this.validatePurchaseCode();
            }
          }
        }
      }
    }
  }
</script>
<style lang="scss">
  div.stepper-register-container {
    min-height: 95vh !important;
    margin-top: 0px !important;
    div.registration-container {
      margin-top: 3vw !important;
      background-color: #ffffff;
      .stepper-container {
        width: 80vw;
      }
      button.login-btn {
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
        min-height: 36px!important;
        min-width: 88px!important;
        text-transform: uppercase!important;
        font-weight: 500!important;
        font-size: 14px!important;
        color: #333!important;
        background-color: #fff!important;
      }
      button.login-btn:focus {
        background-color: #fcfcfc;
      }
      button.login-btn:hover {
        background-color: #fcfcfc;
      }
      button.login-btn:active {
        background-color: #fcfcfc;
      }
    }
  }

  @media screen and (max-width: 960px) {
  div.registration-container {
    margin-left: 1vw !important;
  }
  .stepper-container {
    width: 98vw !important;
    padding-bottom: 5vh;
  }

  .v-stepper__content {
    padding: 10px !important;
  }

  div.contract #contract-iframe {
    width: 100vw !important;
    padding: 0 !important;
  }
  md-steppers-header {
    button:not(.md-active) {
     display: none; 
    }
  }
}
</style>


<template>
  <div class="home py-5">
    <div v-if="dataLoaded" class="sheet-container">
      <AveineUserSheet :APIbaseURL="APIbaseURL" :consumerAPIbaseURL="APIbaseURL" :userProfile="userProfile" :isSelf="isSelf"/>
    </div>
    <div v-else class="d-flex justify-center align-center mt-16">
      <v-progress-circular
        indeterminate
        :size="100"
        :width="10"
        color="secondary"
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import { AveineUserSheet } from 'aveine-user'
import { User, UserProfile } from 'aveine-toolbox';
import { errorHandler } from 'aveine-toolbox'
export default {
  data () {
    return {
      userProfile: {},
      userFollows: [],
      userFollowers: [],
      userFollowable: false,
      userFollowed: false,
      dataLoaded: false,
      isSelf: false,
      APIbaseURL: process.env.VUE_APP_API_CONSUMER_HOST,
    }
  },
  components: {
    AveineUserSheet
  },
  name: 'Read',
  methods: {
    async loadData() {
      try {
        this.authToken = await this.$auth.getTokenSilently();
        let userProfileReq;
        if (this.$route.params.id == "self") {
          userProfileReq = await User.api().get(`/users/self?lang=${this.$i18n.locale}`,{
            baseURL: this.APIbaseURL,
            params: {
              include: "profile"
            }
          });
        } else {
          userProfileReq = await UserProfile.api().get(`/users/${this.$route.params.id}/profile?lang=${this.$i18n.locale}`,{
            baseURL: this.APIbaseURL,
            params: {
              include: "profile"
            }
          });
        }

        this.userProfile = await UserProfile.query().whereId(userProfileReq.entities.user_profiles[0].$id).withAllRecursive().first();
        if (this.$route.params.id !== "self") {
          this.userProfile.user = {id: this.$route.params.id}
        }

        if (this.userProfile.id == this.$auth.userProfile.id) {
          this.isSelf = true
        }


        if (this.$route.params.id == "self") {
          let followedReq = await UserProfile.api().get(`/users/${this.$route.params.id}/follows`, {
            baseURL: this.APIbaseURL,
          })

          followedReq.response.data.map(async (follow) => {
            this.userFollows.push(await UserProfile.query().whereId(follow.id).withAllRecursive().first());
          });
          

          const userFollowersReq = await User.api().get(`users/${this.$route.params.id}/followers?lang=${this.$i18n.locale}`, {baseURL: this.APIbaseURL});
          const userFollowsReq = await User.api().get(`users/${this.$route.params.id}/follows?lang=${this.$i18n.locale}`, {baseURL: this.APIbaseURL});

          this.userProfile.follow_count = parseInt(userFollowsReq.response.data.meta.total)
          this.userProfile.follower_count = parseInt(userFollowersReq.response.data.meta.total)
        }
        this.dataLoaded = true;
      } catch (errors) {
        this.dataLoaded = true;
        errorHandler(errors, this)
      }
    }
  },
  async created() {
    this.loadData()
  },
}
</script>



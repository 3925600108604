import Vue from 'vue'
import axios from 'axios'
import { Vuex, VuexORM, database } from "aveine-toolbox"
import VuexORMAxios from '@vuex-orm/plugin-axios'
import VuexORMisDirtyPlugin from '@vuex-orm/plugin-change-flags';
import { dataNormalizer } from 'aveine-toolbox';

Vue.use(Vuex)
const headers = {}

// Initializing VuexORM
VuexORM.use(VuexORMAxios,
  {
  axios,
  apiRoot: "/",
  baseURL: process.env.VUE_APP_API_CONSUMER_HOST,
  headers,
  transformResponse: (data) => {
    return Object.keys(data).length !== 0 ? dataNormalizer.documentToModel(JSON.parse(data)): null;
  },
  validateStatus: function (status) {
    if (status === 401) {
      localStorage.routeToRedirectTo = window.location.pathname;
      Vue.prototype.$auth.logout({
        returnTo: window.location.origin + "/login"
      });
    }
    return status >= 200 && status < 300; // default
  }
})

// To do : See how to use this correctly or replacing it
VuexORM.use(VuexORMisDirtyPlugin);

export { headers }

export const store = new Vuex.Store({
  plugins: [VuexORM.install(database)]
})


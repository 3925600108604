var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home py-5"},[(_vm.dataLoaded)?_c('div',{staticClass:"sheet-container"},[_c('v-container',{staticClass:"d-flex flex-row align-center justify-center mb-2 flex-wrap"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-card',_vm._g(_vm._b({staticClass:"d-flex flex-column ma-5 badge",attrs:{"outlined":"","tile":""}},'v-card',attrs,false),on),[(_vm.shelvePosition1)?_c('v-btn',{staticStyle:{"position":"absolute","padding-bottom":"15px","padding-right":"15px"},attrs:{"icon":""},on:{"click":function($event){_vm.shelvePosition1 = null; _vm.shelveUpdated = true}}},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e(),(_vm.shelvePosition1)?_c('v-img',{attrs:{"src":require(("@/assets/badges/" + _vm.shelvePosition1 + ".png"))}}):_vm._e()],1)]}}],null,false,2106583833)},[(_vm.shelvePosition1)?_c('span',[_vm._v(_vm._s(_vm.$t(("aveine-space.badges." + _vm.shelvePosition1))))]):_vm._e()]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-card',_vm._g(_vm._b({staticClass:"d-flex flex-column ma-5 badge",attrs:{"outlined":"","tile":""}},'v-card',attrs,false),on),[(_vm.shelvePosition2)?_c('v-btn',{staticStyle:{"position":"absolute","padding-bottom":"15px","padding-right":"15px"},attrs:{"icon":""},on:{"click":function($event){_vm.shelvePosition2 = null; _vm.shelveUpdated = true}}},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e(),(_vm.shelvePosition2)?_c('v-img',{attrs:{"src":require(("@/assets/badges/" + _vm.shelvePosition2 + ".png"))}}):_vm._e()],1)]}}],null,false,3592955801)},[(_vm.shelvePosition2)?_c('span',[_vm._v(_vm._s(_vm.$t(("aveine-space.badges." + _vm.shelvePosition2))))]):_vm._e()]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-card',_vm._g(_vm._b({staticClass:"d-flex flex-column ma-5 badge",attrs:{"outlined":"","tile":""}},'v-card',attrs,false),on),[(_vm.shelvePosition3)?_c('v-btn',{staticStyle:{"position":"absolute","padding-bottom":"15px","padding-right":"15px"},attrs:{"icon":""},on:{"click":function($event){_vm.shelvePosition3 = null; _vm.shelveUpdated = true}}},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e(),(_vm.shelvePosition3)?_c('v-img',{attrs:{"src":require(("@/assets/badges/" + _vm.shelvePosition3 + ".png"))}}):_vm._e()],1)]}}],null,false,4150260121)},[(_vm.shelvePosition3)?_c('span',[_vm._v(_vm._s(_vm.$t(("aveine-space.badges." + _vm.shelvePosition3))))]):_vm._e()])],1),_c('v-container',{staticClass:"d-flex justify-center mb-3"},[(_vm.shelveUpdated)?_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.submitBadgeShowcase}},[_vm._v(_vm._s(_vm.$t("aveine-space.profile.button.submit")))]):_vm._e()],1),_c('v-container',{staticClass:"d-flex flex-row mb-10 flex-wrap badge-container"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',[_c('v-card',_vm._g(_vm._b({staticClass:"d-flex flex-column ma-5 badge",attrs:{"outlined":"","tile":""}},'v-card',attrs,false),on),[_c('v-img',{class:_vm.$auth.userProfile.badge.aerator == true ? 'active' : 'inactive',attrs:{"src":require("@/assets/badges/aerator.png")}})],1),(_vm.$auth.userProfile.badge.aerator == true)?_c('v-btn',{staticStyle:{"font-size":"10px"},attrs:{"disabled":_vm.badgeAlreadyOnShowcase('aerator'),"outlined":""},on:{"click":function($event){return _vm.addBadgeToShowcase('aerator')}}},[_vm._v(_vm._s(_vm.$t("aveine-space.profile.button.showcase")))]):_vm._e()],1)]}}],null,false,3238319390)},[_c('span',[_vm._v(_vm._s(_vm.$t("aveine-space.badges.aerator_text")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',[_c('v-card',_vm._g(_vm._b({staticClass:"d-flex flex-column ma-5 badge",attrs:{"outlined":"","tile":""}},'v-card',attrs,false),on),[_c('v-img',{class:_vm.$auth.userProfile.badge.aerator_rename == true ? 'active' : 'inactive',attrs:{"src":require("@/assets/badges/aerator_rename.png")}})],1),(_vm.$auth.userProfile.badge.aerator_rename == true)?_c('v-btn',{staticStyle:{"font-size":"10px"},attrs:{"disabled":_vm.badgeAlreadyOnShowcase('aerator_rename'),"outlined":""},on:{"click":function($event){return _vm.addBadgeToShowcase('aerator_rename')}}},[_vm._v(_vm._s(_vm.$t("aveine-space.profile.button.showcase")))]):_vm._e()],1)]}}],null,false,13281777)},[_c('span',[_vm._v(_vm._s(_vm.$t("aveine-space.badges.aerator_rename_text")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',[_c('v-card',_vm._g(_vm._b({staticClass:"d-flex flex-column ma-5 badge",attrs:{"outlined":"","tile":""}},'v-card',attrs,false),on),[_c('v-img',{class:_vm.$auth.userProfile.badge.tasting_profile == true ? 'active' : 'inactive',attrs:{"src":require("@/assets/badges/tasting_profile.png")}})],1),(_vm.$auth.userProfile.badge.tasting_profile == true)?_c('v-btn',{staticStyle:{"font-size":"10px"},attrs:{"disabled":_vm.badgeAlreadyOnShowcase('tasting_profile'),"outlined":""},on:{"click":function($event){return _vm.addBadgeToShowcase('tasting_profile')}}},[_vm._v(_vm._s(_vm.$t("aveine-space.profile.button.showcase")))]):_vm._e()],1)]}}],null,false,1560681798)},[_c('span',[_vm._v(_vm._s(_vm.$t("aveine-space.badges.tasting_profile_text")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',[_c('v-card',_vm._g(_vm._b({staticClass:"d-flex flex-column ma-5 badge",attrs:{"outlined":"","tile":""}},'v-card',attrs,false),on),[_c('v-img',{class:_vm.$auth.userProfile.badge.scan_red_level >= 1 ? 'active' : 'inactive',attrs:{"src":require("@/assets/badges/scan_red_level_1.png")}})],1),(((_vm.$auth.userProfile.badge.scan_red_progression * 100) / 1) < 100)?_c('v-progress-linear',{staticStyle:{"width":"80%","margin":"auto"},attrs:{"value":(_vm.$auth.userProfile.badge.scan_red_progression * 100) / 1,"color":"primary","height":"15"}},[[_c('span',[_vm._v(_vm._s(_vm.$auth.userProfile.badge.scan_red_progression)+"/1")])]],2):_c('v-btn',{staticStyle:{"font-size":"10px"},attrs:{"disabled":_vm.badgeAlreadyOnShowcase('scan_red_level_1'),"outlined":""},on:{"click":function($event){return _vm.addBadgeToShowcase('scan_red_level_1')}}},[_vm._v(_vm._s(_vm.$t("aveine-space.profile.button.showcase")))])],1)]}}],null,false,365207428)},[_c('span',[_vm._v(_vm._s(_vm.$t("aveine-space.badges.scan_red_level_1_text")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',[_c('v-card',_vm._g(_vm._b({staticClass:"d-flex flex-column ma-5 badge",attrs:{"outlined":"","tile":""}},'v-card',attrs,false),on),[_c('v-img',{class:_vm.$auth.userProfile.badge.scan_red_level >= 2 ? 'active' : 'inactive',attrs:{"src":require("@/assets/badges/scan_red_level_2.png")}})],1),(((_vm.$auth.userProfile.badge.scan_red_progression * 100) / 50) < 100)?_c('v-progress-linear',{staticStyle:{"width":"80%","margin":"auto"},attrs:{"value":(_vm.$auth.userProfile.badge.scan_red_progression * 100) / 50,"color":"blue-grey","height":"15"}},[[_c('span',[_vm._v(_vm._s(_vm.$auth.userProfile.badge.scan_red_progression)+"/50")])]],2):_c('v-btn',{staticStyle:{"font-size":"10px"},attrs:{"disabled":_vm.badgeAlreadyOnShowcase('scan_red_level_2'),"outlined":""},on:{"click":function($event){return _vm.addBadgeToShowcase('scan_red_level_2')}}},[_vm._v(_vm._s(_vm.$t("aveine-space.profile.button.showcase")))])],1)]}}],null,false,921600454)},[_c('span',[_vm._v(_vm._s(_vm.$t("aveine-space.badges.scan_red_level_2_text")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',[_c('v-card',_vm._g(_vm._b({staticClass:"d-flex flex-column ma-5 badge",attrs:{"outlined":"","tile":""}},'v-card',attrs,false),on),[_c('v-img',{class:_vm.$auth.userProfile.badge.scan_red_level >= 3 ? 'active' : 'inactive',attrs:{"src":require("@/assets/badges/scan_red_level_3.png")}})],1),(((_vm.$auth.userProfile.badge.scan_red_progression * 100) / 100) < 100)?_c('v-progress-linear',{staticStyle:{"width":"80%","margin":"auto"},attrs:{"value":(_vm.$auth.userProfile.badge.scan_red_progression * 100) / 100,"color":"blue-grey","height":"15"}},[[_c('span',[_vm._v(_vm._s(_vm.$auth.userProfile.badge.scan_red_progression)+"/100")])]],2):_c('v-btn',{staticStyle:{"font-size":"10px"},attrs:{"disabled":_vm.badgeAlreadyOnShowcase('scan_red_level_3'),"outlined":""},on:{"click":function($event){return _vm.addBadgeToShowcase('scan_red_level_3')}}},[_vm._v(_vm._s(_vm.$t("aveine-space.profile.button.showcase")))])],1)]}}],null,false,894557970)},[_c('span',[_vm._v(_vm._s(_vm.$t("aveine-space.badges.scan_red_level_3_text")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',[_c('v-card',_vm._g(_vm._b({staticClass:"d-flex flex-column ma-5 badge",attrs:{"outlined":"","tile":""}},'v-card',attrs,false),on),[_c('v-img',{class:_vm.$auth.userProfile.badge.scan_red_level >= 4 ? 'active' : 'inactive',attrs:{"src":require("@/assets/badges/scan_red_level_4.png")}})],1),(((_vm.$auth.userProfile.badge.scan_red_progression * 100) / 500) < 100)?_c('v-progress-linear',{staticStyle:{"width":"80%","margin":"auto"},attrs:{"value":(_vm.$auth.userProfile.badge.scan_red_progression * 100) / 500,"color":"blue-grey","height":"15"}},[[_c('span',[_vm._v(_vm._s(_vm.$auth.userProfile.badge.scan_red_progression)+"/500")])]],2):_c('v-btn',{staticStyle:{"font-size":"10px"},attrs:{"disabled":_vm.badgeAlreadyOnShowcase('scan_red_level_4'),"outlined":""},on:{"click":function($event){return _vm.addBadgeToShowcase('scan_red_level_4')}}},[_vm._v(_vm._s(_vm.$t("aveine-space.profile.button.showcase")))])],1)]}}],null,false,354222198)},[_c('span',[_vm._v(_vm._s(_vm.$t("aveine-space.badges.scan_red_level_4_text")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',[_c('v-card',_vm._g(_vm._b({staticClass:"d-flex flex-column ma-5 badge",attrs:{"outlined":"","tile":""}},'v-card',attrs,false),on),[_c('v-img',{class:_vm.$auth.userProfile.badge.scan_white_level >= 1 ? 'active' : 'inactive',attrs:{"src":require("@/assets/badges/scan_white_level_1.png")}})],1),(((_vm.$auth.userProfile.badge.scan_white_progression * 100) / 1) < 100)?_c('v-progress-linear',{staticStyle:{"width":"80%","margin":"auto"},attrs:{"value":(_vm.$auth.userProfile.badge.scan_white_progression * 100) / 1,"color":"blue-grey","height":"15"}},[[_c('span',[_vm._v(_vm._s(_vm.$auth.userProfile.badge.scan_white_progression)+"/1")])]],2):_c('v-btn',{staticStyle:{"font-size":"10px"},attrs:{"disabled":_vm.badgeAlreadyOnShowcase('scan_white_level_1'),"outlined":""},on:{"click":function($event){return _vm.addBadgeToShowcase('scan_white_level_1')}}},[_vm._v(_vm._s(_vm.$t("aveine-space.profile.button.showcase")))])],1)]}}],null,false,3163329766)},[_c('span',[_vm._v(_vm._s(_vm.$t("aveine-space.badges.scan_white_level_1_text")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',[_c('v-card',_vm._g(_vm._b({staticClass:"d-flex flex-column ma-5 badge",attrs:{"outlined":"","tile":""}},'v-card',attrs,false),on),[_c('v-img',{class:_vm.$auth.userProfile.badge.scan_white_level >= 2 ? 'active' : 'inactive',attrs:{"src":require("@/assets/badges/scan_white_level_2.png")}})],1),(((_vm.$auth.userProfile.badge.scan_white_progression * 100) / 50) < 100)?_c('v-progress-linear',{staticStyle:{"width":"80%","margin":"auto"},attrs:{"value":(_vm.$auth.userProfile.badge.scan_white_progression * 100) / 50,"color":"blue-grey","height":"15"}},[[_c('span',[_vm._v(_vm._s(_vm.$auth.userProfile.badge.scan_white_progression)+"/50")])]],2):_c('v-btn',{staticStyle:{"font-size":"10px"},attrs:{"disabled":_vm.badgeAlreadyOnShowcase('scan_white_level_2'),"outlined":""},on:{"click":function($event){return _vm.addBadgeToShowcase('scan_white_level_2')}}},[_vm._v(_vm._s(_vm.$t("aveine-space.profile.button.showcase")))])],1)]}}],null,false,226886450)},[_c('span',[_vm._v(_vm._s(_vm.$t("aveine-space.badges.scan_white_level_2_text")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',[_c('v-card',_vm._g(_vm._b({staticClass:"d-flex flex-column ma-5 badge",attrs:{"outlined":"","tile":""}},'v-card',attrs,false),on),[_c('v-img',{class:_vm.$auth.userProfile.badge.scan_white_level >= 3 ? 'active' : 'inactive',attrs:{"src":require("@/assets/badges/scan_white_level_3.png")}})],1),(((_vm.$auth.userProfile.badge.scan_white_progression * 100) / 100) < 100)?_c('v-progress-linear',{staticStyle:{"width":"80%","margin":"auto"},attrs:{"value":(_vm.$auth.userProfile.badge.scan_white_progression * 100) / 100,"color":"blue-grey","height":"15"}},[[_c('span',[_vm._v(_vm._s(_vm.$auth.userProfile.badge.scan_white_progression)+"/100")])]],2):_c('v-btn',{staticStyle:{"font-size":"10px"},attrs:{"disabled":_vm.badgeAlreadyOnShowcase('scan_white_level_3'),"outlined":""},on:{"click":function($event){return _vm.addBadgeToShowcase('scan_white_level_3')}}},[_vm._v(_vm._s(_vm.$t("aveine-space.profile.button.showcase")))])],1)]}}],null,false,3181604774)},[_c('span',[_vm._v(_vm._s(_vm.$t("aveine-space.badges.scan_white_level_3_text")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',[_c('v-card',_vm._g(_vm._b({staticClass:"d-flex flex-column ma-5 badge",attrs:{"outlined":"","tile":""}},'v-card',attrs,false),on),[_c('v-img',{class:_vm.$auth.userProfile.badge.scan_white_level >= 4 ? 'active' : 'inactive',attrs:{"src":require("@/assets/badges/scan_white_level_4.png")}})],1),(((_vm.$auth.userProfile.badge.scan_white_progression * 100) / 500) < 100)?_c('v-progress-linear',{staticStyle:{"width":"80%","margin":"auto"},attrs:{"value":(_vm.$auth.userProfile.badge.scan_white_progression * 100) / 500,"color":"blue-grey","height":"15"}},[[_c('span',[_vm._v(_vm._s(_vm.$auth.userProfile.badge.scan_white_progression)+"/500")])]],2):_c('v-btn',{staticStyle:{"font-size":"10px"},attrs:{"disabled":_vm.badgeAlreadyOnShowcase('scan_white_level_4'),"outlined":""},on:{"click":function($event){return _vm.addBadgeToShowcase('scan_white_level_4')}}},[_vm._v(_vm._s(_vm.$t("aveine-space.profile.button.showcase")))])],1)]}}],null,false,408039810)},[_c('span',[_vm._v(_vm._s(_vm.$t("aveine-space.badges.scan_white_level_4_text")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',[_c('v-card',_vm._g(_vm._b({staticClass:"d-flex flex-column ma-5 badge",attrs:{"outlined":"","tile":""}},'v-card',attrs,false),on),[_c('v-img',{class:_vm.$auth.userProfile.badge.scan_rose_level >= 1 ? 'active' : 'inactive',attrs:{"src":require("@/assets/badges/scan_rose_level_1.png")}})],1),(((_vm.$auth.userProfile.badge.scan_rose_progression * 100) / 1) < 100)?_c('v-progress-linear',{staticStyle:{"width":"80%","margin":"auto"},attrs:{"value":(_vm.$auth.userProfile.badge.scan_rose_progression * 100) / 1,"color":"blue-grey","height":"15"}},[[_c('span',[_vm._v(_vm._s(_vm.$auth.userProfile.badge.scan_rose_progression)+"/1")])]],2):_c('v-btn',{staticStyle:{"font-size":"10px"},attrs:{"disabled":_vm.badgeAlreadyOnShowcase('scan_rose_level_1'),"outlined":""},on:{"click":function($event){return _vm.addBadgeToShowcase('scan_rose_level_1')}}},[_vm._v(_vm._s(_vm.$t("aveine-space.profile.button.showcase")))])],1)]}}],null,false,3062022346)},[_c('span',[_vm._v(_vm._s(_vm.$t("aveine-space.badges.scan_rose_level_1_text")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',[_c('v-card',_vm._g(_vm._b({staticClass:"d-flex flex-column ma-5 badge",attrs:{"outlined":"","tile":""}},'v-card',attrs,false),on),[_c('v-img',{class:_vm.$auth.userProfile.badge.scan_rose_level >= 2 ? 'active' : 'inactive',attrs:{"src":require("@/assets/badges/scan_rose_level_2.png")}})],1),(((_vm.$auth.userProfile.badge.scan_rose_progression * 100) / 50) < 100)?_c('v-progress-linear',{staticStyle:{"width":"80%","margin":"auto"},attrs:{"value":(_vm.$auth.userProfile.badge.scan_rose_progression * 100) / 50,"color":"blue-grey","height":"15"}},[[_c('span',[_vm._v(_vm._s(_vm.$auth.userProfile.badge.scan_rose_progression)+"/50")])]],2):_c('v-btn',{staticStyle:{"font-size":"10px"},attrs:{"disabled":_vm.badgeAlreadyOnShowcase('scan_rose_level_2'),"outlined":""},on:{"click":function($event){return _vm.addBadgeToShowcase('scan_rose_level_2')}}},[_vm._v(_vm._s(_vm.$t("aveine-space.profile.button.showcase")))])],1)]}}],null,false,1895303966)},[_c('span',[_vm._v(_vm._s(_vm.$t("aveine-space.badges.scan_rose_level_2_text")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',[_c('v-card',_vm._g(_vm._b({staticClass:"d-flex flex-column ma-5 badge",attrs:{"outlined":"","tile":""}},'v-card',attrs,false),on),[_c('v-img',{class:_vm.$auth.userProfile.badge.scan_rose_level >= 3 ? 'active' : 'inactive',attrs:{"src":require("@/assets/badges/scan_rose_level_3.png")}})],1),(((_vm.$auth.userProfile.badge.scan_rose_progression * 100) / 100) < 100)?_c('v-progress-linear',{staticStyle:{"width":"80%","margin":"auto"},attrs:{"value":(_vm.$auth.userProfile.badge.scan_rose_progression * 100) / 100,"color":"blue-grey","height":"15"}},[[_c('span',[_vm._v(_vm._s(_vm.$auth.userProfile.badge.scan_rose_progression)+"/100")])]],2):_c('v-btn',{staticStyle:{"font-size":"10px"},attrs:{"disabled":_vm.badgeAlreadyOnShowcase('scan_rose_level_3'),"outlined":""},on:{"click":function($event){return _vm.addBadgeToShowcase('scan_rose_level_3')}}},[_vm._v(_vm._s(_vm.$t("aveine-space.profile.button.showcase")))])],1)]}}],null,false,383802890)},[_c('span',[_vm._v(_vm._s(_vm.$t("aveine-space.badges.scan_rose_level_3_text")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',[_c('v-card',_vm._g(_vm._b({staticClass:"d-flex flex-column ma-5 badge",attrs:{"outlined":"","tile":""}},'v-card',attrs,false),on),[_c('v-img',{class:_vm.$auth.userProfile.badge.scan_rose_level >= 4 ? 'active' : 'inactive',attrs:{"src":require("@/assets/badges/scan_rose_level_4.png")}})],1),(((_vm.$auth.userProfile.badge.scan_rose_progression * 100) / 500) < 100)?_c('v-progress-linear',{staticStyle:{"width":"80%","margin":"auto"},attrs:{"value":(_vm.$auth.userProfile.badge.scan_rose_progression * 100) / 500,"color":"blue-grey","height":"15"}},[[_c('span',[_vm._v(_vm._s(_vm.$auth.userProfile.badge.scan_rose_progression)+"/500")])]],2):_c('v-btn',{staticStyle:{"font-size":"10px"},attrs:{"disabled":_vm.badgeAlreadyOnShowcase('scan_rose_level_4'),"outlined":""},on:{"click":function($event){return _vm.addBadgeToShowcase('scan_rose_level_4')}}},[_vm._v(_vm._s(_vm.$t("aveine-space.profile.button.showcase")))])],1)]}}],null,false,2033098606)},[_c('span',[_vm._v(_vm._s(_vm.$t("aveine-space.badges.scan_rose_level_4_text")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',[_c('v-card',_vm._g(_vm._b({staticClass:"d-flex flex-column ma-5 badge",attrs:{"outlined":"","tile":""}},'v-card',attrs,false),on),[_c('v-img',{class:_vm.$auth.userProfile.badge.tasting_note_level >= 1 ? 'active' : 'inactive',attrs:{"src":require("@/assets/badges/tasting_note_level_1.png")}})],1),(((_vm.$auth.userProfile.badge.tasting_note_progression * 100) / 1) < 100)?_c('v-progress-linear',{staticStyle:{"width":"80%","margin":"auto"},attrs:{"value":(_vm.$auth.userProfile.badge.tasting_note_progression * 100) / 1,"color":"blue-grey","height":"15"}},[[_c('span',[_vm._v(_vm._s(_vm.$auth.userProfile.badge.tasting_note_progression)+"/1")])]],2):_c('v-btn',{staticStyle:{"font-size":"10px"},attrs:{"disabled":_vm.badgeAlreadyOnShowcase('tasting_note_level_1'),"outlined":""},on:{"click":function($event){return _vm.addBadgeToShowcase('tasting_note_level_1')}}},[_vm._v(_vm._s(_vm.$t("aveine-space.profile.button.showcase")))])],1)]}}],null,false,1916366780)},[_c('span',[_vm._v(_vm._s(_vm.$t("aveine-space.badges.tasting_note_level_1_text")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',[_c('v-card',_vm._g(_vm._b({staticClass:"d-flex flex-column ma-5 badge",attrs:{"outlined":"","tile":""}},'v-card',attrs,false),on),[_c('v-img',{class:_vm.$auth.userProfile.badge.tasting_note_level >= 2 ? 'active' : 'inactive',attrs:{"src":require("@/assets/badges/tasting_note_level_2.png")}})],1),(((_vm.$auth.userProfile.badge.tasting_note_progression * 100) / 100) < 100)?_c('v-progress-linear',{staticStyle:{"width":"80%","margin":"auto"},attrs:{"value":(_vm.$auth.userProfile.badge.tasting_note_progression * 100) / 100,"color":"blue-grey","height":"15"}},[[_c('span',[_vm._v(_vm._s(_vm.$auth.userProfile.badge.tasting_note_progression)+"/100")])]],2):_c('v-btn',{staticStyle:{"font-size":"10px"},attrs:{"disabled":_vm.badgeAlreadyOnShowcase('tasting_note_level_2'),"outlined":""},on:{"click":function($event){return _vm.addBadgeToShowcase('tasting_note_level_2')}}},[_vm._v(_vm._s(_vm.$t("aveine-space.profile.button.showcase")))])],1)]}}],null,false,791921948)},[_c('span',[_vm._v(_vm._s(_vm.$t("aveine-space.badges.tasting_note_level_2_text")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',[_c('v-card',_vm._g(_vm._b({staticClass:"d-flex flex-column ma-5 badge",attrs:{"outlined":"","tile":""}},'v-card',attrs,false),on),[_c('v-img',{class:_vm.$auth.userProfile.badge.tasting_note_level >= 3 ? 'active' : 'inactive',attrs:{"src":require("@/assets/badges/tasting_note_level_3.png")}})],1),(((_vm.$auth.userProfile.badge.tasting_note_progression * 100) / 200) < 100)?_c('v-progress-linear',{staticStyle:{"width":"80%","margin":"auto"},attrs:{"value":(_vm.$auth.userProfile.badge.tasting_note_progression * 100) / 200,"color":"blue-grey","height":"15"}},[[_c('span',[_vm._v(_vm._s(_vm.$auth.userProfile.badge.tasting_note_progression)+"/200")])]],2):_c('v-btn',{staticStyle:{"font-size":"10px"},attrs:{"disabled":_vm.badgeAlreadyOnShowcase('tasting_note_level_3'),"outlined":""},on:{"click":function($event){return _vm.addBadgeToShowcase('tasting_note_level_3')}}},[_vm._v(_vm._s(_vm.$t("aveine-space.profile.button.showcase")))])],1)]}}],null,false,725831967)},[_c('span',[_vm._v(_vm._s(_vm.$t("aveine-space.badges.tasting_note_level_3_text")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',[_c('v-card',_vm._g(_vm._b({staticClass:"d-flex flex-column ma-5 badge",attrs:{"outlined":"","tile":""}},'v-card',attrs,false),on),[_c('v-img',{class:_vm.$auth.userProfile.badge.tasting_note_level >= 4 ? 'active' : 'inactive',attrs:{"src":require("@/assets/badges/tasting_note_level_4.png")}})],1),(((_vm.$auth.userProfile.badge.tasting_note_progression * 100) / 500) < 100)?_c('v-progress-linear',{staticStyle:{"width":"80%","margin":"auto"},attrs:{"value":(_vm.$auth.userProfile.badge.tasting_note_progression * 100) / 500,"color":"blue-grey","height":"15"}},[[_c('span',[_vm._v(_vm._s(_vm.$auth.userProfile.badge.tasting_note_progression)+"/500")])]],2):_c('v-btn',{staticStyle:{"font-size":"10px"},attrs:{"disabled":_vm.badgeAlreadyOnShowcase('tasting_note_level_4'),"outlined":""},on:{"click":function($event){return _vm.addBadgeToShowcase('tasting_note_level_4')}}},[_vm._v(_vm._s(_vm.$t("aveine-space.profile.button.showcase")))])],1)]}}],null,false,4190442712)},[_c('span',[_vm._v(_vm._s(_vm.$t("aveine-space.badges.tasting_note_level_4_text")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',[_c('v-card',_vm._g(_vm._b({staticClass:"d-flex flex-column ma-5 badge",attrs:{"outlined":"","tile":""}},'v-card',attrs,false),on),[_c('v-img',{class:_vm.$auth.userProfile.badge.wine_form_level >= 1 ? 'active' : 'inactive',attrs:{"src":require("@/assets/badges/wine_form_level_1.png")}})],1),(((_vm.$auth.userProfile.badge.wine_form_progression * 100) / 1) < 100)?_c('v-progress-linear',{staticStyle:{"width":"80%","margin":"auto"},attrs:{"value":(_vm.$auth.userProfile.badge.wine_form_progression * 100) / 1,"color":"blue-grey","height":"15"}},[[_c('span',[_vm._v(_vm._s(_vm.$auth.userProfile.badge.wine_form_progression)+"/1")])]],2):_c('v-btn',{staticStyle:{"font-size":"10px"},attrs:{"disabled":_vm.badgeAlreadyOnShowcase('wine_form_level_1'),"outlined":""},on:{"click":function($event){return _vm.addBadgeToShowcase('wine_form_level_1')}}},[_vm._v(_vm._s(_vm.$t("aveine-space.profile.button.showcase")))])],1)]}}],null,false,2492022749)},[_c('span',[_vm._v(_vm._s(_vm.$t("aveine-space.badges.wine_form_level_1_text")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',[_c('v-card',_vm._g(_vm._b({staticClass:"d-flex flex-column ma-5 badge",attrs:{"outlined":"","tile":""}},'v-card',attrs,false),on),[_c('v-img',{class:_vm.$auth.userProfile.badge.wine_form_level >= 2 ? 'active' : 'inactive',attrs:{"src":require("@/assets/badges/wine_form_level_2.png")}})],1),(((_vm.$auth.userProfile.badge.wine_form_progression * 100) / 100) < 100)?_c('v-progress-linear',{staticStyle:{"width":"80%","margin":"auto"},attrs:{"value":(_vm.$auth.userProfile.badge.wine_form_progression * 100) / 100,"color":"blue-grey","height":"15"}},[[_c('span',[_vm._v(_vm._s(_vm.$auth.userProfile.badge.wine_form_progression)+"/100")])]],2):_c('v-btn',{staticStyle:{"font-size":"10px"},attrs:{"disabled":_vm.badgeAlreadyOnShowcase('wine_form_level_2'),"outlined":""},on:{"click":function($event){return _vm.addBadgeToShowcase('wine_form_level_2')}}},[_vm._v(_vm._s(_vm.$t("aveine-space.profile.button.showcase")))])],1)]}}],null,false,2113593725)},[_c('span',[_vm._v(_vm._s(_vm.$t("aveine-space.badges.wine_form_level_2_text")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',[_c('v-card',_vm._g(_vm._b({staticClass:"d-flex flex-column ma-5 badge",attrs:{"outlined":"","tile":""}},'v-card',attrs,false),on),[_c('v-img',{class:_vm.$auth.userProfile.badge.wine_form_level >= 3 ? 'active' : 'inactive',attrs:{"src":require("@/assets/badges/wine_form_level_3.png")}})],1),(((_vm.$auth.userProfile.badge.wine_form_progression * 100) / 200) < 100)?_c('v-progress-linear',{staticStyle:{"width":"80%","margin":"auto"},attrs:{"value":(_vm.$auth.userProfile.badge.wine_form_progression * 100) / 200,"color":"blue-grey","height":"15"}},[[_c('span',[_vm._v(_vm._s(_vm.$auth.userProfile.badge.wine_form_progression)+"/200")])]],2):_c('v-btn',{staticStyle:{"font-size":"10px"},attrs:{"disabled":_vm.badgeAlreadyOnShowcase('wine_form_level_3'),"outlined":""},on:{"click":function($event){return _vm.addBadgeToShowcase('wine_form_level_3')}}},[_vm._v(_vm._s(_vm.$t("aveine-space.profile.button.showcase")))])],1)]}}],null,false,2599800190)},[_c('span',[_vm._v(_vm._s(_vm.$t("aveine-space.badges.wine_form_level_3_text")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',[_c('v-card',_vm._g(_vm._b({staticClass:"d-flex flex-column ma-5 badge",attrs:{"outlined":"","tile":""}},'v-card',attrs,false),on),[_c('v-img',{class:_vm.$auth.userProfile.badge.wine_form_level >= 4 ? 'active' : 'inactive',attrs:{"src":require("@/assets/badges/wine_form_level_4.png")}})],1),(((_vm.$auth.userProfile.badge.wine_form_progression * 100) / 1000) < 100)?_c('v-progress-linear',{staticStyle:{"width":"80%","margin":"auto"},attrs:{"value":(_vm.$auth.userProfile.badge.wine_form_progression * 100) / 1000,"color":"blue-grey","height":"15"}},[[_c('span',[_vm._v(_vm._s(_vm.$auth.userProfile.badge.wine_form_progression)+"/1000")])]],2):_c('v-btn',{staticStyle:{"font-size":"10px"},attrs:{"disabled":_vm.badgeAlreadyOnShowcase('wine_form_level_4'),"outlined":""},on:{"click":function($event){return _vm.addBadgeToShowcase('wine_form_level_4')}}},[_vm._v(_vm._s(_vm.$t("aveine-space.profile.button.showcase")))])],1)]}}],null,false,2588746029)},[_c('span',[_vm._v(_vm._s(_vm.$t("aveine-space.badges.wine_form_level_4_text")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',[_c('v-card',_vm._g(_vm._b({staticClass:"d-flex flex-column ma-5 badge",attrs:{"outlined":"","tile":""}},'v-card',attrs,false),on),[_c('v-img',{class:_vm.$auth.userProfile.badge.blog_level >= 1 ? 'active' : 'inactive',attrs:{"src":require("@/assets/badges/blog_level_1.png")}})],1),(((_vm.$auth.userProfile.badge.blog_progression * 100) / 1) < 100)?_c('v-progress-linear',{staticStyle:{"width":"80%","margin":"auto"},attrs:{"value":(_vm.$auth.userProfile.badge.blog_progression * 100) / 1,"color":"blue-grey","height":"15"}},[[_c('span',[_vm._v(_vm._s(_vm.$auth.userProfile.badge.blog_progression)+"/1")])]],2):_c('v-btn',{staticStyle:{"font-size":"10px"},attrs:{"disabled":_vm.badgeAlreadyOnShowcase('blog_level_1'),"outlined":""},on:{"click":function($event){return _vm.addBadgeToShowcase('blog_level_1')}}},[_vm._v(_vm._s(_vm.$t("aveine-space.profile.button.showcase")))])],1)]}}],null,false,2726680391)},[_c('span',[_vm._v(_vm._s(_vm.$t("aveine-space.badges.blog_level_1_text")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',[_c('v-card',_vm._g(_vm._b({staticClass:"d-flex flex-column ma-5 badge",attrs:{"outlined":"","tile":""}},'v-card',attrs,false),on),[_c('v-img',{class:_vm.$auth.userProfile.badge.blog_level >= 2 ? 'active' : 'inactive',attrs:{"src":require("@/assets/badges/blog_level_2.png")}})],1),(((_vm.$auth.userProfile.badge.blog_progression * 100) / 5) < 100)?_c('v-progress-linear',{staticStyle:{"width":"80%","margin":"auto"},attrs:{"value":(_vm.$auth.userProfile.badge.blog_progression * 100) / 5,"color":"blue-grey","height":"15"}},[[_c('span',[_vm._v(_vm._s(_vm.$auth.userProfile.badge.blog_progression)+"/5")])]],2):_c('v-btn',{staticStyle:{"font-size":"10px"},attrs:{"disabled":_vm.badgeAlreadyOnShowcase('blog_level_2'),"outlined":""},on:{"click":function($event){return _vm.addBadgeToShowcase('blog_level_2')}}},[_vm._v(_vm._s(_vm.$t("aveine-space.profile.button.showcase")))])],1)]}}],null,false,1911119203)},[_c('span',[_vm._v(_vm._s(_vm.$t("aveine-space.badges.blog_level_2_text")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',[_c('v-card',_vm._g(_vm._b({staticClass:"d-flex flex-column ma-5 badge",attrs:{"outlined":"","tile":""}},'v-card',attrs,false),on),[_c('v-img',{class:_vm.$auth.userProfile.badge.blog_level >= 3 ? 'active' : 'inactive',attrs:{"src":require("@/assets/badges/blog_level_3.png")}})],1),(((_vm.$auth.userProfile.badge.blog_progression * 100) / 10) < 100)?_c('v-progress-linear',{staticStyle:{"width":"80%","margin":"auto"},attrs:{"value":(_vm.$auth.userProfile.badge.blog_progression * 100) / 10,"color":"blue-grey","height":"15"}},[[_c('span',[_vm._v(_vm._s(_vm.$auth.userProfile.badge.blog_progression)+"/10")])]],2):_c('v-btn',{staticStyle:{"font-size":"10px"},attrs:{"disabled":_vm.badgeAlreadyOnShowcase('blog_level_3'),"outlined":""},on:{"click":function($event){return _vm.addBadgeToShowcase('blog_level_3')}}},[_vm._v(_vm._s(_vm.$t("aveine-space.profile.button.showcase")))])],1)]}}],null,false,2542835415)},[_c('span',[_vm._v(_vm._s(_vm.$t("aveine-space.badges.blog_level_3_text")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',[_c('v-card',_vm._g(_vm._b({staticClass:"d-flex flex-column ma-5 badge",attrs:{"outlined":"","tile":""}},'v-card',attrs,false),on),[_c('v-img',{class:_vm.$auth.userProfile.badge.blog_level >= 4 ? 'active' : 'inactive',attrs:{"src":require("@/assets/badges/blog_level_4.png")}})],1),(((_vm.$auth.userProfile.badge.blog_progression * 100) / 20) < 100)?_c('v-progress-linear',{staticStyle:{"width":"80%","margin":"auto"},attrs:{"value":(_vm.$auth.userProfile.badge.blog_progression * 100) / 20,"color":"blue-grey","height":"15"}},[[_c('span',[_vm._v(_vm._s(_vm.$auth.userProfile.badge.blog_progression)+"/20")])]],2):_c('v-btn',{staticStyle:{"font-size":"10px"},attrs:{"disabled":_vm.badgeAlreadyOnShowcase('blog_level_4'),"outlined":""},on:{"click":function($event){return _vm.addBadgeToShowcase('blog_level_4')}}},[_vm._v(_vm._s(_vm.$t("aveine-space.profile.button.showcase")))])],1)]}}],null,false,1003492596)},[_c('span',[_vm._v(_vm._s(_vm.$t("aveine-space.badges.blog_level_4_text")))])])],1)],1):_c('div',{staticClass:"d-flex justify-center align-center mt-16"},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":100,"width":10,"color":"secondary"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="home py-5">
    <div v-if="dataLoaded" class="sheet-container">
      <v-container class="d-flex flex-row align-center justify-center mb-2 flex-wrap">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-card
              class="d-flex flex-column ma-5 badge"
              outlined
              tile
              v-bind="attrs"
              v-on="on"
            >
              <v-btn v-if="shelvePosition1" icon style="position:absolute; padding-bottom: 15px; padding-right: 15px;" @click="shelvePosition1 = null; shelveUpdated = true"><v-icon>mdi-close</v-icon></v-btn>
              <v-img v-if="shelvePosition1" :src="require(`@/assets/badges/${shelvePosition1}.png`)"></v-img>
            </v-card>
          </template>
          <span v-if="shelvePosition1">{{$t(`aveine-space.badges.${shelvePosition1}`)}}</span>
        </v-tooltip>
        <v-tooltip top >
          <template v-slot:activator="{ on, attrs }">
            <v-card
              class="d-flex flex-column ma-5 badge"
              outlined
              tile
              v-bind="attrs"
              v-on="on"
            >
              <v-btn v-if="shelvePosition2" icon style="position:absolute; padding-bottom: 15px; padding-right: 15px;" @click="shelvePosition2 = null; shelveUpdated = true"><v-icon>mdi-close</v-icon></v-btn>
              <v-img v-if="shelvePosition2" :src="require(`@/assets/badges/${shelvePosition2}.png`)"></v-img>
            </v-card>
          </template>
          <span v-if="shelvePosition2">{{$t(`aveine-space.badges.${shelvePosition2}`)}}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-card
              class="d-flex flex-column ma-5 badge"
              outlined
              tile
              v-bind="attrs"
              v-on="on"
            >
              <v-btn v-if="shelvePosition3" icon style="position:absolute; padding-bottom: 15px; padding-right: 15px;" @click="shelvePosition3 = null; shelveUpdated = true"><v-icon>mdi-close</v-icon></v-btn>
              <v-img v-if="shelvePosition3" :src="require(`@/assets/badges/${shelvePosition3}.png`)"></v-img>
            </v-card>
          </template>
          <span v-if="shelvePosition3">{{$t(`aveine-space.badges.${shelvePosition3}`)}}</span>
        </v-tooltip>
      </v-container>
      <v-container class="d-flex justify-center mb-3">
        <v-btn v-if="shelveUpdated" @click="submitBadgeShowcase" outlined>{{$t("aveine-space.profile.button.submit")}}</v-btn>
      </v-container>
      <v-container
        class="d-flex flex-row mb-10 flex-wrap badge-container"
      >
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div>
              <v-card
                class="d-flex flex-column ma-5 badge"
                outlined
                tile
                v-bind="attrs"
                v-on="on"
              >
                <v-img :class="$auth.userProfile.badge.aerator == true ? 'active' : 'inactive'" src="@/assets/badges/aerator.png"></v-img>
              </v-card>
              <v-btn v-if="$auth.userProfile.badge.aerator == true" :disabled="badgeAlreadyOnShowcase('aerator')" style="font-size: 10px;" @click="addBadgeToShowcase('aerator')" outlined>{{$t("aveine-space.profile.button.showcase")}}</v-btn>
            </div>
          </template>
          <span>{{$t("aveine-space.badges.aerator_text")}}</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div>
              <v-card
                class="d-flex flex-column ma-5 badge"
                outlined
                tile
                v-bind="attrs"
                v-on="on"
              >
                <v-img :class="$auth.userProfile.badge.aerator_rename == true ? 'active' : 'inactive'" src="@/assets/badges/aerator_rename.png"></v-img>
              </v-card>
              <v-btn v-if="$auth.userProfile.badge.aerator_rename == true" :disabled="badgeAlreadyOnShowcase('aerator_rename')" style="font-size: 10px;" @click="addBadgeToShowcase('aerator_rename')" outlined>{{$t("aveine-space.profile.button.showcase")}}</v-btn>
            </div>
          </template>
          <span>{{$t("aveine-space.badges.aerator_rename_text")}}</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div>
              <v-card
                class="d-flex flex-column ma-5 badge"
                outlined
                tile
                v-bind="attrs"
                v-on="on"
              >
                <v-img :class="$auth.userProfile.badge.tasting_profile == true ? 'active' : 'inactive'" src="@/assets/badges/tasting_profile.png"></v-img>
              </v-card>
              <v-btn v-if="$auth.userProfile.badge.tasting_profile == true" :disabled="badgeAlreadyOnShowcase('tasting_profile')" style="font-size: 10px;" @click="addBadgeToShowcase('tasting_profile')" outlined>{{$t("aveine-space.profile.button.showcase")}}</v-btn>
            </div>
          </template>
          <span>{{$t("aveine-space.badges.tasting_profile_text")}}</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div>
              <v-card
                class="d-flex flex-column ma-5 badge"
                outlined
                tile
                v-bind="attrs"
                v-on="on"
              >
                <v-img :class="$auth.userProfile.badge.scan_red_level >= 1 ? 'active' : 'inactive'" src="@/assets/badges/scan_red_level_1.png"></v-img>
              </v-card>
              <v-progress-linear
                v-if="(($auth.userProfile.badge.scan_red_progression * 100) / 1) < 100"
                :value="($auth.userProfile.badge.scan_red_progression * 100) / 1"
                color="primary"
                height="15"
                style="width:80%; margin:auto;"
              >
                <template>
                  <span>{{$auth.userProfile.badge.scan_red_progression}}/1</span>
                </template>
              </v-progress-linear>
              <v-btn v-else :disabled="badgeAlreadyOnShowcase('scan_red_level_1')" style="font-size: 10px;" @click="addBadgeToShowcase('scan_red_level_1')" outlined>{{$t("aveine-space.profile.button.showcase")}}</v-btn>
            </div>

          </template>
          <span>{{$t("aveine-space.badges.scan_red_level_1_text")}}</span>
        </v-tooltip>

        <v-tooltip top>
          <template class="d-flex flex-column" v-slot:activator="{ on, attrs }">
            <div>
              <v-card
                class="d-flex flex-column ma-5 badge"
                outlined
                tile
                v-bind="attrs"
                v-on="on"
              >
                <v-img :class="$auth.userProfile.badge.scan_red_level >= 2 ? 'active' : 'inactive'" src="@/assets/badges/scan_red_level_2.png"></v-img>
              </v-card>
              <v-progress-linear
                v-if="(($auth.userProfile.badge.scan_red_progression * 100) / 50) < 100"
                :value="($auth.userProfile.badge.scan_red_progression * 100) / 50"
                color="blue-grey"
                height="15"
                style="width:80%; margin:auto;"
              >
                <template>
                  <span>{{$auth.userProfile.badge.scan_red_progression}}/50</span>
                </template>
              </v-progress-linear>
              <v-btn v-else :disabled="badgeAlreadyOnShowcase('scan_red_level_2')" style="font-size: 10px;" @click="addBadgeToShowcase('scan_red_level_2')" outlined>{{$t("aveine-space.profile.button.showcase")}}</v-btn>
            </div>
          </template>
          <span>{{$t("aveine-space.badges.scan_red_level_2_text")}}</span>
        </v-tooltip>

        <v-tooltip top>
          <template class="d-flex flex-column" v-slot:activator="{ on, attrs }">
            <div>
              <v-card
                class="d-flex flex-column ma-5 badge"
                outlined
                tile
                v-bind="attrs"
                v-on="on"
              >
                <v-img :class="$auth.userProfile.badge.scan_red_level >= 3 ? 'active' : 'inactive'" src="@/assets/badges/scan_red_level_3.png"></v-img>
              </v-card>
              <v-progress-linear
                v-if="(($auth.userProfile.badge.scan_red_progression * 100) / 100) < 100"
                :value="($auth.userProfile.badge.scan_red_progression * 100) / 100"
                color="blue-grey"
                height="15"
                style="width:80%; margin:auto;"
              >
                <template>
                  <span>{{$auth.userProfile.badge.scan_red_progression}}/100</span>
                </template>
              </v-progress-linear>
              <v-btn v-else :disabled="badgeAlreadyOnShowcase('scan_red_level_3')" style="font-size: 10px;" @click="addBadgeToShowcase('scan_red_level_3')" outlined>{{$t("aveine-space.profile.button.showcase")}}</v-btn>
            </div>
          </template>
          <span>{{$t("aveine-space.badges.scan_red_level_3_text")}}</span>
        </v-tooltip>

        <v-tooltip top>
          <template class="d-flex flex-column" v-slot:activator="{ on, attrs }">
            <div>
            <v-card
              class="d-flex flex-column ma-5 badge"
              outlined
              tile
              v-bind="attrs"
              v-on="on"
            >
              <v-img :class="$auth.userProfile.badge.scan_red_level >= 4 ? 'active' : 'inactive'" src="@/assets/badges/scan_red_level_4.png"></v-img>
            </v-card>
            <v-progress-linear
                v-if="(($auth.userProfile.badge.scan_red_progression * 100) / 500) < 100"
                :value="($auth.userProfile.badge.scan_red_progression * 100) / 500"
                color="blue-grey"
                height="15"
                style="width:80%; margin:auto;"
              >
                <template>
                  <span>{{$auth.userProfile.badge.scan_red_progression}}/500</span>
                </template>
              </v-progress-linear>
              <v-btn v-else :disabled="badgeAlreadyOnShowcase('scan_red_level_4')" style="font-size: 10px;" @click="addBadgeToShowcase('scan_red_level_4')" outlined>{{$t("aveine-space.profile.button.showcase")}}</v-btn>
            </div>
          </template>
          <span>{{$t("aveine-space.badges.scan_red_level_4_text")}}</span>
        </v-tooltip>


        <v-tooltip top>
          <template class="d-flex flex-column" v-slot:activator="{ on, attrs }">
            <div>
              <v-card
                class="d-flex flex-column ma-5 badge"
                outlined
                tile
                v-bind="attrs"
                v-on="on"
              >
                <v-img :class="$auth.userProfile.badge.scan_white_level >= 1 ? 'active' : 'inactive'" src="@/assets/badges/scan_white_level_1.png"></v-img>
              </v-card>
              <v-progress-linear
                v-if="(($auth.userProfile.badge.scan_white_progression * 100) / 1) < 100"
                :value="($auth.userProfile.badge.scan_white_progression * 100) / 1"
                color="blue-grey"
                height="15"
                style="width:80%; margin:auto;"
              >
                <template>
                  <span>{{$auth.userProfile.badge.scan_white_progression}}/1</span>
                </template>
              </v-progress-linear>
              <v-btn v-else :disabled="badgeAlreadyOnShowcase('scan_white_level_1')" style="font-size: 10px;" @click="addBadgeToShowcase('scan_white_level_1')" outlined>{{$t("aveine-space.profile.button.showcase")}}</v-btn>
            </div>
          </template>
          <span>{{$t("aveine-space.badges.scan_white_level_1_text")}}</span>
        </v-tooltip>

        <v-tooltip top>
          <template class="d-flex flex-column" v-slot:activator="{ on, attrs }">
            <div>
              <v-card
                class="d-flex flex-column ma-5 badge"
                outlined
                tile
                v-bind="attrs"
                v-on="on"
              >
                <v-img :class="$auth.userProfile.badge.scan_white_level >= 2 ? 'active' : 'inactive'" src="@/assets/badges/scan_white_level_2.png"></v-img>
              </v-card>
              <v-progress-linear
                v-if="(($auth.userProfile.badge.scan_white_progression * 100) / 50) < 100"
                :value="($auth.userProfile.badge.scan_white_progression * 100) / 50"
                color="blue-grey"
                height="15"
                style="width:80%; margin:auto;"
              >
                <template>
                  <span>{{$auth.userProfile.badge.scan_white_progression}}/50</span>
                </template>
              </v-progress-linear>
              <v-btn v-else :disabled="badgeAlreadyOnShowcase('scan_white_level_2')" style="font-size: 10px;" @click="addBadgeToShowcase('scan_white_level_2')" outlined>{{$t("aveine-space.profile.button.showcase")}}</v-btn>
            </div>
          </template>
          <span>{{$t("aveine-space.badges.scan_white_level_2_text")}}</span>
        </v-tooltip>

        <v-tooltip top>
          <template class="d-flex flex-column" v-slot:activator="{ on, attrs }">
            <div>
              <v-card
                class="d-flex flex-column ma-5 badge"
                outlined
                tile
                v-bind="attrs"
                v-on="on"
              >
                <v-img :class="$auth.userProfile.badge.scan_white_level >= 3 ? 'active' : 'inactive'" src="@/assets/badges/scan_white_level_3.png"></v-img>
              </v-card>
              <v-progress-linear
                v-if="(($auth.userProfile.badge.scan_white_progression * 100) / 100) < 100"
                :value="($auth.userProfile.badge.scan_white_progression * 100) / 100"
                color="blue-grey"
                height="15"
                style="width:80%; margin:auto;"
              >
                <template>
                  <span>{{$auth.userProfile.badge.scan_white_progression}}/100</span>
                </template>
              </v-progress-linear>
              <v-btn v-else :disabled="badgeAlreadyOnShowcase('scan_white_level_3')" style="font-size: 10px;" @click="addBadgeToShowcase('scan_white_level_3')" outlined>{{$t("aveine-space.profile.button.showcase")}}</v-btn>
            </div>
          </template>
          <span>{{$t("aveine-space.badges.scan_white_level_3_text")}}</span>
        </v-tooltip>

        <v-tooltip top>
          <template class="d-flex flex-column" v-slot:activator="{ on, attrs }">
            <div>
              <v-card
                class="d-flex flex-column ma-5 badge"
                outlined
                tile
                v-bind="attrs"
                v-on="on"
              >
                <v-img :class="$auth.userProfile.badge.scan_white_level >= 4 ? 'active' : 'inactive'" src="@/assets/badges/scan_white_level_4.png"></v-img>
              </v-card>
              <v-progress-linear
                v-if="(($auth.userProfile.badge.scan_white_progression * 100) / 500) < 100"
                :value="($auth.userProfile.badge.scan_white_progression * 100) / 500"
                color="blue-grey"
                height="15"
                style="width:80%; margin:auto;"
              >
                <template>
                  <span>{{$auth.userProfile.badge.scan_white_progression}}/500</span>
                </template>
              </v-progress-linear>
              <v-btn v-else :disabled="badgeAlreadyOnShowcase('scan_white_level_4')" style="font-size: 10px;" @click="addBadgeToShowcase('scan_white_level_4')" outlined>{{$t("aveine-space.profile.button.showcase")}}</v-btn>
            </div>
          </template>
          <span>{{$t("aveine-space.badges.scan_white_level_4_text")}}</span>
        </v-tooltip>

        <v-tooltip top>
          <template class="d-flex flex-column" v-slot:activator="{ on, attrs }">
            <div>
              <v-card
                class="d-flex flex-column ma-5 badge"
                outlined
                tile
                v-bind="attrs"
                v-on="on"
              >
                <v-img :class="$auth.userProfile.badge.scan_rose_level >= 1 ? 'active' : 'inactive'" src="@/assets/badges/scan_rose_level_1.png"></v-img>
              </v-card>
              <v-progress-linear
                v-if="(($auth.userProfile.badge.scan_rose_progression * 100) / 1) < 100"
                :value="($auth.userProfile.badge.scan_rose_progression * 100) / 1"
                color="blue-grey"
                height="15"
                style="width:80%; margin:auto;"
              >
                <template>
                  <span>{{$auth.userProfile.badge.scan_rose_progression}}/1</span>
                </template>
              </v-progress-linear>
              <v-btn v-else :disabled="badgeAlreadyOnShowcase('scan_rose_level_1')" style="font-size: 10px;" @click="addBadgeToShowcase('scan_rose_level_1')" outlined>{{$t("aveine-space.profile.button.showcase")}}</v-btn>
            </div>
          </template>
          <span>{{$t("aveine-space.badges.scan_rose_level_1_text")}}</span>
        </v-tooltip>

        <v-tooltip top>
          <template class="d-flex flex-column" v-slot:activator="{ on, attrs }">
            <div>
              <v-card
                class="d-flex flex-column ma-5 badge"
                outlined
                tile
                v-bind="attrs"
                v-on="on"
              >
                <v-img :class="$auth.userProfile.badge.scan_rose_level >= 2 ? 'active' : 'inactive'" src="@/assets/badges/scan_rose_level_2.png"></v-img>
              </v-card>
              <v-progress-linear
                v-if="(($auth.userProfile.badge.scan_rose_progression * 100) / 50) < 100"
                :value="($auth.userProfile.badge.scan_rose_progression * 100) / 50"
                color="blue-grey"
                height="15"
                style="width:80%; margin:auto;"
              >
                <template>
                  <span>{{$auth.userProfile.badge.scan_rose_progression}}/50</span>
                </template>
              </v-progress-linear>
              <v-btn v-else :disabled="badgeAlreadyOnShowcase('scan_rose_level_2')" style="font-size: 10px;" @click="addBadgeToShowcase('scan_rose_level_2')" outlined>{{$t("aveine-space.profile.button.showcase")}}</v-btn>
            </div>
          </template>
          <span>{{$t("aveine-space.badges.scan_rose_level_2_text")}}</span>
        </v-tooltip>

        <v-tooltip top>
          <template class="d-flex flex-column" v-slot:activator="{ on, attrs }">
            <div>
              <v-card
                class="d-flex flex-column ma-5 badge"
                outlined
                tile
                v-bind="attrs"
                v-on="on"
              >
                <v-img :class="$auth.userProfile.badge.scan_rose_level >= 3 ? 'active' : 'inactive'" src="@/assets/badges/scan_rose_level_3.png"></v-img>
              </v-card>
              <v-progress-linear
                v-if="(($auth.userProfile.badge.scan_rose_progression * 100) / 100) < 100"
                :value="($auth.userProfile.badge.scan_rose_progression * 100) / 100"
                color="blue-grey"
                height="15"
                style="width:80%; margin:auto;"
              >
                <template>
                  <span>{{$auth.userProfile.badge.scan_rose_progression}}/100</span>
                </template>
              </v-progress-linear>
              <v-btn v-else :disabled="badgeAlreadyOnShowcase('scan_rose_level_3')" style="font-size: 10px;" @click="addBadgeToShowcase('scan_rose_level_3')" outlined>{{$t("aveine-space.profile.button.showcase")}}</v-btn>

            </div>
          </template>
          <span>{{$t("aveine-space.badges.scan_rose_level_3_text")}}</span>
        </v-tooltip>

        <v-tooltip top>
          <template class="d-flex flex-column" v-slot:activator="{ on, attrs }">
            <div>      
              <v-card
                class="d-flex flex-column ma-5 badge"
                outlined
                tile
                v-bind="attrs"
                v-on="on"
              >
                <v-img :class="$auth.userProfile.badge.scan_rose_level >= 4 ? 'active' : 'inactive'" src="@/assets/badges/scan_rose_level_4.png"></v-img>
              </v-card>
              <v-progress-linear
                v-if="(($auth.userProfile.badge.scan_rose_progression * 100) / 500) < 100"
                :value="($auth.userProfile.badge.scan_rose_progression * 100) / 500"
                color="blue-grey"
                height="15"
                style="width:80%; margin:auto;"
              >
                <template>
                  <span>{{$auth.userProfile.badge.scan_rose_progression}}/500</span>
                </template>
              </v-progress-linear>
              <v-btn v-else :disabled="badgeAlreadyOnShowcase('scan_rose_level_4')" style="font-size: 10px;" @click="addBadgeToShowcase('scan_rose_level_4')" outlined>{{$t("aveine-space.profile.button.showcase")}}</v-btn>
            </div>
          </template>
          <span>{{$t("aveine-space.badges.scan_rose_level_4_text")}}</span>
        </v-tooltip>

        <v-tooltip top>
          <template class="d-flex flex-column" v-slot:activator="{ on, attrs }">
            <div>    
              <v-card
                class="d-flex flex-column ma-5 badge"
                outlined
                tile
                v-bind="attrs"
                v-on="on"
              >
                <v-img :class="$auth.userProfile.badge.tasting_note_level >= 1 ? 'active' : 'inactive'" src="@/assets/badges/tasting_note_level_1.png"></v-img>
              </v-card>
              <v-progress-linear
                v-if="(($auth.userProfile.badge.tasting_note_progression * 100) / 1) < 100"
                :value="($auth.userProfile.badge.tasting_note_progression * 100) / 1"
                color="blue-grey"
                height="15"
                style="width:80%; margin:auto;"
              >
                <template>
                  <span>{{$auth.userProfile.badge.tasting_note_progression}}/1</span>
                </template>
              </v-progress-linear>
              <v-btn v-else :disabled="badgeAlreadyOnShowcase('tasting_note_level_1')" style="font-size: 10px;" @click="addBadgeToShowcase('tasting_note_level_1')" outlined>{{$t("aveine-space.profile.button.showcase")}}</v-btn>
            </div>
          </template>
          <span>{{$t("aveine-space.badges.tasting_note_level_1_text")}}</span>
        </v-tooltip>

        <v-tooltip top>
          <template class="d-flex flex-column" v-slot:activator="{ on, attrs }">
            <div>    
              <v-card
                class="d-flex flex-column ma-5 badge"
                outlined
                tile
                v-bind="attrs"
                v-on="on"
              >
                <v-img :class="$auth.userProfile.badge.tasting_note_level >= 2 ? 'active' : 'inactive'" src="@/assets/badges/tasting_note_level_2.png"></v-img>
              </v-card>
              <v-progress-linear
                v-if="(($auth.userProfile.badge.tasting_note_progression * 100) / 100) < 100"
                :value="($auth.userProfile.badge.tasting_note_progression * 100) / 100"
                color="blue-grey"
                height="15"
                style="width:80%; margin:auto;"
              >
                <template>
                  <span>{{$auth.userProfile.badge.tasting_note_progression}}/100</span>
                </template>
              </v-progress-linear>
              <v-btn v-else :disabled="badgeAlreadyOnShowcase('tasting_note_level_2')" style="font-size: 10px;" @click="addBadgeToShowcase('tasting_note_level_2')" outlined>{{$t("aveine-space.profile.button.showcase")}}</v-btn>

            </div>
          </template>
          <span>{{$t("aveine-space.badges.tasting_note_level_2_text")}}</span>
        </v-tooltip>

        <v-tooltip top>
          <template class="d-flex flex-column" v-slot:activator="{ on, attrs }">
            <div>
              <v-card
                class="d-flex flex-column ma-5 badge"
                outlined
                tile
                v-bind="attrs"
                v-on="on"
              >
                <v-img :class="$auth.userProfile.badge.tasting_note_level >= 3 ? 'active' : 'inactive'" src="@/assets/badges/tasting_note_level_3.png"></v-img>
              </v-card>
              <v-progress-linear
                v-if="(($auth.userProfile.badge.tasting_note_progression * 100) / 200) < 100"
                :value="($auth.userProfile.badge.tasting_note_progression * 100) / 200"
                color="blue-grey"
                height="15"
                style="width:80%; margin:auto;"
              >
                <template>
                  <span>{{$auth.userProfile.badge.tasting_note_progression}}/200</span>
                </template>
              </v-progress-linear>
              <v-btn v-else :disabled="badgeAlreadyOnShowcase('tasting_note_level_3')" style="font-size: 10px;" @click="addBadgeToShowcase('tasting_note_level_3')" outlined>{{$t("aveine-space.profile.button.showcase")}}</v-btn>

            </div>
          </template>
          <span>{{$t("aveine-space.badges.tasting_note_level_3_text")}}</span>
        </v-tooltip>

        <v-tooltip top>
          <template class="d-flex flex-column" v-slot:activator="{ on, attrs }">
            <div>
              <v-card
                class="d-flex flex-column ma-5 badge"
                outlined
                tile
                v-bind="attrs"
                v-on="on"
              >
                <v-img :class="$auth.userProfile.badge.tasting_note_level >= 4 ? 'active' : 'inactive'" src="@/assets/badges/tasting_note_level_4.png"></v-img>
              </v-card>
              <v-progress-linear
                v-if="(($auth.userProfile.badge.tasting_note_progression * 100) / 500) < 100"
                :value="($auth.userProfile.badge.tasting_note_progression * 100) / 500"
                color="blue-grey"
                height="15"
                style="width:80%; margin:auto;"
              >
                <template>
                  <span>{{$auth.userProfile.badge.tasting_note_progression}}/500</span>
                </template>
              </v-progress-linear>
              <v-btn v-else :disabled="badgeAlreadyOnShowcase('tasting_note_level_4')" style="font-size: 10px;" @click="addBadgeToShowcase('tasting_note_level_4')" outlined>{{$t("aveine-space.profile.button.showcase")}}</v-btn>
            </div>
          </template>
          <span>{{$t("aveine-space.badges.tasting_note_level_4_text")}}</span>
        </v-tooltip>

        <v-tooltip top>
          <template class="d-flex flex-column" v-slot:activator="{ on, attrs }">
            <div>
              <v-card
                class="d-flex flex-column ma-5 badge"
                outlined
                tile
                v-bind="attrs"
                v-on="on"
              >
                <v-img :class="$auth.userProfile.badge.wine_form_level >= 1 ? 'active' : 'inactive'" src="@/assets/badges/wine_form_level_1.png"></v-img>
              </v-card>

              <v-progress-linear
                v-if="(($auth.userProfile.badge.wine_form_progression * 100) / 1) < 100"
                :value="($auth.userProfile.badge.wine_form_progression * 100) / 1"
                color="blue-grey"
                height="15"
                style="width:80%; margin:auto;"
              >
                <template>
                  <span>{{$auth.userProfile.badge.wine_form_progression}}/1</span>
                </template>
              </v-progress-linear>
              <v-btn v-else :disabled="badgeAlreadyOnShowcase('wine_form_level_1')" style="font-size: 10px;" @click="addBadgeToShowcase('wine_form_level_1')" outlined>{{$t("aveine-space.profile.button.showcase")}}</v-btn>

            </div>
          </template>
          <span>{{$t("aveine-space.badges.wine_form_level_1_text")}}</span>
        </v-tooltip>

        <v-tooltip top>
          <template class="d-flex flex-column" v-slot:activator="{ on, attrs }">
            <div>
              <v-card
                class="d-flex flex-column ma-5 badge"
                outlined
                tile
                v-bind="attrs"
                v-on="on"
              >
                <v-img :class="$auth.userProfile.badge.wine_form_level >= 2 ? 'active' : 'inactive'" src="@/assets/badges/wine_form_level_2.png"></v-img>
              </v-card>
              <v-progress-linear
                v-if="(($auth.userProfile.badge.wine_form_progression * 100) / 100) < 100"
                :value="($auth.userProfile.badge.wine_form_progression * 100) / 100"
                color="blue-grey"
                height="15"
                style="width:80%; margin:auto;"
              >
                <template>
                  <span>{{$auth.userProfile.badge.wine_form_progression}}/100</span>
                </template>
              </v-progress-linear>
              <v-btn v-else :disabled="badgeAlreadyOnShowcase('wine_form_level_2')" style="font-size: 10px;" @click="addBadgeToShowcase('wine_form_level_2')" outlined>{{$t("aveine-space.profile.button.showcase")}}</v-btn>
            </div>
          </template>
          <span>{{$t("aveine-space.badges.wine_form_level_2_text")}}</span>
        </v-tooltip>

        <v-tooltip top>
          <template class="d-flex flex-column" v-slot:activator="{ on, attrs }">
            <div>
              <v-card
                class="d-flex flex-column ma-5 badge"
                outlined
                tile
                v-bind="attrs"
                v-on="on"
              >
                <v-img :class="$auth.userProfile.badge.wine_form_level >= 3 ? 'active' : 'inactive'" src="@/assets/badges/wine_form_level_3.png"></v-img>
              </v-card>
              <v-progress-linear
                v-if="(($auth.userProfile.badge.wine_form_progression * 100) / 200) < 100"
                :value="($auth.userProfile.badge.wine_form_progression * 100) / 200"
                color="blue-grey"
                height="15"
                style="width:80%; margin:auto;"
              >
                <template>
                  <span>{{$auth.userProfile.badge.wine_form_progression}}/200</span>
                </template>
              </v-progress-linear>
              <v-btn v-else :disabled="badgeAlreadyOnShowcase('wine_form_level_3')" style="font-size: 10px;" @click="addBadgeToShowcase('wine_form_level_3')" outlined>{{$t("aveine-space.profile.button.showcase")}}</v-btn>

            </div>
          </template>
          <span>{{$t("aveine-space.badges.wine_form_level_3_text")}}</span>
        </v-tooltip>

        <v-tooltip top>
          <template class="d-flex flex-column" v-slot:activator="{ on, attrs }">
            <div>
              <v-card
                class="d-flex flex-column ma-5 badge"
                outlined
                tile
                v-bind="attrs"
                v-on="on"
              >
                <v-img :class="$auth.userProfile.badge.wine_form_level >= 4 ? 'active' : 'inactive'" src="@/assets/badges/wine_form_level_4.png"></v-img>
              </v-card>
              <v-progress-linear
                v-if="(($auth.userProfile.badge.wine_form_progression * 100) / 1000) < 100"
                :value="($auth.userProfile.badge.wine_form_progression * 100) / 1000"
                color="blue-grey"
                height="15"
                style="width:80%; margin:auto;"
              >
                <template>
                  <span>{{$auth.userProfile.badge.wine_form_progression}}/1000</span>
                </template>
              </v-progress-linear>
              <v-btn v-else :disabled="badgeAlreadyOnShowcase('wine_form_level_4')" style="font-size: 10px;" @click="addBadgeToShowcase('wine_form_level_4')" outlined>{{$t("aveine-space.profile.button.showcase")}}</v-btn>
            </div>
          </template>
          <span>{{$t("aveine-space.badges.wine_form_level_4_text")}}</span>
        </v-tooltip>

        <v-tooltip top>
          <template class="d-flex flex-column" v-slot:activator="{ on, attrs }">
            <div>
              <v-card
                class="d-flex flex-column ma-5 badge"
                outlined
                tile
                v-bind="attrs"
                v-on="on"
              >
                <v-img :class="$auth.userProfile.badge.blog_level >= 1 ? 'active' : 'inactive'" src="@/assets/badges/blog_level_1.png"></v-img>
              </v-card>
              <v-progress-linear
                v-if="(($auth.userProfile.badge.blog_progression * 100) / 1) < 100"
                :value="($auth.userProfile.badge.blog_progression * 100) / 1"
                color="blue-grey"
                height="15"
                style="width:80%; margin:auto;"
              >
                <template>
                  <span>{{$auth.userProfile.badge.blog_progression}}/1</span>
                </template>
              </v-progress-linear>
              <v-btn v-else :disabled="badgeAlreadyOnShowcase('blog_level_1')" style="font-size: 10px;" @click="addBadgeToShowcase('blog_level_1')" outlined>{{$t("aveine-space.profile.button.showcase")}}</v-btn>
            </div>
          </template>
          <span>{{$t("aveine-space.badges.blog_level_1_text")}}</span>
        </v-tooltip>

        <v-tooltip top>
          <template class="d-flex flex-column" v-slot:activator="{ on, attrs }">
            <div>
              <v-card
                class="d-flex flex-column ma-5 badge"
                outlined
                tile
                v-bind="attrs"
                v-on="on"
              >
                <v-img :class="$auth.userProfile.badge.blog_level >= 2 ? 'active' : 'inactive'" src="@/assets/badges/blog_level_2.png"></v-img>
              </v-card>
              <v-progress-linear
                v-if="(($auth.userProfile.badge.blog_progression * 100) / 5) < 100"
                :value="($auth.userProfile.badge.blog_progression * 100) / 5"
                color="blue-grey"
                height="15"
                style="width:80%; margin:auto;"
              >
                <template>
                  <span>{{$auth.userProfile.badge.blog_progression}}/5</span>
                </template>
              </v-progress-linear>
              <v-btn v-else :disabled="badgeAlreadyOnShowcase('blog_level_2')" style="font-size: 10px;" @click="addBadgeToShowcase('blog_level_2')" outlined>{{$t("aveine-space.profile.button.showcase")}}</v-btn>
            </div>
          </template>
          <span>{{$t("aveine-space.badges.blog_level_2_text")}}</span>
        </v-tooltip>

        <v-tooltip top>
          <template class="d-flex flex-column" v-slot:activator="{ on, attrs }">
            <div>
              <v-card
                class="d-flex flex-column ma-5 badge"
                outlined
                tile
                v-bind="attrs"
                v-on="on"
              >
                <v-img :class="$auth.userProfile.badge.blog_level >= 3 ? 'active' : 'inactive'" src="@/assets/badges/blog_level_3.png"></v-img>
              </v-card>
              <v-progress-linear
                v-if="(($auth.userProfile.badge.blog_progression * 100) / 10) < 100"
                :value="($auth.userProfile.badge.blog_progression * 100) / 10"
                color="blue-grey"
                height="15"
                style="width:80%; margin:auto;"
              >
                <template>
                  <span>{{$auth.userProfile.badge.blog_progression}}/10</span>
                </template>
              </v-progress-linear>
              <v-btn v-else :disabled="badgeAlreadyOnShowcase('blog_level_3')" style="font-size: 10px;" @click="addBadgeToShowcase('blog_level_3')" outlined>{{$t("aveine-space.profile.button.showcase")}}</v-btn>
            </div>
          </template>
          <span>{{$t("aveine-space.badges.blog_level_3_text")}}</span>
        </v-tooltip>

        <v-tooltip top>
          <template class="d-flex flex-column" v-slot:activator="{ on, attrs }">
            <div>
              <v-card
                class="d-flex flex-column ma-5 badge"
                outlined
                tile
                v-bind="attrs"
                v-on="on"
              >
                <v-img :class="$auth.userProfile.badge.blog_level >= 4 ? 'active' : 'inactive'" src="@/assets/badges/blog_level_4.png"></v-img>
              </v-card>
              <v-progress-linear
                v-if="(($auth.userProfile.badge.blog_progression * 100) / 20) < 100"
                :value="($auth.userProfile.badge.blog_progression * 100) / 20"
                color="blue-grey"
                height="15"
                style="width:80%; margin:auto;"
              >
                <template>
                  <span>{{$auth.userProfile.badge.blog_progression}}/20</span>
                </template>
              </v-progress-linear>
              <v-btn v-else :disabled="badgeAlreadyOnShowcase('blog_level_4')" style="font-size: 10px;" @click="addBadgeToShowcase('blog_level_4')" outlined>{{$t("aveine-space.profile.button.showcase")}}</v-btn>

            </div>
          </template>
          <span>{{$t("aveine-space.badges.blog_level_4_text")}}</span>
        </v-tooltip>
        
     </v-container>
    </div>
    <div v-else class="d-flex justify-center align-center mt-16">
      <v-progress-circular
        indeterminate
        :size="100"
        :width="10"
        color="secondary"
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import { UserProfile } from 'aveine-toolbox';
import { errorHandler } from 'aveine-toolbox'
export default {
  data () {
    return {
      userProfile: {},
      shelvePosition1: null,
      shelvePosition2: null,
      shelvePosition3: null,
      shelveUpdated: false,
      dataLoaded: false,
      APIbaseURL: process.env.VUE_APP_API_CONSUMER_HOST
    }
  },
  name: 'Read',
  async created() {
    try {
      this.shelvePosition1 = this.$auth.userProfile.shelve[0]
      this.shelvePosition2 =  this.$auth.userProfile.shelve[1]
      this.shelvePosition3 = this.$auth.userProfile.shelve[2]

      this.dataLoaded = true;
    } catch (errors) {
      this.dataLoaded = true;
      errorHandler(errors, this)
    }
  },
  methods: {
    /**
     * Check if the selected badge is already on showcase or not
     * @param {String} badgeName selected badge name
     */
    badgeAlreadyOnShowcase (badgeName) {
      return (this.shelvePosition1 == badgeName || this.shelvePosition2 == badgeName || this.shelvePosition3 == badgeName || this.shelvePosition3 != null)
    },
    /**
     * 
     * @param {String} badge the badge string to add to shelve
     */
    addBadgeToShowcase (badge) {
      this.shelveUpdated = true
      if (!this.shelvePosition1)
        this.shelvePosition1 = badge
      else if (!this.shelvePosition2)
        this.shelvePosition2 = badge
      else if (!this.shelvePosition3)
        this.shelvePosition3 = badge
    },

    async submitBadgeShowcase () {
      try {
        this.dataLoaded = false;
        this.$auth.userProfile.shelve = [];

        if (this.shelvePosition1) {
          this.$auth.userProfile.shelve.push(this.shelvePosition1)
        }
        
        if (this.shelvePosition2) {
          this.$auth.userProfile.shelve.push(this.shelvePosition2)
        }

        if (this.shelvePosition3) {
          this.$auth.userProfile.shelve.push(this.shelvePosition3)
        }

        let formData = {
          document: this.$auth.userProfile        
        }

        await UserProfile.api().patch(`/users/self/profile/multipart`, formData, {baseURL: process.env.VUE_APP_API_CONSUMER_HOST});
        this.shelveUpdated =  false
        this.$toastr.s(this.$t("aveine-space.title.profileSaved"));
        this.dataLoaded = true;
      } catch (errors) {
        this.dataLoaded = true;
        errorHandler(errors, this)
      }
    }  
  },
}
</script>
<style lang="scss">
div.badge-container {
  padding: 20px;
  box-shadow: 0 3px 5px 1px #80808069;
  background-color: white;
  border-radius: 0 20px 20px 20px;
}

.badge {
  width: 100px;
  height: 100px;
}

.badge .v-image {
  margin: 10px;
}

.badge .inactive {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.3;
}
</style>


import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: "#161D37",
        secondary: "#923834",
        misc: "#E19826"
      },
      dark: {
        primary: "#161D37",
        secondary: "#923834",
        misc: "#E19826"
      },
    },
  }
});

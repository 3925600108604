<template>
	<div class="login d-flex flex-column justify-center">
		<div class="d-flex flex-column justify-center login-header">
			<div class="d-flex flex-column ml-xl-4 ml-md-3 mt-16 justify-center-start header-banner">
				<h1 class="mt-10">
					{{$t("aveine-space.homepage.tagline")}}
				</h1>
				<h2 class="mb-6 font-weight-regular ml-xl-10 ml-md-8 mb-16">
					{{$t("aveine-space.homepage.taglineSubtitle")}}
				</h2>
				<div class="d-flex flex-xl-row flex-md-row flex-column justify-xl-start justify-md-start justify-center align-center pa-xl-10 pa-md-8">
					<div class="ma-4">
						<v-btn raised rounded class="pa-6" color="white" @click="scrollDiscovery">{{$t("aveine-space.homepage.discover")}}</v-btn>
					</div>
					<div class="ma-4 mb-13">
						<AveineLogin/>
						<v-btn raised rounded class="pa-6" color="white">{{$t("aveine-space.homepage.login")}}</v-btn>
					</div>
				</div>
			</div>
		</div>
		<div id="advantages" class="d-flex flex-column login-advantages mb-16">
			<div class="d-flex flex-column my-16 align-start">
				<h1>{{$t("aveine-space.homepage.advantagesTitle")}}</h1>
			</div>
			<v-carousel v-if="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'" hide-delimiters>
				<v-carousel-item>
					<v-sheet
						height="100%"
						tile
					>
						<v-row
							class="fill-height"
							align="center"
							justify="center"
						>
							<div class="advantages">
								<div class="d-flex justify-center align-center ">
									<v-img  max-width="150" height="150" contain  class="advantage-picto mb-4" alt="picto-aeration" src="@/assets/Bouteilles-icon.png"/>
								</div>
								<h2 class="advantage-title mb-4">{{$t("aveine-space.homepage.manageWinesTitle")}}</h2>
								<p class="advantage-text">
									{{$t("aveine-space.homepage.manageWinesText")}}
								</p>
							</div>
						</v-row>
					</v-sheet>
				</v-carousel-item>
				<v-carousel-item>
					<v-sheet
						height="100%"
						tile
					>
						<v-row
							class="fill-height"
							align="center"
							justify="center"
						>
							<div class="advantages">
								<div class="d-flex justify-center align-center ">
									<v-img  max-width="150" height="150" contain class="advantage-picto mb-4" alt="picto-aeration" src="@/assets/Bouteille-icon-coeur.png"/>
								</div>					
								<h2 class="advantage-title mb-4">{{$t("aveine-space.homepage.recommendationTitle")}}</h2>
								<p class="advantage-text">
									{{$t("aveine-space.homepage.recommendationText")}}
								</p>
							</div>
						</v-row>
					</v-sheet>
				</v-carousel-item>
				<v-carousel-item>
					<v-sheet
						height="100%"
						tile
					>
						<v-row
							class="fill-height"
							align="center"
							justify="center"
						>
							<div class="advantages">
								<div class="d-flex justify-center align-center ">
									<v-img  max-width="150" height="150" contain class="advantage-picto mb-4" alt="picto-aeration" src="@/assets/Verres-icon.png"/>
								</div>					
								<h2 class="advantage-title mb-4">{{$t("aveine-space.homepage.friendsTitle")}}</h2>
								<p class="advantage-text">
									{{$t("aveine-space.homepage.friendsText")}}
								</p>
							</div>
						</v-row>
					</v-sheet>
				</v-carousel-item>
				<v-carousel-item>
					<v-sheet
						height="100%"
						tile
					>
						<v-row
							class="fill-height"
							align="center"
							justify="center"
						>
							<div class="advantages">
								<div class="d-flex justify-center align-center">
									<v-img  max-width="150" height="150" contain  class="advantage-picto mb-4" alt="picto-aeration" src="@/assets/recompenses-icon.png"/>
								</div>
								<h2 class="advantage-title mb-4">{{$t("aveine-space.homepage.rewardsTitle")}}</h2>
								<p class="advantage-text">
									{{$t("aveine-space.homepage.rewardsText")}}
								</p>
							</div>
						</v-row>
					</v-sheet>
				</v-carousel-item>
			</v-carousel>
			<div v-else class="d-flex flex-xl-row flex-md-row flex-column align-center justify-space-between mb-8">
				<div class="advantages">
					<div class="d-flex justify-center align-center ">
						<v-img  max-width="150" height="150" contain  class="advantage-picto mb-4" alt="picto-aeration" src="@/assets/Bouteilles-icon.png"/>
					</div>
					<h2 class="advantage-title mb-4">{{$t("aveine-space.homepage.manageWinesTitle")}}</h2>
					<p class="advantage-text">
						{{$t("aveine-space.homepage.manageWinesText")}}
					</p>
				</div>
				<div class="advantages">
					<div class="d-flex justify-center align-center ">
						<v-img  max-width="150" height="150" contain class="advantage-picto mb-4" alt="picto-aeration" src="@/assets/Bouteille-icon-coeur.png"/>
					</div>					
					<h2 class="advantage-title mb-4">{{$t("aveine-space.homepage.recommendationTitle")}}</h2>
					<p class="advantage-text">
						{{$t("aveine-space.homepage.recommendationText")}}
					</p>
				</div>
				<div class="advantages">
					<div class="d-flex justify-center align-center ">
						<v-img  max-width="150" height="150" contain class="advantage-picto mb-4" alt="picto-aeration" src="@/assets/Verres-icon.png"/>
					</div>					
					<h2 class="advantage-title mb-4">{{$t("aveine-space.homepage.friendsTitle")}}</h2>
					<p class="advantage-text">
						{{$t("aveine-space.homepage.friendsText")}}
					</p>
				</div>
				<div class="advantages">
					<div class="d-flex justify-center align-center">
						<v-img  max-width="150" height="150" contain  class="advantage-picto mb-4" alt="picto-aeration" src="@/assets/recompenses-icon.png"/>
					</div>
					<h2 class="advantage-title mb-4">{{$t("aveine-space.homepage.rewardsTitle")}}</h2>
					<p class="advantage-text">
						{{$t("aveine-space.homepage.rewardsText")}}
					</p>
				</div>
			</div>
		</div>
		<div class="d-flex flex-column justify-center align-center pro-block">
			<div class="d-flex flex-column mt-xl-16 mt-md-14 my-2 justify-center mx-1">
				<h1 class="mb-16 text-center">
					{{$t("aveine-space.homepage.ambassadorTitle")}}
				</h1>
				<h3 class="mb-xl-16 mb-md-14 mb-6 font-weight-regular text-center">
					{{$t("aveine-space.homepage.ambassadorText")}}
				</h3>
				<h4 class="mt-16 font-weight-regular text-center">
					{{$t("aveine-space.homepage.curiousAboutAmbassadorProgram")}}
				</h4>
			</div>
			<div class="d-flex flex-column justify-xl-start justify-md-start justify-center pa-xl-10 pa-md-8 mt-5 mb-5">
				<div>
					<v-btn outlined rounded class="pa-6" color="white" href="https://www.aveine.com/pages/professionals">{{$t("aveine-space.homepage.learnMore")}}</v-btn>
				</div>
			</div>
		</div>
		<div class="aveine-cover d-flex flex-column align-left">
			<v-row class="d-flex flex-xl-row flex-md-row flex-sm-row flex-column justify-left align-xl-center align-md-center align-sm-center ml-xl-8 ml-md-7 ml-sm-7 mx-3">
				<v-col xl="6" md="6" sm="8" class="ma-xl-10 ma-md-10 ma-sm-8 mt-4">
					<h1 class="mb-16">{{$t("aveine-space.homepage.aboutAveineTitle")}}</h1>
					<h3 class="font-weight-regular pl-xl-6 pl-md-5 text-xl-start text-md-start text-center">
						{{$t("aveine-space.homepage.aboutAveineText")}}
					</h3>
					<v-row class="d-flex ma-16 mb-4">
						<v-btn outlined rounded class="pa-6" color="primary" href="https://www.aveine.paris/" target="_blank">{{$t("aveine-space.homepage.discover")}}</v-btn>
					</v-row>
				</v-col>

				<div class="mb-10" style="width: 100vw" v-if="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'">
					<v-img
						alt="Aveine"
						cover
						max-height="200"
						src="@/assets/aveine-banner.jpg"/>
				</div>
			</v-row>
		</div>
	</div>
</template>
<script>
	import { AveineLogin } from 'aveine-toolbox'
	import { errorHandler } from 'aveine-toolbox'
	import { Invitation } from 'aveine-toolbox'
  export default {
	name: 'Login',
  data () {
    return {
			invitationEmail: null
    }
	},
	components:{
		AveineLogin
	},
	computed: {
	},
  methods: {
		/**
		 * Auto Scroll to the advantages discovery section
		 *
		 * @return {void}
		 */
		scrollDiscovery() {
			document.getElementById('advantages').scrollIntoView({behavior: "smooth"});
		},

		/**
		 * Submit the become ambassador form and send an invitation
		 *
		 * @return {void}
		 */
		async becomeAmbassadorSubmit(dialog) {
			try {
				Invitation.api().post(`/pro/invitations/ambassador`, {email: this.invitationEmail}, {baseURL: process.env.VUE_APP_API_PRO_HOST});
				this.$toastr.s(this.$t("aveine-space.title.invitationSent"));
				this.invitationEmail = null;
				dialog.value = false;
      } catch (errors) {
        errorHandler(errors, this)
      }
		},
  },


		mounted () {
			if (this.$auth.roleLoaded) {
				if (localStorage.routeToRedirectTo) {
					this.$router.push(localStorage.routeToRedirectTo)
					localStorage.removeItem("routeToRedirectTo");
				} else if (this.$auth.isAuthenticated) {
					if (this.$auth.ambassador && this.$auth.ambassador.id)
						this.$router.push('/authenticated/pro/dashboard');
					else
						this.$router.push('/authenticated/consumer/dashboard');
				}
			} else {
				this.$auth.$watch('roleLoaded', (roleLoaded) => {
					if (roleLoaded) {
						if (localStorage.routeToRedirectTo) {
              this.$router.push(localStorage.routeToRedirectTo)
              localStorage.removeItem("routeToRedirectTo");
            } else if (this.$auth.isAuthenticated) {
							if (this.$auth.ambassador && this.$auth.ambassador.id)
								this.$router.push('/authenticated/pro/dashboard');
							else
								this.$router.push('/authenticated/consumer/dashboard');
						}
					}
				})
			}
		}
  }
</script>

<style lang="scss">
	@import url("https://use.typekit.net/yff7lve.css");

	div.login {
		white-space: pre-line;
		div.login-header {
			display: flex;
			height: 91vh;
			min-height: 600px;
			color: white;
			background: center no-repeat url('../assets/images/background.jpg');
			background-size: cover;
			padding-left: 50px;
			div.header-banner {
				position: relative;
				bottom: 10vh;
				h1 {
					font-family: 'ivypresto-display', sans-serif;
					font-weight: normal;
					font-size: 62px;
					line-height: 1.3em;
					margin-bottom: 6vh !important;
					margin-top: 10vh !important;
				}
				h2 {
					font-size: 26px;
					line-height: 1.6em;
				}
				button {
					font-weight: bolder;
					background-color: var(--v-secondary-base);
				}
				#login {
					margin: 0;
					position: relative;
					top: 40px;
					z-index: 10;
					color: transparent !important;
					.login-btn {
						i {
							color: transparent;
						}
					}
				}
			}
			div.discover-button {
				color: #1A2A4B;
				position: absolute;
				bottom:3vh;
				left: 0;
				right: 0;
				text-align: center;
				font-size: 11px;
				letter-spacing: 0.1em;
				md-icon {
					position: relative;
					top: 0px;
					transition: top 0.8s ease;
					color: var(--v-secondary-base);
				}
				&:hover {
					cursor: pointer;
					md-icon {
						top: 10px;
						transition: top 0.8s ease;
					}
				}
			}
		}

		@media only screen and (max-width: 960px) {
			div.login-header {
				padding-left: 10px !important;
				div.header-banner {
					h1 {
						font-family: 'ivypresto-display', sans-serif;
						font-weight: normal;
						font-size: 42px;
						line-height: 1.3em;
						margin-bottom: 5vh !important;
						margin-top: 2vh !important;
					}
					h2 {
						font-size: 18px;
						line-height: 1.8em;
					}
					button {
						font-weight: bolder;
						background-color: var(--v-secondary-base);
					}
				}
			}
		}
		div.login-advantages {
			max-width: 88vw;
			align-self: center;
			text-align: center;
			h1 {
				font-family: 'ivypresto-display', sans-serif;
				font-weight: normal;
				font-size: 52px;
				margin: 20px;
			}
			div.advantages {
				min-width: 17vw;
				text-align: center;
				.advantage-title {
					font-family: 'ivypresto-display', sans-serif;
					font-weight: normal;
					font-size: 32px;
				}
				.advantage-text {
					font-size: 14px;
					color: #1A2A4B;
					line-height: 2.5em;
					width: 80%;
					margin: auto;
					margin-bottom: 5vh !important;
				}
			}
		}
		@media only screen and (max-width: 1378px) and (min-width: 961px) {
			div.login-advantages {
				height: 800px !important;
				.advantage-title {
					height: 30vh !important;
				}
				.advantage-text {
					margin-bottom: 0vh !important;
				}
			}
		}
		@media only screen and (max-width: 961px) {
			div.login-advantages {
				height: 100vh !important;
				.advantage-title {
					height: 15vh !important;
				}
				.advantage-text {
					margin-bottom: 0vh !important;
				}
			}
		}
		.pro-block {
			display: flex;
			color: white;
			padding-left: 50px;
			color: white;
			width: 100vw !important;
			height: 80vh !important;
			padding: 0 !important;
			background-color: var(--v-primary-base);
			h1 {
				font-family: 'ivypresto-display', sans-serif;
				font-weight: normal;
				font-size: 62px;
				line-height: 1.3em;
			}
			h2 {
				font-size: 32px;
				line-height: 1.5em;
			}
			button {
				font-weight: bold;
			}
		}
		@media only screen and (max-width: 1378px) and (min-width: 961px) {
			.pro-block {
				height: 600px !important;
				padding-top: 30px;
			}
		}
		@media only screen and (max-width: 960px) {
			.pro-block {
				display: flex;
				color: white;
				padding-left: 50px;
				color: white;
				width: 100vw !important;
				padding: 0 !important;
				background-color: var(--v-primary-base);
				h1 {
					font-family: 'ivypresto-display', sans-serif;
					font-weight: normal;
					font-size: 42px !important;
					line-height: 1.3em;
					margin-bottom: 5vh !important;
					margin-top: 2vh !important;
				}
				h2 {
					font-size: 18px;
					line-height: 1.8em !important;
				}
				button {
					font-weight: bolder;
					background-color: var(--v-secondary-base);
				}
			}
		}
		.aveine-cover {
			width: 100vw !important;
			min-height: 25vw !important;
			padding: 0 !important;
			background-image: url("~@/assets/aveine-banner.jpg");
			background-position: right;
			background-size: 50vw;
			background-color: white;
			box-shadow: 70px 0 0 0 rgba(255, 255, 255);
			h1 {
				font-family: 'ivypresto-display', sans-serif;
				font-weight: normal;
				font-size: 62px;
				line-height: 1.3em;
			}
			h2 {
				font-size: 32px;
				line-height: 1.6em;
			}
			button {
				font-weight: bold;
			}
		}
	}

	@media screen and (max-width: 960px) {
		div.header-banner {
			position: relative;
			bottom: 5vh !important;
		}
	}
	@media only screen and (max-width: 960px) {
    .aveine-cover {
      width: 100vw !important;
      padding: 0 !important;
      background-image: none !important;
      background-position: right;
      background-size: 50vw;
      background-color: white;
      box-shadow: 70px 0 0 0 rgba(255, 255, 255);
			h1 {
				font-family: 'ivypresto-display', sans-serif;
				font-weight: normal;
				font-size: 42px !important;
				line-height: 1.3em;
				margin-bottom: 5vh !important;
				margin-top: 2vh !important;
			}
			h2 {
				font-size: 18px !important;
				line-height: 2em !important;
			}
			button {
				font-weight: bold;
			}
    }
  }
</style>

<template>
  <div class="home py-5">
    <span class="d-flex flex-row page-title">{{$t("aveine-space.title.page.myInvoices")}}</span>
    <v-banner
        dark
        color="secondary"
        v-if="invoiceSummary['amount-unlocked-euro'] || invoiceSummary['amount-unlocked-dollar'] || invoiceSummary['amount-locked-euro'] || invoiceSummary['amount-locked-dollar']"
        class="d-flex flex-xl-row flex-lg-row flex-column justify-center px-xl-16 px-lg-16 mb-8"
      >
        <v-icon
          slot="icon"
          size="36"
        >
          mdi-file-document-edit
        </v-icon>
          <p v-if="invoiceSummary['amount-unlocked-euro'] || invoiceSummary['amount-unlocked-dollar']">{{$t("aveine-space.sales.unlockedMoney")}} {{invoiceSummary["amount-unlocked-euro"] ? invoiceSummary["amount-unlocked-euro"] + " €" : invoiceSummary["amount-unlocked-dollar"] + " $"}}</p>
          <p v-if="invoiceSummary['amount-locked-euro'] || invoiceSummary['amount-locked-dollar']">{{$t("aveine-space.sales.lockedMoney")}} {{invoiceSummary["amount-locked-euro"] ? invoiceSummary["amount-locked-euro"] + " €" : invoiceSummary["amount-locked-dollar"] + " $"}} ({{$t("aveine-space.sales.unlockedAt")}} {{new Date(invoiceSummary["will-unlock-at"]).toLocaleDateString("fr-FR")}})</p> 
        <template v-slot:actions>
          <v-btn
            v-if="invoiceSummary['amount-unlocked-euro'] || invoiceSummary['amount-unlocked-dollar']"
            class="mb-2"
            outlined 
            :to="{ name:'InvoiceCreate', params: {id: $route.params.id} }"
          >
          {{$t("aveine-space.title.declare")}}
          </v-btn>
          <div v-else>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div
                  class="mb-2"
                  v-bind="attrs" v-on="on"
                >
                  <v-icon large>
                    mdi-help
                  </v-icon>
                </div>
              </template>
              <span>{{$t("aveine-invoice.tooltip.moneyAvailable")}}</span> <br>
              <span>{{$t("aveine-invoice.tooltip.moneyLocked")}}</span>
            </v-tooltip>
          </div>
        </template>
      </v-banner>
    <div v-if="dataLoaded" class="sheet-container">
      <div class="d-flex flex-xl-row flex-lg-row flex-column justify-start mt-4 mr-xl-16 mr-lg-12">
        <v-select
          class="ma-6"
          v-model="payedFilter"
          :items="[{text:$t(`aveine-invoice.title.all`), value:null},
                    {text:$t(`aveine-invoice.title.payed`), value:true},
                    {text:$t(`aveine-invoice.title.notPayed`), value:false}]"
          :label="$t('aveine-invoice.title.paymentFilter')"
        ></v-select>
        <v-select
          class="ma-6"
          v-model="sortInvoice"
          :items="[{text:$t(`aveine-invoice.title.desc`), value:'desc'},
                  {text:$t(`aveine-invoice.title.asc`), value:'asc'}]"
          :label="$t('aveine-invoice.title.sort')"
        ></v-select>
    </div>
      <AveineInvoiceManagement v-if="invoices.length" :APIbaseURL="APIbaseURL" :invoices="invoices" />
      <div class="d-flex justify-center align-center flex-column ma-10" v-else>
        <div>
          <v-icon class="ma-6" color="primary" size="128">mdi-close-octagon-outline</v-icon>
        </div>
        <span>{{$t("aveine-invoice.title.noInvoice")}}</span>
      </div>
      <div class="text-center">
        <v-pagination
          v-model="currentPage"
          :length="paginationLength"
          :total-visible="paginationLength < 7 ? paginationLength : 7"
        ></v-pagination>
      </div>
    </div>
    <div v-else class="d-flex justify-center align-center mt-16">
      <v-progress-circular
        indeterminate
        :size="100"
        :width="10"
        color="secondary"
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import { AveineInvoiceManagement } from 'aveine-invoice';
import { Invoice } from 'aveine-toolbox';
import { errorHandler } from 'aveine-toolbox'
export default {
  data () {
    return {
      invoices: [],
      invoiceSummary: {},
      sortInvoice: "desc",
      payedFilter: null,
      currentPage: this.$route.query.page ? this.$route.query.page : 1,
      paginationLength: 0,
      dataLoaded: false,
      APIbaseURL: process.env.VUE_APP_API_PRO_HOST
    }
  },
  name: 'Invoice',
  components: {
    AveineInvoiceManagement
  },

  watch: {
    payedFilter () {
      this.loadInvoices();
    },
    sortInvoice () {
      this.loadInvoices()
    },
    async currentPage (val) {
      this.dataLoaded= false;
      this.$router.push({ path: '/authenticated/pro/invoices', query: { page: val }});
      this.loadInvitations()
    },
  },
  
  async mounted() {
    this.loadInvoices();
     this.authToken = await this.$auth.getTokenSilently();

    let fetchTotalSummary = await fetch(`${this.APIbaseURL}/orders/ambassadors/${this.$route.params.id}/summary`, {
      method: "GET",
      headers: {
        "Authorization": `Bearer ` + this.authToken
      }
    })

    const invoiceSummaryJson = await fetchTotalSummary.json();
    this.invoiceSummary = invoiceSummaryJson.meta;
  },
  methods: {
    async loadInvoices () {
      try {
        this.invoices = [];
        this.dataLoaded = false;
        
        let params =  
        {
          "page[number]": this.$route.query.page || 1,
          "page[size]": 40,
          "include": 'ambassador.user, super-ambassador.user'
        };

        if (this.payedFilter == true) 
          params[`filter[paid-at][NE]`] = "null"
        else if (this.payedFilter == false)
          params[`filter[paid-at]`] = "null"
        else 
          params[`filter[paid-at]`] = null

        if (this.sortInvoice == "desc")
          params[`sort`] = "-created-at"
        else if  (this.sortInvoice == "asc")
          params[`sort`] = "created-at"


        let invoices = await Invoice.api().get(`ambassadors/${this.$route.params.id}/invoices?lang=${this.$i18n.locale}`, {
            baseURL: this.APIbaseURL,
            params
          }
        );

        const that = this;
        invoices.response.data.map(async function(data){
          that.invoices.push(await Invoice.query().whereId(data.id).withAllRecursive().first());
        })
        this.paginationLength = invoices.response.data.meta.last
        this.dataLoaded = true;
      } catch (errors) {
        this.dataLoaded = true;
        errorHandler(errors, this)
      }
    }
  }
}
</script>

<template>
  <div class="home py-5">
    <div v-if="dataLoaded">
      <div class="d-flex flex-column align-center justify-center ma-xl-6 ma-lg-6">
        <v-row v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'" class="dashboard-stat-tabs d-flex flex-lg-row flex-column justify-center">
          <v-col>
            <v-col class="tab d-flex">
              <v-row  class="mx-3">
                <v-img class="tab-images" src="@/assets/scan_vin.png" contain :alt='$t("aveine-space.dashboard.wineForm")'></v-img>
              </v-row>
              <v-row class="d-flex flex-column justify-center text-center mr-10">
                <br> {{$t("aveine-space.newsfeed.scannedWines")}} <br>
                
                <span class="numbers">
                  <b>{{scanCount}}</b>
                </span>
                <v-btn outlined class="d-flex align-center ma-4" :to="{ name: 'WineHistory' }">
                  {{$t("aveine-space.newsfeed.lastSeenWines")}}
                  <v-icon>
                    mdi-chevron-right
                  </v-icon>
                </v-btn>
              </v-row>
            </v-col>
          </v-col>
          <v-col v-if="$auth.userProfile.tasting_profile.completionRate < 100">
            <v-col class="tab d-flex">
              <v-row  v-if="$auth.userProfile.tasting_profile.completionRate > 0" class="d-flex align-center mx-3">
                <v-progress-circular
                  :rotate="-90"
                  :size="80"
                  :width="8"
                  :value="$auth.userProfile.tasting_profile.completionRate"
                  color="secondary"
                >
                  {{ $auth.userProfile.tasting_profile.completionRate }}%
                </v-progress-circular>        
              </v-row>
              <v-row v-else class="mx-3">
                <v-img class="tab-images" src="@/assets/tasting_profile.png" contain :alt='$t("aveine-space.dashboard.wineForm")'></v-img>
              </v-row>
              <v-row class="d-flex flex-column justify-center text-center mr-3 ml-3">
                <br>{{$t("aveine-space.newsfeed.myTastingProfile")}}<br>
                <span class="d-flex flex-column align-center justify-center numbers">
                  <v-dialog
                    transition="dialog-bottom-transition"
                    max-width="1000"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <span class="text-body-2 my-1">{{$t("aveine-space.newsfeed.myTastingProfileText")}}</span>
                      <v-btn outlined class="d-flex align-center ma-4" v-bind="attrs" v-on="on">
                        {{$t("aveine-space.newsfeed.completeMyProfile")}}
                      </v-btn>
                    </template>
                    <template v-slot:default="dialog">
                      <v-card>
                        <v-toolbar
                          class="text-h6 px-6"
                          color="primary"
                          dark
                        >{{$t("aveine-space.newsfeed.myPreferences")}}                   
                          <v-spacer></v-spacer>
                        </v-toolbar>
                        <v-card-text class="pa-0">
                          <AveineTastingProfileForm :APIbaseURL="APIbaseURL" :tastingProfile="$auth.userProfile.tasting_profile" :callback="function() {dialog.value = false}" />
                        </v-card-text>
                      </v-card>
                    </template>
                  </v-dialog>
                </span>
              </v-row>
            </v-col>
          </v-col>
          <v-col v-else>
            <v-col class="tab d-flex">
              <v-row class="mx-3">
                <v-img class="tab-images" src="@/assets/badges.png" contain></v-img>
              </v-row>
              <v-row  class="d-flex flex-row justify-center text-center">
                <template>
                  <v-card
                    max-height="64"
                    max-width="64"
                    class="d-flex flex-column mx-5 badge"
                  >
                    <v-img max-height="52" v-if="$auth.userProfile.shelve[0]" :src="require(`@/assets/badges/${$auth.userProfile.shelve[0]}.png`)"></v-img>
                    <v-img max-height="52" class="inactive" v-else src="@/assets/badges/scan_red_level_3.png"></v-img>
                  </v-card>
                </template>
                <template>
                  <v-card
                    max-height="64"
                    max-width="64"
                    class="d-flex flex-column mx-5 badge"
                  >
                    <v-img max-height="52" v-if="$auth.userProfile.shelve[1]" :src="require(`@/assets/badges/${$auth.userProfile.shelve[1]}.png`)"></v-img>
                    <v-img max-height="52" class="inactive" v-else src="@/assets/badges/scan_white_level_3.png"></v-img>
                  </v-card>
                </template>
                <template>
                  <v-card
                    max-height="64"
                    max-width="64"
                    class="d-flex flex-column mx-5 badge"
                  >
                    <v-img max-height="52" v-if="$auth.userProfile.shelve[2]" :src="require(`@/assets/badges/${$auth.userProfile.shelve[2]}.png`)"></v-img>
                    <v-img max-height="52" class="inactive" v-else src="@/assets/badges/scan_rose_level_3.png"></v-img>
                  </v-card>
                </template>
                <v-btn outlined class="d-flex align-center ma-4" :to="{ name: 'BadgeIndex' }">
                  {{ $t("aveine-space.newsfeed.seeAllBadges") }}
                  <v-icon>
                    mdi-chevron-right
                  </v-icon>
                </v-btn>
              </v-row>
            </v-col>
          </v-col>
          <v-col>
            <v-col class="tab d-flex">
              <v-row  class="mx-3">
                <v-img class="tab-images" src="@/assets/avis.png" contain :alt='$t("aveine-space.dashboard.wineForm")'></v-img>
              </v-row>
              <v-row class="d-flex flex-column justify-center text-center mr-10">
                <br> {{$t("aveine-space.newsfeed.reviewsWritten")}}<br>
                
                <span class="numbers">
                  <b>{{commentCount}}</b>
                </span>
                <v-btn outlined class="d-flex align-center ma-4" :to="{ name: 'UserRead', params:{id: 'self'} }">
                  {{$t("aveine-space.newsfeed.seeAllReviews")}}
                  <v-icon>
                    mdi-chevron-right
                  </v-icon>
                </v-btn>
              </v-row>
            </v-col>
          </v-col>
        </v-row>
        <v-col class="d-flex flex-column px-1 dashboard-container">
          <v-card
            v-for="(newsCard, index) in newsCards" :key="index" class="d-flex flex-column ma-xl-8 ma-lg-6 mb-3 dashboard-card"
            outlined
          >
            <v-list-item class="pa-0" two-line>
              <v-list-item-content>
                <div class="mb-2 blog-container">
                  <div v-if="newsCard.kind =='Blog'">
                    <a href="https://www.aveine.paris/blog/" target="_blank"  class="d-flex flex-row card-header">
                      <v-icon size="50" color="secondary">mdi-newspaper</v-icon>
                      <div class="d-flex font-italic font-weight-bold ma-5">
                        {{$t("aveine-space.newsfeed.newBlogArticle")}} <br>
                        {{new Date(newsCard.blog.published_at).toLocaleDateString("fr-FR")}}
                      </div>
                    </a>
                    
                    <div class="d-flex flex-column ma-3 ml-10">
                      <a class="text-h5 mb-5" :href="newsCard.blog.url">{{newsCard.blog.title}}</a> <br>
                      <p class="text-subtitle font-italic" >{{newsCard.blog.excerpt}}</p>
                    </div>
                    <div class="d-flex flex-column justify-center">
                      <v-img class="blog-picture" :src="newsCard.blog.picture"></v-img>
                    </div>
                    <v-btn :href="newsCard.blog.url" target="_blank" outlined class="d-flex justify-center align-center mt-5">
                      {{$t("aveine-space.newsfeed.readMore")}}
                    </v-btn>
                  </div>
                  <div v-if="newsCard.kind =='Ambassador'">
                    <div class="d-flex flex-row card-header">
                      <v-icon size="50" color="secondary" >mdi-account-outline</v-icon>
                      <div class="d-flex font-italic font-weight-bold ma-5">
                        {{$t("aveine-space.newsfeed.newAveineAmbassador")}}
                      </div>
                    </div>
                    <div class="d-flex justify-center align-center">
                      <v-avatar
                        color="primary ma-5"
                        size="250"
                      >
                        <img
                          v-if="newsCard.ambassador.image"
                          :src="newsCard.ambassador.image"
                        >
                        <img
                          v-else
                          src="@/assets/placeholder-image.png"
                        >
                      </v-avatar>
                    </div>

                    <div class="d-flex flex-column justify-center align-center mb-3 text-h5 font-weight-light" >
                      <span class="text-center">
                        {{newsCard.ambassador.name}}<br>
                        {{newsCard.ambassador.kind[$i18n.locale]}} - {{newsCard.ambassador.specialities[$i18n.locale]}}
                      </span> 
                      <v-btn :to="{ name: 'AmbassadorRead', params: { id: newsCard.ambassador.id }}" outlined class="d-flex justify-center mt-5">
                        {{$t("aveine-space.newsfeed.learnMore")}}
                      </v-btn>
                    </div>
                  </div>
                  <div v-if="newsCard.kind =='Changelog'">
                    <div class="d-flex flex-row card-header">
                      <v-icon size="50" color="secondary" >mdi-receipt-text-outline</v-icon>
                      <div class="d-flex font-italic font-weight-bold ma-5">
                        {{$t("aveine-space.newsfeed.newUpdate")}} {{newsCard.changelog.component == "aveine_original" ? "Aveine" : 
                              newsCard.changelog.component == "aveine_estl" ? "Aveine Essentiel" :
                              newsCard.changelog.component == "ios" ? "IOS" : 
                              newsCard.changelog.component == "android" ? "Android" : null
                            }} {{newsCard.changelog.version}} <br>
                      </div>
                    </div>
                    <div class="mb-3 text-h5 font-italic font-weight-light" >
                      <v-expansion-panels focusable>
                        <v-expansion-panel>
                          <v-expansion-panel-header class="d-flex flex-xl-row flex-lg-row flex-column align-center justify-center">
                            <v-btn outlined class="d-flex justify-center">
                              {{$t("aveine-space.newsfeed.learnMore")}}
                            </v-btn>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <div class="markdown-container px-xl-10 px-lg-6" v-html="newsCard.changelog.markdown[$i18n.locale] ? newsCard.changelog.markdown[$i18n.locale] : newsCard.changelog.markdown.fr"></div>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </div>
                  </div>
                  <div v-if="newsCard.kind =='Wine'">
                    <div class="d-flex flex-row card-header">
                      <v-avatar
                        color="primary ma-2"
                        size="50"
                      >
                        <img
                          :src="newsCard.profile.avatar"
                        >
                      </v-avatar>
                      <v-icon size="50" color="misc" >mdi-glass-wine</v-icon>
                      <div class="d-flex font-italic font-weight-bold ma-5">
                        {{newsCard.profile.name}} {{$t("aveine-space.newsfeed.scannedAWine")}}
                      </div>
                    </div>
                    <div class="d-flex flex-xl-row flex-md-row flex-column justify-center align-center">
                      <v-col cols="5" class="d-flex justify-xl-start justify-md-start justify-center align-center">
                        <v-img  max-height="400" max-width="300" :src="newsCard.wine.primary_wine_label.image"></v-img>
                      </v-col>
                      <v-divider vertical></v-divider>
                      <v-col cols="5" class="d-flex flex-column align-center justify-center mb-3 text-h6 font-weight-light text-center">
                        {{newsCard.wine.vintage}}
                        {{newsCard.wine.cuvee}} <br> 
                        {{newsCard.wine.template.domain ? newsCard.wine.template.domain.name : null}}
                        {{newsCard.wine.template.region ? newsCard.wine.template.region.name[$i18n.locale] : null}} - {{newsCard.wine.template.region ? newsCard.wine.template.country.name[$i18n.locale] : null}}
                        {{newsCard.wine.appellation ? newsCard.wine.appellation.name : null}}
                        {{newsCard.wine.cru}}
                        <div class="d-flex flex-row justify-space-between">
                          <p :class="'color-'+newsCard.wine.template.color">{{$t(`aveine-wine.attributes.wineTypes.${newsCard.wine.template.color}`)}}</p>
                          <p class="mx-2"> - </p>
                          <p v-if="!newsCard.wine.template.effervescence">{{$t("aveine-wine.attributes.wineTypes.still")}}</p>
                          <p v-if="newsCard.wine.template.effervescence == 'sparkling'">{{$t("aveine-wine.attributes.wineTypes.sparkling")}}</p>
                          <p v-if="newsCard.wine.template.effervescence == 'semi_sparkling'">{{$t("aveine-wine.attributes.wineTypes.semiSparkling")}}</p>
                          <p v-if="newsCard.wine.template.effervescence == 'beady'">{{$t("aveine-wine.attributes.wineTypes.beady")}}</p>
                        </div>
                        <v-btn :to="{ name: 'WineRead', params: { id: newsCard.wine.id }}" outlined class="d-flex justify-center mt-5">
                          {{$t("aveine-space.newsfeed.learnMore")}}
                        </v-btn>
                      </v-col>
                    </div>
                  </div>
                  <div v-if="newsCard.kind =='Comment'">
                    <div class="d-flex flex-row card-header">
                      <v-avatar
                        color="primary ma-2"
                        size="50"
                      >
                        <img
                          :src="newsCard.profile.avatar"
                        >
                      </v-avatar>
                      <v-icon size="50" color="misc" >mdi-comment-outline</v-icon>
                      <div class="d-flex font-italic font-weight-bold ma-5">
                        {{newsCard.profile.name}} {{$t("aveine-space.newsfeed.leftAReview")}}
                      </div>
                    </div>
                    <div class="d-flex flex-xl-row flex-md-row flex-column justify-center align-center">
                      <v-col v-if="newsCard.comment.wine" cols="5" class="d-flex justify-center align-center">
                        <v-img  max-height="400" max-width="300" :src="newsCard.comment.wine.wine_labels[0].image"></v-img>
                      </v-col>
                      <v-divider vertical></v-divider>
                      <v-col class="d-flex flex-column justify-center align-center">
                        <span v-if="newsCard.comment.wine" class="d-flex align-center mx-1 my-2 text-center">
                          {{newsCard.comment.wine.vintage}}
                        </span>
                          
                        <router-link :to="{ name: 'WineRead', params: { id: newsCard.comment.wine.id }}" v-if="newsCard.comment.wine"  class="d-flex align-center mx-1 my-2 text-center text-weight-bold">
                          {{newsCard.comment.wine.cuvee}} <br> 
                          {{newsCard.comment.wine.template.domain ? newsCard.comment.wine.template.domain.name : null}}
                          {{newsCard.comment.wine.template.region ? newsCard.comment.wine.template.region.name[$i18n.locale] : null}} - {{newsCard.comment.wine.template.region ? newsCard.comment.wine.template.country.name[$i18n.locale] : null}}
                          {{newsCard.comment.wine.appellation ? newsCard.comment.wine.appellation.name : null}}
                          {{newsCard.comment.wine.cru}}
                        </router-link>
                        <div class="ma-3 mb-0 text-h6 font-italic font-weight-light" >
                          <p v-if="newsCard.comment && newsCard.comment" class="flex-wrap">
                            ❝ {{newsCard.comment.comment}} ❞
                          </p>
                        </div>
                        <div class="d-flex flex-row">
                          <div class="d-flex flex-row mb-10">
                            <v-img v-if="newsCard.comment.aeration" class="pictogram mx-4" src="@/assets/glass.png" contain max-width="12"/>
                            <span class="mt-3" v-if="newsCard.comment.aeration">{{newsCard.comment.aeration}}h</span>
                          </div>
                          <v-rating
                            readonly
                            class="mb-5 mx-5"
                            v-if="newsCard.comment.rating != null"
                            v-model="newsCard.comment.rating"
                            :length="5"
                            color="secondary"
                            background-color="grey lighten-1"
                          ></v-rating>
                          <!-- <div class="d-flex flex-column">
                            <span @click="upvote(newsCard.comment)" class="d-flex align-center mx-1 my-2"><v-icon color="primary" class="mb-1 mr-3">mdi-thumb-up</v-icon> {{newsCard.comment.comment_upvote.length + 1}}</span>
                          </div> -->
                        </div>
                      </v-col>
                    </div>
                  </div>
                  <div v-if="newsCard.kind =='Suggestion'">
                    <div class="d-flex flex-row card-header">
                      <v-icon size="50" color="misc" >mdi-bottle-wine-outline</v-icon>
                      <div class="d-flex font-italic font-weight-bold ma-5">
                        {{$t("aveine-space.newsfeed.suggestionOfTheDay")}}
                      </div>
                    </div>
                    <div class="d-flex flex-xl-row flex-md-row flex-column justify-center align-center">
                      <v-col cols="5" class="d-flex justify-xl-start justify-md-start justify-center align-center">
                        <v-img  max-height="400" max-width="300" :src="newsCard.wine.primary_wine_label.image"></v-img>
                      </v-col>
                      <v-divider vertical></v-divider>
                      <v-col cols="5" class="d-flex flex-column align-center justify-center mb-3 text-h6 font-weight-light text-center">
                        {{newsCard.wine.vintage}}
                        {{newsCard.wine.cuvee}} <br> 
                        {{newsCard.wine.template.domain ? newsCard.wine.template.domain.name : null}}
                        {{newsCard.wine.template.region ? newsCard.wine.template.region.name[$i18n.locale] : null}} - {{newsCard.wine.template.region ? newsCard.wine.template.country.name[$i18n.locale] : null}}
                        {{newsCard.wine.appellation ? newsCard.wine.appellation.name : null}}
                        {{newsCard.wine.cru}}
                        <div class="d-flex flex-row justify-space-between">
                          <p :class="'color-'+newsCard.wine.template.color">{{$t(`aveine-wine.attributes.wineTypes.${newsCard.wine.template.color}`)}}</p>
                          <p class="mx-2"> - </p>
                          <p v-if="!newsCard.wine.template.effervescence">{{$t("aveine-wine.attributes.wineTypes.still")}}</p>
                          <p v-if="newsCard.wine.template.effervescence == 'sparkling'">{{$t("aveine-wine.attributes.wineTypes.sparkling")}}</p>
                          <p v-if="newsCard.wine.template.effervescence == 'semi_sparkling'">{{$t("aveine-wine.attributes.wineTypes.semiSparkling")}}</p>
                          <p v-if="newsCard.wine.template.effervescence == 'beady'">{{$t("aveine-wine.attributes.wineTypes.beady")}}</p>
                        </div>
                        <v-btn :to="{ name: 'WineRead', params: { id: newsCard.wine.id }}" outlined class="d-flex justify-center mt-5">
                          Voir plus
                        </v-btn>
                      </v-col>
                    </div>
                  </div>
                  <div v-if="newsCard.kind =='FillTastingProfile'">
                    <div class="d-flex flex-row card-header">
                      <v-icon size="50" color="misc" >mdi-pencil-plus-outline</v-icon>
                      <div class="d-flex font-italic font-weight-bold ma-5">
                        {{$t("aveine-space.newsfeed.myTastingProfileText")}}
                      </div>
                    </div>
                    <v-dialog
                      transition="dialog-bottom-transition"
                      max-width="1000"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <div class="d-flex justify-center align-center">
                          <v-btn outlined class="d-flex justify-center align-center ma-4" v-bind="attrs" v-on="on">
                            {{$t("aveine-space.newsfeed.completeMyProfile")}}
                          </v-btn>
                        </div>
                      </template>
                      <template v-slot:default="dialog">
                        <v-card>
                          <v-toolbar
                            class="text-h6 px-6"
                            color="primary"
                            dark
                          >{{$t("aveine-space.newsfeed.myPreferences")}}                    
                            <v-spacer></v-spacer>
                          </v-toolbar>
                          <v-card-text class="pa-0">
                            <AveineTastingProfileForm :APIbaseURL="APIbaseURL" :tastingProfile="$auth.userProfile.tasting_profile" :callback="function() {dialog.value = false}" />
                          </v-card-text>
                        </v-card>
                      </template>
                    </v-dialog>
                  </div>
                  <div v-if="newsCard.kind =='Instagram'">
                    <a :href="'https://instagram.com/'+ newsCard.media.account_handle" target="_blank" class="d-flex flex-row card-header">
                      <v-icon size="50" color="secondary" >mdi-instagram</v-icon>
                      <div class="d-flex font-italic font-weight-bold ma-5">
                        @{{newsCard.media.account_handle}}
                        <br>
                        <br>
                        {{new Date(newsCard.media.timestamp).toLocaleDateString("fr-FR")}}
                      </div>
                    </a>
                    <div class="d-flex flex-row align-center justify-center">
                      <div class="d-flex flex-column align-center justify-center ma-10" style="max-width:100%">
                        <v-img v-if="newsCard.media.kind == 'IMAGE'" max-height="400" max-width="400" :src="newsCard.media.media_url"></v-img>
                        <v-img v-if="newsCard.media.kind == 'VIDEO'" max-height="400" max-width="400" :src="newsCard.media.thumbnail_url"></v-img>
                        <a :href="newsCard.media.url" target="_blank" style="max-height: 180px; overflow: hidden; text-overflow: ellipsis;" class="mt-10 mx-2 mb-4 text-subtitle font-italic">
                          {{newsCard.media.caption}}
                        </a>
                        <v-btn v-if="$vuetify.breakpoint.name == 'xs'" outlined :href="'https://instagram.com/'+ newsCard.media.account_handle" target="_blank" class="d-flex justify-end mt-4">
                          {{$t("aveine-space.newsfeed.followOnInstagram")}}
                        </v-btn>
                      </div>
                      <v-divider v-if="$vuetify.breakpoint.name !== 'xs'" vertical></v-divider>
                      <div v-if="$vuetify.breakpoint.name !== 'xs'" class="d-flex flex-column ma-10">
                        <div class="d-flex flex-column justify-end align-start instagram-promotion">
                          <v-icon size="50" >mdi-instagram</v-icon>
                          <v-btn outlined :href="'https://instagram.com/'+ newsCard.media.account_handle" target="_blank" class="d-flex justify-end mt-4">
                            {{$t("aveine-space.newsfeed.followOnInstagram")}}
                          </v-btn>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-card>
          <div v-intersect="infiniteScrolling">
            <div v-if="!additionalDataLoaded" class="d-flex justify-center align-center mt-16">
              <v-progress-circular
                indeterminate
                :size="60"
                :width="6"
                color="secondary"
              ></v-progress-circular>
            </div>
          </div>
        </v-col>
      </div>
    </div>
    <div v-else class="d-flex justify-center align-center mt-16">
      <v-progress-circular
        indeterminate
        :size="100"
        :width="10"
        color="secondary"
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import AveineTastingProfileForm from '@/components/AveineTastingProfileForm.vue'
import { NewsfeedEntry, TastingProfile, WineUserSetting, Scan, WineUserComment, WineUserCommentUpvote } from 'aveine-toolbox';
import { marked } from 'marked';
import { errorHandler } from 'aveine-toolbox'
export default {
  data () {
    return {
      newsCards: [],
      newsfeedToken: "",
      currentPage: this.$route.query.page ? this.$route.query.page : 1,
      lastScan: {},
      scanCount: "",
      commentCount: "",
      paginationLength: 0,
      dataLoaded: false,
      additionalDataLoaded: true,
      APIbaseURL: process.env.VUE_APP_API_CONSUMER_HOST
    }
  },
  name: 'ConsumerDashboard',
  components: { AveineTastingProfileForm },
  async created() {
    this.loadData();
    if (!this.$auth.userProfile.tasting_profile || Object.keys(this.$auth.userProfile.tasting_profile).length === 0) {
      this.$auth.userProfile.tasting_profile = await TastingProfile.new()
      this.$auth.userProfile.tasting_profile.$isNew = true
    }

    let scanCountRequest = await Scan.api().get(`users/self/scans/count?lang=${this.$i18n.locale}`, {baseURL: this.APIbaseURL})
    this.scanCount = scanCountRequest.response.request.response;

    let commentRequest = await WineUserComment.api().get(`/users/self/comments?lang=${this.$i18n.locale}`, {baseURL: this.APIbaseURL})
    this.commentCount = commentRequest.response.data.length

    let wineSettingRequest = await WineUserSetting.api().get(`users/self/wine-settings?lang=${this.$i18n.locale}`, {
        baseURL: this.APIbaseURL,
        params: {
          "include": "wine.primary-wine-label",
          "sort": "-last-access",
          "page[number]": 1,
          "page[size]": 1
        }
      }
    );


    wineSettingRequest.response.data.map(async (wineSetting) => {
      const wineUserSetting = await WineUserSetting.query().whereId(wineSetting.id).withAllRecursive().first()
      let wineToPush = wineUserSetting.wine
      this.lastScan = wineToPush;
    });
    this.completionRateCalculation();
  },
  methods: {
    async loadData () {
      try {
        const newsfeedRequest = await NewsfeedEntry.api().get(`newsfeed?lang=${this.$i18n.locale}`, {
          baseURL: this.APIbaseURL,
          params: {
            "newsfeed-token": this.newsfeedToken,
            "page[size]": 20,
            "page[number]": this.currentPage,
          }
        });

        newsfeedRequest.response.data.map(async (newsfeedEntry) => {
          const newsFeedEntryToPush = await NewsfeedEntry.query().whereId(newsfeedEntry.id).withAllRecursive().first()
          if (newsFeedEntryToPush.kind == "Changelog") {
            Object.keys(newsFeedEntryToPush.changelog.markdown).map(function(lang){
              if (newsFeedEntryToPush.changelog.markdown[lang]) {
                newsFeedEntryToPush.changelog.markdown[lang] = marked(newsFeedEntryToPush.changelog.markdown[lang], { sanitize: true })
              }
            })
          }
          this.newsCards.push(newsFeedEntryToPush)
        })

        await NewsfeedEntry.deleteAll()
        if (!this.newsfeedToken)
          this.newsfeedToken = newsfeedRequest.response.data.meta['newsfeed-token']

        this.currentPage++;
        
        this.dataLoaded = true;
      } catch (errors) {
        this.dataLoaded = true;
        errorHandler(errors, this)
      }
    },
    async upvote (comment) {
      try {
        await WineUserCommentUpvote.api().post(`comments/${comment.id}/upvotes?lang=${this.$i18n.locale}`, null, {baseURL: this.APIbaseURL});
        comment.upvote += 1;
      } catch (errors) {
        errorHandler(errors, this)
      }
    },
    infiniteScrolling () {
      this.additionalDataLoaded = false
      this.loadData()
    },
    completionRateCalculation () {
      // Completion rate calcultation
      let completion = 0;

      if (this.$auth.userProfile.tasting_profile.red) {
        completion += 1
      }
      if (this.$auth.userProfile.tasting_profile.white) {
        completion += 1
      }
      if (this.$auth.userProfile.tasting_profile.rose) {
        completion += 1
      }
      if (this.$auth.userProfile.tasting_profile.effervescent) {
        completion += 1
      }
      if (this.$auth.userProfile.tasting_profile.still) {
        completion += 1
      }
      if (this.$auth.userProfile.tasting_profile.regions[0] && this.$auth.userProfile.tasting_profile.regions[0].id) {
        completion += 1
      }
      if (this.$auth.userProfile.tasting_profile.appellations[0] && this.$auth.userProfile.tasting_profile.appellations[0].id) {
        completion += 1
      }
      if (this.$auth.userProfile.tasting_profile.grapes[0] && this.$auth.userProfile.tasting_profile.grapes[0].id) {
        completion += 1
      }
      if (this.$auth.userProfile.tasting_profile.vegan) {
        completion += 1
      }
      if (this.$auth.userProfile.tasting_profile.organic) {
        completion += 1
      }
      if (this.$auth.userProfile.tasting_profile.natural) {
        completion += 1
      }
      this.$auth.userProfile.tasting_profile.completionRate = Math.round((completion / 11) * 100);
    },
  }
}
</script>
<style lang="scss" scoped>
  .dashboard-container {
    width: 50vw;
    margin-left: 18vw;
    margin-right: 18vw;
    margin-bottom: 72px;
  }

  div.dashboard-stat-tabs {
    width: 80vw;
    div.tab {
      min-height: 175px;
      max-width: 25vw;
      box-shadow: 0 3px 5px 1px #80808069;
      background-color: white;
      border-radius: 0 20px 20px 20px;
      .tab-images {
        max-width: 60px;
        max-height: 100%;
      }
      span.numbers {
        font-weight: lighter;
        b {
          font-weight: bolder;
          font-size: 16px;
        }
      }
    }
  }

  @media all and (max-width: 720px) {
    .dashboard-container {
      width: 100vw;
      margin-bottom: 72px;
    }
  }

  @media all and (min-width: 720px) and (max-width: 1904px) {
    .dashboard-container {
      width: 70vw;
      margin-left: 15vw;
      margin-right: 15vw;
      margin-bottom: 72px;
    }
    div.dashboard-stat-tabs {
      width: 100vw;
      div.tab {
        min-height: 175px;
        max-width: 33vw;
        box-shadow: 0 3px 5px 1px #80808069;
        background-color: white;
        border-radius: 0 20px 20px 20px;
        .tab-images {
          max-width: 60px;
          max-height: 100%;
        }
        span.numbers {
          font-weight: lighter;
          b {
            font-weight: bolder;
            font-size: 16px;
          }
        }
      }
    }
  }
  .badge .inactive {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    opacity: 0.3;
  }

  .dashboard-card {
    box-shadow: 0 3px 5px 1px #80808069;
    background-color: white;
    border-radius: 0 20px 20px 20px;
  }

  .card-header {
    border-bottom: 4px solid var(--v-primary-base);
    padding: 15px;
    text-decoration: none !important;
    margin-bottom: 20px;
  }

  div.blog-container {
    max-width: 100% !important;
  }

  div.blog-picture {
    max-width: 100%;
    max-height: 300px;
    object-fit: cover;
    overflow: hidden;
  }

  div.instagram-promotion {
    width: 400px;
    height: 400px;
    background: right no-repeat url('../../../assets/logo.png');
    background-size: cover;
    background-position: 100px -50px;
  }

  div.markdown-container {
    overflow-y: scroll;
    overflow-wrap: break-word;
    height: 100%;
    max-height: 50vh;
    //Scrollbar style for Firefox
    scrollbar-color: var(--v-primary-base) #F8F8F8;
    scrollbar-width: thin;
  }
  //Scrollbar style for webkit browsers
  div.markdown-container::-webkit-scrollbar {
    width: 8px;
  }
  div.markdown-container::-webkit-scrollbar-track {
    margin: 20px;
    background: #F8F8F8;
    border-radius: 10px;
  }
  div.markdown-container::-webkit-scrollbar-thumb {
    background: var(--v-primary-base);
    border-radius: 10px;
  } 
  div.markdown-container img {
    max-width: 100%;
  }
</style>


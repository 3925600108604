<template>
  <v-container class="wrapper d-flex flex-column justify-center align-center py-16">
    <div>
      <v-img src="@/assets/page-not-found.png" max-width="300"/>
    </div>
    <h3 class="text-center">{{$t("aveine-space.errors.pageNotFound")}}</h3>
  </v-container>
</template>

<script>

  export default {
    name: 'PageNotFound',
  }
</script>

<template>
  <div class="home py-5">
    <div v-if="dataLoaded" class="sheet-container">
      <AveineDomainSheet :APIbaseURL="APIbaseURL" :domain="domain" />
    </div>
    <div v-else class="d-flex justify-center align-center mt-16">
      <v-progress-circular
        indeterminate
        :size="100"
        :width="10"
        color="secondary"
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import { AveineDomainSheet } from 'aveine-domain'
import { Domain } from 'aveine-toolbox'
import { errorHandler } from 'aveine-toolbox'
export default {
  data () {
    return {
      domain: {},
      dataLoaded: false,
      APIbaseURL: process.env.VUE_APP_API_CONSUMER_HOST
    }
  },
  name: 'Read',
  components: {
    AveineDomainSheet
  },
  async created() {
    try {
      await Domain.api().get(`domains/${this.$route.params.id}?lang=${this.$i18n.locale}`, {
          baseURL: process.env.VUE_APP_API_CONSUMER_HOST,
          params: {
            include:
            'region,' +
            'address.spatial-information,address.country',
          }
        }
      );
      this.domain = await Domain.query().whereId(this.$route.params.id).withAllRecursive().first();
      this.dataLoaded = true;
    } catch (errors) {
      this.dataLoaded = true;
      errorHandler(errors, this)
    }
  }
}
</script>

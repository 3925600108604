<template>
  <v-app>
    <v-app-bar
      app
      light
      elevation="10"
      :flat="true"
    >
      <div class="d-flex align-center toolbar-left">
        <div class="d-flex mr-6 align-center menu-button">
          <v-btn
            v-if="$auth.isAuthenticated"
            icon
            color="white"
            @click.stop="toggleMenu = !toggleMenu"
          >
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </div>

        <div v-if="$vuetify.breakpoint.name !== 'xs' && $vuetify.breakpoint.name !== 'sm'" class="d-flex">
          <router-link :to="{name: 'ConsumerDashboard'}">
            <v-img
              alt="Aveine"
              class="shrink mt-1"
              contain
              min-width="135"
              src="@/assets/logo-aveine.png"
              width="100"
            />
          </router-link>
        </div>
      </div>

      <v-spacer class="mx-3"></v-spacer>

      <v-spacer v-if="!$auth.isAuthenticated"></v-spacer>
      
      <div v-if="$auth.loading" class="d-flex justify-center">
        <v-progress-circular
          indeterminate
          :size="25"
          :width="5"
          color="secondary"
      ></v-progress-circular>
      </div>
      <div v-else class="d-flex align-center">
        <v-btn v-if="$auth.isAuthenticated" class="d-flex align-center mx-1 mr-4" color="black" icon @click="expandSearch = !expandSearch">
          <v-icon>
            mdi-magnify
          </v-icon>
        </v-btn>
        <div class="mb-1">
          <AveineButtonLanguage/>
        </div>
        <v-row v-if="$auth.isAuthenticated && $auth.userProfile" class="user-profile">
          <router-link v-if="$vuetify.breakpoint.name !== 'xs'" class="d-flex align-center mx-xl-5 mx-lg-3 mt-2" :to="{ name: 'UserRead', params: {id:'self'}}">
            <v-avatar
              color="primary"
              size="32"
            >
              <img
                :src="$auth.userProfile.avatar"
              >
            </v-avatar>
          </router-link>
          <router-link :to="{ name: 'UserRead', params: {id:'self'}}" v-if="$vuetify.breakpoint.name !== 'xs'" class="d-flex align-center font-weight-light mx-1">{{this.$auth.userProfile.name}}</router-link>
          <v-dialog
            transition="dialog-bottom-transition"
            max-width="1000"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="d-flex align-center mx-1 mr-4" v-bind="attrs" v-on="on" icon color="black">
                <v-icon>mdi-cog-outline</v-icon>
              </v-btn>
            </template>
            <template v-slot:default="dialog">
              <v-card>
                <v-toolbar
                  class="text-h6 px-6"
                  color="primary"
                  dark
                >{{$t("aveine-space.profile.profile")}}
                  <v-spacer></v-spacer>
                  <v-btn icon @click="dialog.value = false" >
                    <v-icon color="white">mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-card-text class="d-flex flex-column pa-0">
                  <v-tabs
                    v-model="currentUserTab"
                    background-color="primary"
                    center-active
                    dark
                  >
                    <v-tab href="#tab-1">{{$t("aveine-space.profile.editProfile")}}</v-tab>
                    <v-tab href="#tab-2">{{$t("aveine-space.profile.editTastingProfile")}}</v-tab>
                    <v-tab href="#tab-3">{{$t("aveine-space.profile.followings")}}</v-tab>
                    <v-tab href="#tab-4">{{$t("aveine-space.profile.followers")}}</v-tab>
                  </v-tabs>
                  <div class="profile-form-container">
                    <v-tabs-items v-model="currentUserTab">
                      <v-tab-item value="tab-1" class="px-16">
                        <v-form v-model="profileFormIsValid">
                          <v-text-field
                            class="justify-center ma-3"
                            :rules="[v => !!v || $t('aveine-space.error.inputRequired')]"
                            v-model="$auth.userProfile.name"
                            :label="$t('aveine-space.profile.name')"
                          ></v-text-field>

                          <v-text-field
                            class="justify-center ma-3"
                            v-model="$auth.userProfile.description"
                            :label="$t('aveine-space.profile.description')"
                          ></v-text-field>

                          <v-select
                            class="justify-center ma-3"
                            v-model="$auth.userProfile.language"
                            :items="[{text:$t(`aveine-space.profile.language.fr`), value:'fr'},
                                    {text:$t(`aveine-space.profile.language.en`), value:'en'}]"
                          ></v-select>

                          <v-divider class="my-9"></v-divider>

                          <v-col class="ma-xl-6 ma-lg-5 mb-6">
                            <v-row class="d-flex align-center justify-center image-container">
                              <v-col class="upload-container">
                                <div class="d-flex justify-center image-upload mb-4">
                                  <div class="upload-btn-wrapper">
                                    <button class="btn">{{$t("aveine-space.profile.button.uploadFile")}}</button>
                                    <input
                                      type="file"
                                      accept="image/png,image/jpeg"
                                      @change="loadProfileImageFile"
                                    >
                                  </div>
                                </div>
                                <v-row v-if="$auth.userProfile.avatar" class="d-flex justify-center my-4">
                                  <v-btn @click="deleteProfileImage()">
                                    {{$t("aveine-space.profile.button.deleteImage")}}
                                  </v-btn>
                                </v-row>
                              </v-col>
                              <v-row v-if="$auth.userProfile.avatar" class="flex-grow-0 flex-shrink-1 image-container">
                                <img class="avatar-image-preview" alt="" :src="$auth.userProfile.displayed_image ? $auth.userProfile.displayed_image : $auth.userProfile.avatar">
                              </v-row>
                            </v-row>
                          </v-col>
                          <div class="d-flex justify-center my-6">
                            <v-btn
                              :disabled="!profileFormIsValid"
                              color="primary"
                              class="ma-xl-2 ma-lg-2 mb-2"
                              @click="submitUpdateProfile(dialog)"
                            >{{ $t("aveine-space.profile.button.submit") }}</v-btn>
                          </div>
                        </v-form>
                      </v-tab-item>
                      <v-tab-item value="tab-2">
                        <AveineTastingProfileForm :APIbaseURL="APIbaseURL" :key="$auth.userProfile.tasting_profile ? $auth.userProfile.tasting_profile.updated_at : null" :tastingProfile="$auth.userProfile.tasting_profile" :callback="function(tastingProfile) {dialog.value = false; currentUserTab = 1; $auth.userProfile.tasting_profile = tastingProfile}" />
                      </v-tab-item>
                      <v-tab-item value="tab-3">
                        <v-col class="pa-0">
                          <AveineUserList :APIbaseURL="APIbaseURL" :callback="function () { dialog.value = false }" :users="$auth.userProfile.follows"/>
                        </v-col>
                        <div class="text-center mb-10">
                          <v-pagination
                            v-model="currentFollowsPage"
                            :length="followsPaginationLength"
                            :total-visible="followsPaginationLength < 7 ? followsPaginationLength : 7"
                          ></v-pagination>
                        </div>
                      </v-tab-item>
                      <v-tab-item value="tab-4">
                        <v-col class="pa-0">
                          <AveineUserList :APIbaseURL="APIbaseURL" :callback="function () { dialog.value = false }" :users="$auth.userProfile.followers"/>
                        </v-col>
                        <div class="text-center mb-10">
                          <v-pagination
                            v-model="currentFollowersPage"
                            :length="followersPaginationLength"
                            :total-visible="followersPaginationLength < 7 ? followersPaginationLength : 7"
                          ></v-pagination>
                        </div>
                      </v-tab-item>
                    </v-tabs-items>
                  </div>
                </v-card-text>
              </v-card>
            </template>
          </v-dialog>
        </v-row>
        <AveineLogin/>
      </div>
    </v-app-bar>
    <v-expand-transition class="d-flex justify-end align-end mt-16"  v-if="$auth.isAuthenticated">
      <v-toolbar class="pr-0" v-show="expandSearch" elevation="10" flat height="200" style="position: fixed; right: 0.5px; top: 80px; z-index: 5;" >
        <div class="d-flex flex-column justify-center align-center mt-8 mx-xl-6 mx-lg-4 mx-2">
          <div class="mx-6">
            <v-radio-group
              v-model="searchBar"
              row
            >
              <v-radio
                class="mr-xl-3 mr-lg-2 mr-16"
                :label="$t('aveine-space.search.wines')"
                color="primary"
                value="wine"
              ></v-radio>
              <v-radio
                class="mr-xl-3 mr-lg-2 mr-16"
                :label="$t('aveine-space.search.ambassadors')"
                color="secondary"
                value="ambassador"
              ></v-radio>
              <v-radio
                :label="$t('aveine-space.search.domains')"
                color="misc"
                value="domain"
              ></v-radio>
              <v-radio
                :label="$t('aveine-space.search.users')"
                color="black"
                value="user"
              ></v-radio>
            </v-radio-group>
          </div>

          <div class="d-flex flex-xl-row flex-lg-row flex-column align-center justify-end main-search-bar">
            <div cols="8" class="d-flex justify-xl-center justify-lg-center flex-row" v-if="searchBar == 'wine'">
              <v-col cols="8" class=" ml-1 mr-xl-10 mr-lg-8 mt-2">
                <v-autocomplete
                  :items="searchedWines"
                  :search-input.sync="wineSearch"
                  @update:search-input="onWineSearch"
                  @change="updateWine"
                  color="white"
                  hide-no-data
                  hide-selected
                  :item-text="getWineDesignation"
                  :label="$t('aveine-space.search.searchWines')"
                  :placeholder="$t('aveine-space.search.searchWines')"
                  return-object
                ></v-autocomplete>
              </v-col>
              <v-btn cols="2" :disabled="!wineSearch" @click="searchWineIndex" outlined class="mt-4">
                <v-icon size="32">
                  mdi-magnify
                </v-icon>
              </v-btn>
            </div>
            <div cols="8" class="d-flex justify-xl-center justify-lg-center flex-row" v-if="searchBar == 'ambassador'">
              <v-col cols="8" class=" ml-1 mr-xl-10 mr-lg-8 mt-2">
                <v-autocomplete
                  :items="searchedAmbassadors"
                  :search-input.sync="ambassadorSearch"
                  @update:search-input="onAmbassadorSearch"
                  @change="updateAmbassador"
                  color="white"
                  hide-no-data
                  hide-selected
                  item-text="name"
                  :label="$t('aveine-space.search.searchAmbassadors')"
                  :placeholder="$t('aveine-space.search.searchAmbassadors')"
                  return-object
                ></v-autocomplete>
              </v-col>
              <v-btn cols="2" :disabled="!ambassadorSearch" @click="searchAmbassadorIndex" outlined class="mt-4">
                <v-icon size="32">
                  mdi-magnify
                </v-icon>
              </v-btn>
            </div>
            <div cols="8" class="d-flex justify-xl-center justify-lg-center flex-row" v-if="searchBar == 'domain'">
              <v-col cols="8" class=" ml-1 mr-xl-10 mr-lg-8 mt-2">
                <v-autocomplete
                  :items="searchedDomains"
                  :search-input.sync="domainSearch"
                  @update:search-input="onDomainSearch"
                  @change="updateDomain"
                  color="white"
                  hide-no-data
                  hide-selected
                  item-text="name"
                  :label="$t('aveine-space.search.searchDomains')"
                  :placeholder="$t('aveine-space.search.searchDomains')"
                  return-object
                ></v-autocomplete>
              </v-col>
              <v-btn cols="2" :disabled="!domainSearch" @click="searchDomainIndex" outlined class="mt-4">
                <v-icon size="32">
                  mdi-magnify
                </v-icon>
              </v-btn>
            </div>
            <div cols="8" class="d-flex justify-xl-center justify-lg-center flex-row" v-if="searchBar == 'user'">
              <v-col cols="8" class=" ml-1 mr-xl-10 mr-lg-8 mt-2">
                <v-autocomplete
                  :items="searchedUsers"
                  :search-input.sync="userSearch"
                  @update:search-input="onUserSearch"
                  @change="updateUser"
                  color="white"
                  hide-no-data
                  hide-selected
                  item-text="profile.name"
                  :label="$t('aveine-space.search.searchUsers')"
                  :placeholder="$t('aveine-space.search.searchUsers')"
                  return-object
                >
                <template v-slot:item="data">
                  <v-avatar class="ma-2 mr-4" left>
                    <v-img :src="data.item.profile.avatar"></v-img>
                  </v-avatar>
                  {{ data.item.profile.name }}
                </template>
              </v-autocomplete>
              </v-col>
              <v-btn cols="2" :disabled="!userSearch" @click="searchUserIndex" outlined class="mt-4">
                <v-icon size="32">
                  mdi-magnify
                </v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </v-toolbar>
    </v-expand-transition>
    <v-main>
      <v-dialog
        v-if="tosToSign && tosToSign.id"
        v-model="tosToSign"
        transition="dialog-bottom-transition"
        max-width="1200"
      >
        <template>
          <v-card>
            <v-toolbar
              class="text-h6 px-6"
              color="primary"
              dark
            >{{ $t("aveine-space.title.newTosAvailable")}}
              <v-spacer>
              </v-spacer>   
              <AveineButtonLanguage/>              
            </v-toolbar>
            <v-card-text class="px-16">
              <div>
                <div class="contract ma-6">
                  <iframe id="contract-iframe" :src="tosToSign.name"></iframe>
                </div>
                <v-checkbox v-model="agreedToTos" :label="$t('aveine-space.title.agreeTos')"/>
              </div>            
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn v-if="agreedToTos" color="primary" @click="signTos(tosToSign)" >
                {{$t("aveine-space.title.submit")}}
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>

        
      <v-navigation-drawer
        v-if="!$auth.loading && $auth.isAuthenticated"
        class="menu-drawer"
        color="primary"
        dark
        v-model="toggleMenu"
        absolute
        temporary
        :elevation="1"
      >
        <div v-if="$auth.ambassador">
          <v-divider></v-divider>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="d-flex mt-3">
                  <v-btn class="d-flex align-center mx-1 mr-4" @click="ambassadorMenu = true" :outlined="ambassadorMenu">Menu Professionel</v-btn>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          <v-divider></v-divider>
        </div>

        <v-list dense>
          <v-list-item v-if="$auth.ambassador && ambassadorMenu" class="nav-links ml-6 mt-4">
            <router-link :to="{ name: 'AmbassadorDashboard'}"><v-list-item-title>{{$t("aveine-space.title.menu.ambassadorDashboard")}}</v-list-item-title></router-link>
          </v-list-item>

          <v-list-item v-if="$auth.ambassador && ambassadorMenu" class="nav-links ml-6 mt-4">
            <router-link :to="{ name: 'UpdateSelfAmbassador', params: { id: $auth.ambassador.id } }"><v-list-item-title>{{$t("aveine-space.title.menu.editMyInformation")}}</v-list-item-title></router-link>
          </v-list-item>

          <v-list-item v-if="$auth.ambassador && ambassadorMenu" class="nav-links ml-6 mt-4">
            <router-link :to="{ name: 'AmbassadorSales', params: { id: $auth.ambassador.id } }"><v-list-item-title>{{$t("aveine-space.title.menu.mySales")}}</v-list-item-title></router-link>
          </v-list-item>
          
          <v-list-item v-if="$auth.ambassador && ambassadorMenu" class="nav-links ml-6 mt-4">
            <router-link :to="{ name: 'Invoices', params: { id: $auth.ambassador.id } }"><v-list-item-title>{{$t("aveine-space.title.menu.invoices")}}</v-list-item-title></router-link>
          </v-list-item>

          <div v-if="$auth.ambassador">
            <v-divider></v-divider>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="d-flex mt-3">
                    <v-btn class="d-flex align-center mx-1 mr-4" @click="ambassadorMenu = false" :outlined="!ambassadorMenu">Menu Particulier</v-btn>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            <v-divider></v-divider>
          </div>


          <v-list-item v-if="!ambassadorMenu" class="nav-links ml-6 mt-4">
            <router-link :to="{ name: 'ConsumerDashboard'}"><v-list-item-title>{{$t("aveine-space.title.menu.home")}}</v-list-item-title></router-link>
          </v-list-item>
          <v-list-item class="nav-links mt-4" v-if="!ambassadorMenu">
            <v-list-item-content>
              <v-expansion-panels>
                <v-expansion-panel flat>
                  <v-expansion-panel-header hide-actions color="primary">
                    {{$t("aveine-space.title.menu.users.title")}}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content color="primary">
                    <v-list dense>
                      <v-list-item>
                        <router-link :to="{ name: 'UserRead', params:{id: 'self'} }"><v-list-item-title>{{$t("aveine-space.title.menu.users.myReviews")}}</v-list-item-title></router-link>
                      </v-list-item>
                      <v-list-item>
                        <router-link :to="{ name: 'BadgeIndex' }"><v-list-item-title>{{$t("aveine-space.title.menu.users.myBadges")}}</v-list-item-title></router-link>
                      </v-list-item>
                    </v-list>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="!ambassadorMenu">
            <v-list-item-content>
              <v-expansion-panels>
                <v-expansion-panel flat>
                  <v-expansion-panel-header hide-actions color="primary">
                    {{$t("aveine-space.title.menu.wines.title")}}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content color="primary">
                    <v-list dense>
                      <v-list-item>
                        <router-link to="/authenticated/consumer/wines/list"><v-list-item-title>{{$t("aveine-space.title.menu.wines.list")}}</v-list-item-title></router-link>
                      </v-list-item>
                      <v-list-item>
                        <router-link to="/authenticated/consumer/wines/favorite"><v-list-item-title>{{$t("aveine-space.title.menu.wines.favourite")}}</v-list-item-title></router-link>
                      </v-list-item>
                      <v-list-item>
                        <router-link to="/authenticated/consumer/wines/history"><v-list-item-title>{{$t("aveine-space.title.menu.wines.history")}}</v-list-item-title></router-link>
                      </v-list-item>
                      <v-list-item>
                        <router-link to="/authenticated/consumer/wines/scan-history"><v-list-item-title>{{$t("aveine-space.title.menu.wines.scanHistory")}}</v-list-item-title></router-link>
                      </v-list-item>
                      <!-- <v-list-item>
                        <router-link to="/authenticated/consumer/wines/forms"><v-list-item-title>{{$t("aveine-space.title.menu.wines.wineForms")}}</v-list-item-title></router-link>
                      </v-list-item> -->
                    </v-list>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="!ambassadorMenu" class="nav-links ml-6 mt-5">
            <router-link :to="{ name: 'AmbassadorIndex'}"><v-list-item-title>{{$t("aveine-space.title.menu.ambassadors")}}</v-list-item-title></router-link>
          </v-list-item>
          <v-list-item v-if="!ambassadorMenu" class="nav-links ml-6 mt-7"> 
            <router-link :to="{ name: 'DomainIndex'}"><v-list-item-title>{{$t("aveine-space.title.menu.domains")}}</v-list-item-title></router-link>
          </v-list-item>
        </v-list>
        <div class="d-flex justify-center contact-us-button">
         <!-- <v-dialog
            transition="dialog-bottom-transition"
            max-width="1200"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="d-flex align-center mx-1 mr-4" v-bind="attrs" v-on="on" color="secondary">{{$t('aveine-space.title.menu.contactUs')}}</v-btn>
            </template>
            <template v-slot:default="dialog">
              <v-card>
                <v-toolbar
                  class="text-h6 px-6"
                  color="primary"
                  dark
                >{{ $t("aveine-space.title.menu.contactUs") }}                      
                  <v-spacer></v-spacer>
                  <v-btn icon @click="dialog.value = false" >
                    <v-icon color="white">mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-card-text class="d-flex justify-center align-center pa-16">
                  <h3 class="text-center">{{$t("aveine-space.title.contactMessage")}} <a href="mailto:ambassadeurs@aveine.paris">ambassadeurs@aveine.paris</a></h3>
                </v-card-text>
              </v-card>
            </template>
          </v-dialog> -->
        </div>
      </v-navigation-drawer>
      <v-container v-if="loadRoute" fluid class="app-container">
        <router-view :key="$route.fullPath"/>
      </v-container>
    </v-main>
    <!-- <v-bottom-sheet
      v-model="priceChangeBanner"
      class="d-flex flex-xl-row flex-lg-row flex-column"
    >
      <v-sheet
        class="text-center d-flex flex-row justify-space-between px-10"
      >
        <div class="d-flex justify-center">
          <v-img
            alt="Aveine"
            class="shrink mt-1"
            contain
            src="@/assets/logo.png"
            width="75"
          />
        </div>

        <span v-if="$i18n.locale == 'fr'" class="text-center font-weight-bold my-8 mx-2">
          Chers Ambassadeurs, <br>
          Comme vous l'avez certainement déjà lu dans notre newsletter ou sur nos réseaux sociaux, nos aérateurs feront l’objet d’un ajustement de prix. <br>
          À compter du 1er août 2023, le prix de l’Aveine Essentiel passe à 349 euros et celui de l’Aveine Original à 449 euros. <br>
          Cette décision intervient dans un contexte mondial marqué par une augmentation des coûts de production et des matières premières. <br>
          Cette mesure vise à garantir la continuité de notre offre de produits de haute qualité pour les passionnés de vin. <br>
          En tant qu'ambassadeur, votre commission pour l'Aveine Original est maintenue à 90€, votre commission pour l'Essentiel est désormais de 35€ par aérateur à compter du 1er août. <br>
          <br>
          Nous vous remercions sincèrement pour votre compréhension et votre soutien dans cette démarche.
        </span>
        <span v-else-if="$i18n.locale == 'en'" class="text-center font-weight-bold my-8 mx-2">
          Dear Ambassadors, as you will certainly have already read in our newsletter or on our social networks, our aerators will be undergoing a price adjustment. <br>
          From 1 August 2023, the price of Aveine Essentiel will go up to €349 and that of Aveine Original to €449. This decision comes against a global backdrop of rising production and raw material costs. <br>
          The aim of this measure is to guarantee the continuity of our range of high-quality products for wine lovers. As an ambassador, your commission for the Aveine Original will remain at €90, but we have revised your commission for the Essentiel to €35 per aerator from 1 August. <br>
          <br>
          We sincerely thank you for your understanding and support in this process.
        </span>

        <div class="d-flex align-center mt-8">
          <v-btn
            text
            @click="priceChangeRead"
          >
          OK
          </v-btn>
        </div>
      </v-sheet>
    </v-bottom-sheet> -->
    <v-footer    
      fixed
      dark
      color="primary"
      :elevation="10"
      class="d-flex flex-column justify-center"
    >
      <v-bottom-sheet
        class="d-flex flex-xl-row flex-lg-row flex-column"
        v-model="deviceIsAndroid"
        inset
      >
        <v-sheet
          class="text-center"
          height="230px"
        >
          <div class="d-flex justify-center">
            <v-img
              alt="Aveine"
              class="shrink mt-1"
              contain
              src="@/assets/app_logo.png"
              width="75"
            />
          </div>

          <span class="text-center font-weight-bold my-3 mx-2">
            {{$t("aveine-space.title.downloadApp.downloadAndroid")}}    
          </span>
          <div class="my-2">
            <v-btn
              href="https://play.google.com/store/apps/details?id=paris.aveine.labs.androidapp&hl=fr&gl=US"
              target="_blank"
              outlined
            >
            {{$t("aveine-space.title.downloadApp.download")}}
            </v-btn>
            <v-btn
              text
              @click="deviceIsAndroid = false"
            >
            {{$t("aveine-space.title.downloadApp.close")}}
            </v-btn>
          </div>
        </v-sheet>
      </v-bottom-sheet>
      <v-bottom-sheet
        class="d-flex flex-xl-row flex-lg-row flex-column"
        v-model="deviceIsIos"
        inset
      >
        <v-sheet
          class="text-center"
          height="230px"
        >
          <div class="d-flex justify-center">
            <v-img
              alt="Aveine"
              class="shrink mt-1"
              contain
              src="@/assets/app_logo.png"
              width="75"
            />
          </div>

          <span class="text-center font-weight-bold my-3 mx-2">
            {{$t("aveine-space.title.downloadApp.downloadIos")}}
          </span>

          <div class="my-2">
            <v-btn
              href="https://apps.apple.com/fr/app/aveine/id1332734298"
              target="_blank"
              outlined
            >
            {{$t("aveine-space.title.downloadApp.download")}}
            </v-btn>
            <v-btn
              text
              @click="deviceIsIos = false"
            >
            {{$t("aveine-space.title.downloadApp.close")}}
            </v-btn>
          </div>
        </v-sheet>
      </v-bottom-sheet>
      <cookie-law class="d-flex justify-center align-center"  theme="dark-lime">
        <div slot-scope="props">
          <span class="text-center font-weight-bold">
            {{$t("aveine-space.title.cookieLaw.message")}} <a :href="`https://www.aveine.fr/${$i18n.locale}/pages/politique-de-confidentialit%C3%A9`">{{$t("aveine-space.title.cookieLaw.privacyPolicy")}}</a>
          </span>
          <v-btn class="skew mx-2" outlined @click="props.close"><span>{{$t("aveine-space.title.cookieLaw.refuse")}}</span></v-btn>
          <v-btn class="skew mx-2" color="white" light @click="props.accept"><span>{{$t("aveine-space.title.cookieLaw.accept")}}</span></v-btn>
        </div>

      </cookie-law>
      <v-col
        class="text-center text-caption"
      >
        <b>Version {{require('../package.json').version}} Copyright © {{ new Date().getFullYear() }} Aveine.</b>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import CookieLaw from 'vue-cookie-law'
import { headers } from './store/index'
import { User, UserProfile, Tos, TastingProfile, Ambassador } from 'aveine-toolbox'
import { AveineButtonLanguage, AveineLogin } from 'aveine-toolbox/src/wrapper'
import { errorHandler } from 'aveine-toolbox'
import AveineTastingProfileForm from '@/components/AveineTastingProfileForm.vue'
import AveineUserList from '@/components/AveineUserList.vue'
import algoliasearch from 'algoliasearch/lite';

export default {
  name: 'App',

  components: {
    AveineButtonLanguage,
    AveineLogin,
    AveineTastingProfileForm ,
    AveineUserList,
    CookieLaw
  },
  data: () => ({
    toggleMenu: false,
    ambassadorMenu: false,
    deviceIsAndroid: false,
    deviceIsIos: false,
    priceChangeBanner: false,
    currentUserTab: 0,
    profileFormIsValid: false,
    userProfile: {},
    agreedToTos: false,
    tosToSign: {},
    loadRoute: false,
    expandSearch: false,
    searchBar: "wine",
    currentFollowsPage: 1,
    followsPaginationLength: 0,
    followsLoaded: true,
    currentFollowersPage: 1,
    followersPaginationLength: 0,
    followersLoaded: true,
    /**
     * Store the list of result from the algolia wine search
     * 
     * @type {Array}
     */
    searchedWines: [],
    /**
     * The algolia search queries for wine search
     * 
     * @type {String}
     */
    wineSearch: null,
    /**
     * Store the list of result from the algolia ambassador search
     * 
     * @type {Array}
     */
    searchedAmbassadors: [],
    /**
     * The algolia search queries for wine search
     * 
     * @type {String}
     */
    ambassadorSearch: null,
    /**
     * Store the list of result from the algolia domain search
     * 
     * @type {Array}
     */
    searchedDomains: [],
    /**
     * The algolia search queries for domain search
     * 
     * @type {String}
     */
    domainSearch: null,
    /**
     * Store the list of result from the user search
     * 
     * @type {Array}
     */
    searchedUsers: [],
    /**
     * The search queries for user search
     * 
     * @type {String}
     */
    userSearch: null,
    /**
     * Storing consumer api base url locally to be used in template
     * 
     * @type {String}
     */
    APIbaseURL: process.env.VUE_APP_API_CONSUMER_HOST
  }),
  computed: {
    /**
     * Algolia Search configuration
     * 
     * @return {Object}
     */
    algoliaClient() {
      return algoliasearch(process.env.VUE_APP_ALGOLIA_APP_ID, process.env.VUE_APP_ALGOLIA_API_KEY);
    },

    /**
     * Configuring Algolia Search for ambassador search
     * 
     * @return {Object}
     */
    algoliaIndexAmbassadors () {
      return this.algoliaClient.initIndex(`${process.env.VUE_APP_ALGOLIA_ENV}_ambassadors`);
    },

    /**
     * Configuring Algolia Search for domain search
     * 
     * @return {Object}
     */
    algoliaIndexDomains () {
      return this.algoliaClient.initIndex(`${process.env.VUE_APP_ALGOLIA_ENV}_domains`);
    },


    /**
     * Configuring Algolia Search for wine search
     * 
     * @return {Object}
     */
    algoliaIndexWines () {
      return this.algoliaClient.initIndex(`${process.env.VUE_APP_ALGOLIA_ENV}_wines`);
    },
  },
  watch: {
    /**
     * Watch the app language to reload tos in correct language
     */
    '$i18n.locale': async function() {
      if (this.tosToSign && this.tosToSign.id) {
        if(this.$auth.ambassador &&this.$auth.ambassador.id) {
          const tosRequest = await Tos.api().get(`tos/ambassador/latest?lang=${this.$i18n.locale}`,{baseURL: process.env.VUE_APP_API_PRO_HOST});
          this.tosToSign = tosRequest.response.data
        }
      }
    },
    /**
     * Callback on page change in follows list on profile dialog
     * 
     */
    async currentFollowsPage (val) {
      this.followsLoaded = false;
      this.$auth.userProfile.follows = [];

      const reqUserFollow = await User.api().get(`/users/self/follows?lang=${this.$i18n.locale}`, {
        baseURL: process.env.VUE_APP_API_CONSUMER_HOST,
        params: {
          "page[number]": val,
          "page[size]": 20
        }
      })
      reqUserFollow.response.data.map(async (follow) => {
        this.$auth.userProfile.follows.push(await User.query().whereId(follow.id).withAllRecursive().first())
      });
      this.followsLoaded = true;
    },
    /**
     * Callback on page change in followers list on profile dialog
     * 
     */
     async currentFollowersPage (val) {
      this.followersLoaded = false;
      this.$auth.userProfile.follows = [];
      
      const reqUserFollower = await User.api().get(`/users/self/followers?lang=${this.$i18n.locale}`, {
        baseURL: process.env.VUE_APP_API_CONSUMER_HOST,
        params: {
          "page[number]": val,
          "page[size]": 20
        }
      })
      reqUserFollower.response.data.map(async (followed) => {
        this.$auth.userProfile.followers.push(await User.query().whereId(followed.id).withAllRecursive().first())
      });

      this.followersLoaded = true;
    },
  },

  async created() {
    if (navigator && navigator.language && navigator.language.startsWith("fr")) {
      this.$i18n.locale = "fr"
    } else {
      this.$i18n.locale = "en"
    }

    this.$auth.$watch('loading', async (loading) => {
      if (loading === false) {
        if (this.$auth.isAuthenticated) {
          try {
            if (localStorage.routeToRedirectTo) {
              this.$router.push(localStorage.routeToRedirectTo)
              localStorage.removeItem("routeToRedirectTo");
            }

            const token = await this.$auth.getTokenSilently();
            headers["Authorization"] = `Bearer ` + token;
            headers["Content-Type"] = 'application/vnd.api+json'

            let reqUser = await User.api().get(`users/self?lang=${this.$i18n.locale}`, {
              baseURL: process.env.VUE_APP_API_CONSUMER_HOST,
            })

            await UserProfile.api().get(`users/self/profile?lang=${this.$i18n.locale}`, {
              params: {
                include: "badge",
              },
              baseURL: process.env.VUE_APP_API_CONSUMER_HOST,
            })

            if (reqUser.entities.ambassadors && reqUser.entities.ambassadors[0] && reqUser.entities.ambassadors[0].id) {
              await Ambassador.api().get(`ambassadors/${reqUser.entities.ambassadors[0].id}?lang=${this.$i18n.locale}`, {
                  params: {
                    include:
                    'address.country,address.spatial-information'
                  },
                  baseURL: process.env.VUE_APP_API_PRO_HOST
                }
              );
            }

            const user = await User.query().whereId(reqUser.entities.users[0].$id).withAllRecursive().first()

            if (!user.profile.tasting_profile || (user.profile.tasting_profile && !user.profile.tasting_profile.id))
            
            user.profile.tasting_profile = await TastingProfile.new();

            this.$auth.userProfile = user.profile;

            this.$auth.userProfile.followers = []
            this.$auth.userProfile.follows = []

            const reqUserFollower = await User.api().get(`/users/self/followers?lang=${this.$i18n.locale}`, {
              baseURL: process.env.VUE_APP_API_CONSUMER_HOST,
              params: {
                "page[size]": 20
              }
            })

            const reqUserFollow = await User.api().get(`/users/self/follows?lang=${this.$i18n.locale}`, {
              baseURL: process.env.VUE_APP_API_CONSUMER_HOST,
              params: {
                "page[size]": 20
              }
            })

            reqUserFollower.response.data.map(async (followed) => {
              this.$auth.userProfile.followers.push(await User.query().whereId(followed.id).withAllRecursive().first())
            });

            reqUserFollow.response.data.map(async (follow) => {
              this.$auth.userProfile.follows.push(await User.query().whereId(follow.id).withAllRecursive().first())
            });
            
            this.followsPaginationLength = reqUserFollow.response.data.meta.last
            this.followersPaginationLength = reqUserFollower.response.data.meta.last

            if (this.$auth.userProfile.language)
              this.$i18n.locale = "fr"

            if(user.ambassador && user.ambassador.id) {
              this.$auth.userProfile.ambassadorId = user.ambassador.id 
              this.$auth.ambassador = user.ambassador
              this.ambassadorMenu = true;

              const tosRequest = await Tos.api().get(`tos/ambassador/latest?lang=${this.$i18n.locale}`, {baseURL: process.env.VUE_APP_API_PRO_HOST});
              if (!tosRequest.response.data.meta["has-signed-latest-tos"])
                this.tosToSign = tosRequest.response.data

              // if (!localStorage["aveine-price-change-read"]) {
              //   this.priceChangeBanner = true
              // }
            }
              const userAgent = navigator.userAgent || navigator.vendor || window.opera;

              if (/android/i.test(userAgent)) {
                this.deviceIsAndroid = true
              }

              if (/iPad|iPhone/.test(userAgent) && !window.MSStream) {
                this.deviceIsIos = true
              }

            this.$auth.roleLoaded = true;
            this.loadRoute = true
          } catch (errors) {
            errorHandler(errors, this)
          }
        } else {
          this.$auth.roleLoaded = true;
          this.loadRoute = true
        }
      }
    });
  },
  methods: {
    priceChangeRead () {
      this.priceChangeBanner = false
      localStorage.setItem("aveine-price-change-read", true)
    },
    /**
     * Trigger an Algolia search for wines whenever search input has been change
     * 
     */
    async onWineSearch (val) {
      let result = await this.algoliaIndexWines.search(val);
      this.searchedWines = result.hits;
    },
    /**
     * Compute designation from algolia wine search results
     */
    getWineDesignation (item) {
      let designation
      if (item.cuvee) {
        designation = item.cuvee;
      } else if (item.appellation) {
        designation = item.appellation.name;
      } else if (item.domain) {
        designation = item.domain.name + " - " + item.color[this.$i18n.locale];
      } else {
        designation = item.color[this.$i18n.locale];
      }
      return designation
    },
    /**
     * Triggered on wines selection from algolia results
     * 
     * @param event autocompelete input state
     */
    async updateWine(event) {
      this.$router.push({name: "WineRead", params: {id: event.id}});
      this.expandSearch = false;
    },
    /**
     * Go to full wine search index with the already entered search term
     * 
     */
    searchWineIndex() {
      this.$router.push({name: "WineIndex", query: {query: this.wineSearch}});
      this.expandSearch = false;
    },
    /**
     * Trigger an Algolia search for ambassadors whenever search input has been change
     * 
     */
    async onAmbassadorSearch (val) {
      let result = await this.algoliaIndexAmbassadors.search(val);
      this.searchedAmbassadors = result.hits;
    },
    /**
     * Triggered on ambassador selection from algolia results
     * 
     * @param event autocompelete input state
     */
    async updateAmbassador(event) {
      this.$router.push({name: "AmbassadorRead", params: {id: event.id}});
      this.expandSearch = false;
    },
    /**
     * Go to full ambassador search index with the already entered search term
     * 
     */
    searchAmbassadorIndex() {
      this.$router.push({name: "AmbassadorIndex", query: {query: this.ambassadorSearch}});
      this.expandSearch = false;
    },
    /**
     * Trigger an Algolia search for domains whenever search input has been change
     * 
     */
    async onDomainSearch (val) {
      let result = await this.algoliaIndexDomains.search(val);
      this.searchedDomains = result.hits;
    },
    /**
     * Triggered on domain selection from algolia results
     * 
     * @param event autocompelete input state
     */
    async updateDomain(event) {
      this.$router.push({name: "DomainRead", params: {id: event.id}});
      this.expandSearch = false;
    },
    /**
     * Go to full domain search index with the already entered search term
     * 
     */
    searchDomainIndex() {
      this.$router.push({name: "DomainIndex", query: {query: this.domainSearch}});
      this.expandSearch = false;
    },
    /**
     * Trigger an Algolia search for domains whenever search input has been change
     * 
     */
    async onUserSearch () {
      let result = await User.api().get(`users?lang=${this.$i18n.locale}&query=${this.userSearch}`, {baseURL: process.env.VUE_APP_API_CONSUMER_HOST});
      this.searchedUsers = result.response.data;
    },
    /**
     * Triggered on domain selection from algolia results
     * 
     * @param event autocompelete input state
     */
    async updateUser(event) {
      this.$router.push({name: "UserRead", params: {id: event.id}});
      this.expandSearch = false;
    },
    /**
     * Go to full domain search index with the already entered search term
     * 
     */
    searchUserIndex() {
      this.$router.push({name: "UserIndex", query: {query: this.userSearch}});
      this.expandSearch = false;
    },
    /**
     * Submit profile edit form
     */
    async submitUpdateProfile (dialog) {
      try {
        let formData = {
          document: this.$auth.userProfile        
        }
        
        if (this.$auth.userProfile.displayed_image) {
          formData.attachments = {attachment_avatar: this.$auth.userProfile.avatar} // Check if a new image has been uploaded so not to send url as image
          this.$auth.userProfile.avatar = null
        }

        await UserProfile.api().patch(`/users/self/profile/multipart`, formData, {baseURL: process.env.VUE_APP_API_CONSUMER_HOST});
        this.$toastr.s(this.$t("aveine-space.title.profileSaved"));
        dialog.value = false
      } catch (errors) {
        errorHandler(errors, this)
      }
    },

    /**
     * Load image file and push it into the user profile ressource
     * 
     * @param blob File blob
     */
    loadProfileImageFile (blob) {
      var file = blob.target.files[0]
      let reader = new FileReader();

      reader.onload = () => {
        const image = new Image();
        image.src = reader.result;
        image.onload = () => {
          this.$auth.userProfile = {
            ...this.$auth.userProfile,
            displayed_image: reader.result
          }
        };
      };

      reader.readAsDataURL(file);
      this.$auth.userProfile.avatar = file;
    },
    /**
     * Delete the image
     * Will empty the preview
     *
     * @return {void}
     */
    deleteProfileImage() {
      this.$auth.userProfile.avatar = undefined
      this.$auth.userProfile.displayed_image = undefined
    },
    /**
     * Sign the displayed tos
     */
    async signTos(tos) {
      try {
        await Tos.api().post(`tos/${tos.id}/sign?lang=${this.$i18n.locale}`, null, {baseURL: process.env.VUE_APP_API_PRO_HOST});
        this.tosToSign = null;
      } catch (errors) {
        errorHandler(errors, this)
      }
    }
  },
};
</script>

<style lang="scss">
  
  @import "aveine-toolbox/src/assets/style/aveine-style";
  @import "aveine-toolbox/src/assets/style/aveine-font";
  
  html,body{
    overflow-x: hidden;
  }
  
  div.app-container {
    padding: 0 !important;
    white-space: pre-line;
    overflow: hidden;
  }

  div.language-button-wrapper {
    width: 45px
  }

  div.menu-button {
    background-color: var(--v-primary-base);
  }

  .main-search-bar input {
    font-size: 18px;
    padding: 10px 10px 10px 10px;
    background-color: transparent;
    display: block;
    width: 30vw;
  }

  @media all and (max-width: 720px) {
    .main-search-bar input {
      width: 85vw;
    }
  }

  div.v-toolbar__content {
    padding-left: 0 !important;
    div.toolbar-left {
      height: inherit;
      div.menu-button {
        height: inherit;
      }
    }
  }

  div.profile-form-container {
    padding: 0 !important;
    overflow-y: scroll;
    height: 100%;
    max-height: 70vh !important;
    //Scrollbar style for Firefox
    scrollbar-color: var(--v-primary-base) var(--v-foreground);
    scrollbar-width: thin;
  }
  //Scrollbar style for webkit browsers
  div.profile-form-container::-webkit-scrollbar {
    width: 8px;
  }
  div.profile-form-container::-webkit-scrollbar-track {
    margin: 20px;
    background: var(--v-foreground);
    border-radius: 10px;
  }
  div.profile-form-container::-webkit-scrollbar-thumb {
    background: var(--v-primary-base);
    border-radius: 10px;
  } 
  
  .v-slide-group__prev {
    display: none !important;
  }

  div.image-upload {
    p {
      margin-top: 5px;
    }

    div.upload-btn-wrapper {
      position: relative;
      overflow: hidden;
      display: inline-block;
    }

    button.btn {
      background: #FFFFFF;
      border: 1px solid #a3a3a3;
      border-radius: 5px;
      padding: 5px 25px 5px 25px;
    }

    div.upload-btn-wrapper input[type=file] {
      font-size: 100px;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
    }
  }

  aside.menu-drawer {
    position: fixed;
    top: 65px !important;
    border-radius: 0px 50px 0px 0px;
    div.v-list--dense {
      margin-bottom: 8vh;
    }
    div.nav-links {
      a {
        div.v-list-item__title {
          font-size: 0.9375rem !important;
          line-height: 1;
        }
      }
      margin-bottom: 10px;
      min-height: 40px;
    }
    div.v-list-item__title {
      color: white !important;
      text-decoration: none;
    }
  }

  .avatar-image-preview {
    width: 20vw;
  }
  div.contact-us-button {
    position: absolute;
    bottom: 100px;
    left: 25px;
  }
  
  div.contract #contract-iframe {
    width: 100%;
    height: 500px;
  }
</style>
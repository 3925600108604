import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { addressTranslation } from 'aveine-address';
import { ambassadorTranslation } from 'aveine-ambassador';
import { appellationTranslation } from 'aveine-appellation';
import { toolboxTranslation } from 'aveine-toolbox';
import { companyTranslation } from 'aveine-company';
import { domainTranslation } from 'aveine-domain';
import { grapeTranslation } from 'aveine-grape'
import { invitationTranslation } from 'aveine-invitation';
import { invoiceTranslation } from 'aveine-invoice';
import { saleTranslation } from 'aveine-sale';
import { superAmbassadorTranslation } from 'aveine-super-ambassador';
import { regionTranslation } from 'aveine-region';
import { userTranslation } from 'aveine-user';
import { wineTranslation } from 'aveine-wine';
import { wineFormTranslation } from 'aveine-wine';
import aveineSpaceTranslation from '../translations/aveine-space';

Vue.use(VueI18n);

const messages = {
  'en': { 
    "aveine-address": addressTranslation.en, 
    "aveine-ambassador": ambassadorTranslation.en,
    "aveine-appellation": appellationTranslation.en,
    "aveine-company": companyTranslation.en, 
    "aveine-domain": domainTranslation.en, 
    "aveine-grape": grapeTranslation.en, 
    "aveine-invitation": invitationTranslation.en, 
    "aveine-invoice": invoiceTranslation.en,
    "aveine-sale": saleTranslation.en, 
    "aveine-super-ambassador": superAmbassadorTranslation.en, 
    "aveine-toolbox": toolboxTranslation.en,
    "aveine-region": regionTranslation.en,
    "aveine-user": userTranslation.en,
    "aveine-wine": wineTranslation.en, 
    "aveine-wine-form": wineFormTranslation.en,
    "aveine-space": aveineSpaceTranslation.en
},
  'fr': {
    "aveine-address": addressTranslation.fr, 
    "aveine-ambassador": ambassadorTranslation.fr,
    "aveine-appellation": appellationTranslation.fr,
    "aveine-company": companyTranslation.fr, 
    "aveine-domain": domainTranslation.fr, 
    "aveine-grape": grapeTranslation.fr, 
    "aveine-invitation": invitationTranslation.fr, 
    "aveine-invoice": invoiceTranslation.fr,
    "aveine-sale": saleTranslation.fr, 
    "aveine-super-ambassador": superAmbassadorTranslation.fr, 
    "aveine-toolbox": toolboxTranslation.fr,
    "aveine-region": regionTranslation.fr,
    "aveine-user": userTranslation.fr,
    "aveine-wine": wineTranslation.fr, 
    "aveine-wine-form": wineFormTranslation.fr,
    "aveine-space": aveineSpaceTranslation.fr
},
}
const i18n = new VueI18n({ 
    locale: navigator.language.startsWith("fr") ? "fr" : "en",      
    messages
});

export default i18n
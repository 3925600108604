<template>
  <div class="dashboard py-5">
    <span class="d-flex flex-row page-title">{{$t("aveine-space.title.page.home")}}</span>
    <v-banner
        :dark="(invoiceSummary['amount-unlocked-euro'] || invoiceSummary['amount-unlocked-dollar']) ? true : false"
        :color="(invoiceSummary['amount-unlocked-euro'] || invoiceSummary['amount-unlocked-dollar']) ? 'secondary' : 'white'"
        v-if="invoiceSummary['amount-unlocked-euro'] || invoiceSummary['amount-unlocked-dollar'] || invoiceSummary['amount-locked-euro'] || invoiceSummary['amount-locked-dollar']"
        class="d-flex flex-xl-row flex-lg-row flex-column justify-center px-xl-16 px-lg-16 mb-8"
      >
        <v-icon
          slot="icon"
          size="36"
        >
          mdi-file-document-edit
        </v-icon>
          <p v-if="invoiceSummary['amount-unlocked-euro'] || invoiceSummary['amount-unlocked-dollar']">{{$t("aveine-space.sales.unlockedMoney")}} {{invoiceSummary["amount-unlocked-euro"] ? invoiceSummary["amount-unlocked-euro"] + " €" : invoiceSummary["amount-unlocked-dollar"] + " $"}}</p>
          <p v-if="invoiceSummary['amount-locked-euro'] || invoiceSummary['amount-locked-dollar']">{{$t("aveine-space.sales.lockedMoney")}} {{invoiceSummary["amount-locked-euro"] ? invoiceSummary["amount-locked-euro"] + " €" : invoiceSummary["amount-locked-dollar"] + " $"}} ({{$t("aveine-space.sales.unlockedAt")}} {{new Date(invoiceSummary["will-unlock-at"]).toLocaleDateString("fr-FR")}})</p> 
        <template v-slot:actions>
          <v-btn
            v-if="invoiceSummary['amount-unlocked-euro'] || invoiceSummary['amount-unlocked-dollar']"
            class="mb-2 mr-4"
            outlined 
            :to="{ name:'InvoiceCreate', params: {id: $auth.ambassador.id} }"
          >
            {{$t("aveine-space.title.declare")}}
          </v-btn>
        </template>
      </v-banner>

      <div class="header">
        <v-col class="discount-code d-flex flex-column align-center mt-4">
          <p class="ma-1">{{$t("aveine-space.sales.discountCode")}}</p> <h2 class="ma-1">{{$auth.ambassador.promotion_code}}</h2>
        </v-col>
        <div class="d-flex justify-center">
          <v-card class="pa-6" v-if="$auth.ambassador" outlined>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title class="text-h6 mb-1">
                  {{$auth.ambassador.name}} 
                </v-list-item-title>
                <div v-if="this.$auth.ambassador.address" class="text-overline mb-4">
                  {{$auth.ambassador.address.country.name[$i18n.locale]}}
                </div>

              </v-list-item-content>

              <v-list-item-avatar
                tile
                size="80"
              >
              <v-img v-if="$auth.ambassador.image" :src="this.$auth.ambassador.image"></v-img>
              <v-img v-else src="@/assets/placeholder-image.png"></v-img>
              </v-list-item-avatar>
            </v-list-item>
          </v-card>
        </div>
      </div>
      <div class="d-flex flex-xl-column flex-lg-column flex-column-reverse my-10 mx-xl-6 mx-lg-5 mx-3">
        <v-col class="component my-5">
          <AveineSaleDetails class="component" :APIbaseURL="APIbaseURL" :selectedCurrency="selectedCurrency" :monthSalesNumber="monthSalesNumber" :yearSalesNumber="yearSalesNumber" :totalSalesNumber="totalSalesNumber" :totalIncome="totalIncome" :pendingIncome="pendingIncome" :adminMode="true"/>
        </v-col>
      </div>
  </div>
</template>

<script>
import { AveineSaleDetails } from 'aveine-sale'
import { errorHandler } from 'aveine-toolbox'
export default {
  name: 'AmbassadorDashboard',
  data () {
    return {
      orders: [],
			/**
			 * Represent the different currencies
			 *
			 * @type {Object[]}
			 */
			currencies: [
				{
					name: 'Euros',
					code: 'EUR',
				},
				{
					name: 'Dollars',
					code: 'USD',
				}
      ],
      /**
       * The selected currency
       * 
       * @type {String}
       */
      selectedCurrency: null,
      /**
       * Flag to know if page data has been loaded
       * 
       * @type {boolean}
       */
      dataLoaded: false,
      /**
       * Store monthly sale number by product
       * 
       * @type {Object}
       */
      monthSalesNumber: Object,
      /**
       * Store yearly sale number by product
       * 
       * @type {Object}
       */
      yearSalesNumber: Object,
      /**
       * Store total sale number by product
       * 
       * @type {Object}
       */
      totalSalesNumber: Object,
      /**
       * Store the total income the current user has grossed
       * 
       * @type {Object}
       */
      totalIncome: Object,
      /**
       * Store the invoicing information of the current user
       * 
       * @type {Object}
       */
      invoiceSummary: Object,
      APIbaseURL: process.env.VUE_APP_API_PRO_HOST
    }
  },
  components: {
    AveineSaleDetails
  },
  async created() {
    try {
      this.authToken = await this.$auth.getTokenSilently();

      this.selectedCurrency = this.currencies[0].code;
      
      let begginingMonthDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().split('.')[0]+"Z";
      let endMonthDate =  new Date(new Date().getFullYear(), new Date().getMonth()+1, 1).toISOString().split('.')[0]+"Z";

      let fetchMonthSummary = await fetch(`${this.APIbaseURL}/orders/ambassadors/${this.$auth.ambassador.id}/short-summary?filter=created-at=ge=${begginingMonthDate};created-at=le=${endMonthDate}`, {
        method: "GET",
        headers: {
          "Authorization": `Bearer ` + this.authToken
        }
      })

      let monthSummary = await fetchMonthSummary.json();
      this.monthSalesNumber = {aveineAmount: monthSummary.meta["aveine-amount"], essentielAmount: monthSummary.meta["estl-amount"]}
      
      let begginingYearDate = new Date(new Date().getFullYear(), 1, 1).toISOString().split('.')[0]+"Z";
      let endYearDate =  new Date(new Date().getFullYear(), 12, 31).toISOString().split('.')[0]+"Z";

      let fetchYearSummary = await fetch(`${this.APIbaseURL}/orders/ambassadors/${this.$auth.ambassador.id}/short-summary?filter=created-at=ge=${begginingYearDate};created-at=le=${endYearDate}`, {
        method: "GET",
        headers: {
          "Authorization": `Bearer ` + this.authToken
        }
      })

      let yearSummary = await fetchYearSummary.json();
      this.yearSalesNumber = {aveineAmount: yearSummary.meta["aveine-amount"], essentielAmount: yearSummary.meta["estl-amount"]}

      let fetchTotalSummary = await fetch(`${this.APIbaseURL}/orders/ambassadors/${this.$auth.ambassador.id}/summary`, {
        method: "GET",
        headers: {
          "Authorization": `Bearer ` + this.authToken
        }
      })

      const invoiceSummaryJson = await fetchTotalSummary.json();
      this.invoiceSummary = invoiceSummaryJson.meta;

      this.totalSalesNumber = {aveineAmount: this.invoiceSummary["total-aveine-amount"], essentielAmount: this.invoiceSummary["total-estl-amount"]}
      this.totalIncome = { 
        USD: parseInt(this.invoiceSummary["amount-paid-dollar"]),
        EUR: parseInt(this.invoiceSummary["amount-paid-euro"])
      }
      this.pendingIncome = {
        USD: parseInt(this.invoiceSummary["amount-locked-dollar"]) + parseInt(this.invoiceSummary["amount-unlocked-dollar"]) + parseInt(this.invoiceSummary["amount-pending-dollar"]),
        EUR: parseInt(this.invoiceSummary["amount-locked-euro"]) + parseInt(this.invoiceSummary["amount-unlocked-euro"]) + parseInt(this.invoiceSummary["amount-pending-euro"]),
      }

      this.dataLoaded = true;
    } catch (errors) {
      this.dataLoaded = true;
      errorHandler(errors, this)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  div.wrapper {
    h1 {
      color: #1A2A4B;
      font-size: 1.6em;
    }
    div.header {
      width: 20vw;
      margin: auto;

      div.discount-code,
      div.currency {
        text-align: center;
      }

      div.discount-code {
        margin-bottom: 2vh;
        span {
          color: #E4012D;
          font-size: 2em;
        }
        @media only screen and (max-width: 600px) {
          span {
            margin-right: 35px;
          }
        }
      }
    }


    div.component {
      h2 {
        font-size: 1em;
      }
    }
  }
</style>

<template>
  <div class="index py-5">
    <div class="searchable-list-container">
      <AveineDomainList :APIbaseURL="APIbaseURL" />
    </div>
  </div>
</template>

<script>
import { AveineDomainList } from 'aveine-domain'
export default {
  name: 'Index',
  components: {
    AveineDomainList
  },
  data () {
    return {
      APIbaseURL: process.env.VUE_APP_API_CONSUMER_HOST
    }
  }
}
</script>

export default {
  "en": {
    "homepage": {
      "tagline": "Manage your favorite wines\nwith ease!",
      "taglineSubtitle":"By the creators of the Aveine Wine Aerator, discover the Aveine Space.\nKeep track of your wine tasting and \ndiscover your next favorite bottle!",
      "discover": "I Discover",
      "login": "I Login",
      "advantagesTitle": "The Wine Social Network",
      "manageWinesTitle": "Manage your Wines",
      "manageWinesText": "Keep track of your favorite wines, the last wines you tasted and learn more information about them.",
      "recommendationTitle": "Receive personalized\nrecommendations",
      "recommendationText": "Fill in a tasting profile with the types of wines you enjoy the most and we'll recommend you new bottles to taste everyday.",
      "friendsTitle": "See your friends\ntastings",
      "friendsText": "Get in touch with your friends, see what they are drinking and what they recommend.",
      "rewardsTitle": "Get rewarded using\nthe App",
      "rewardsText": "Get special badges on your profile by scanning wines, writing reviews and using the app in general.",
      "ambassadorTitle": "Are you an\nAveine Ambassador?",
      "ambassadorText": "Manage your business, keep track of the sales your make, issue invoices and claim your commissions!\nDiscover all of these steps on the professional space of the Aveine Space.",
      "curiousAboutAmbassadorProgram": "Curious about the Ambassador Program?",
      "learnMore": "Learn more",
      "aboutAveineTitle": "About Aveine",
      "aboutAveineText": "Aveine is the first smart wine aerator, it allows you to aerate your wine instantly and\nto perfection, for and optimal wine tasting experience. Already adopted by\nmany profesionals and it seduces countless wine enthusiasts."
    },
    "title": {
      "aveine": "Aveine",
      "myAmbassadorsList": "My ambassadors",
      "contactMessage": "For any question about the Ambassador program or any tecnnical difficulties, please contact us via this address: ",
      "becomeAmbassador": "Become an ambassador",
      "becomeAmbassadorMessage": "Please enter your email address, you'll receive an invitation to register as an ambassador",
      "email": "Email",
      "invitationSent": "Invitation sent",
      "wineFormToolTip": "The submited wines will have to be reviewed before appearing in our database",
      "tosSignature": "Terms of Services has been updated",
      "editProfile": "Edit Profile",
      "declare": "Declare",
      "newTosAvailable": "Terms of Service has been updated",
      "agreeTos": "I accept the terms of service",
      "submit": "Submit",
      "send": "Send",
      "profileSaved": "Profile saved",
      "invitationEmail": "Email to send the invitation to",
      "noScannedWine": "Scan wines with the mobile application and you'll see them appear here.",
      "cookieLaw": {
        "message": "This website uses cookies to ensure you get the best experience on our website.",
        "privacyPolicy": "Privacy Policy",
        "accept": "Accept",
        "refuse": "Decline"
      },
      "downloadApp": {
        "downloadAndroid": "For a better experience on mobile, you can download the official Aveine App on the Play Store!",
        "downloadIos": "For a better experience on mobile, you can download the official Aveine App on the App Store!",
        "download": "Download",
        "close": "Close"
      },
      "menu": {
        "navigation": "Navigation",
        "home": "Home",
        "ambassadorDashboard": "Dashboard",
        "editMyInformation": "Edit my Information",
        "invoices": "My Invoices",
        "mySales": "My Sales",
        "users": {
          "title": "My profile",
          "myReviews": "My reviews",
          "myBadges": "My badges",
        },
        "wines": {
          "title": "Wines",
          "list": "Search",
          "wineForms": "Forms",
          "favourite": "Favourite",
          "history": "Last seen wines",
          "scanHistory": "Last scanned wines"
        },
        "domains": "Domains",
        "ambassadors": "Aveine Ambassadors",
        "contactUs": "Contact Us"
      },
      "page": {
        "home": "Dashboard",
        "editMyInformation": "Edit my Information",
        "myInvoices": "My Invoices",
        "mySales": "My Sales",
        "createInvoice": "Issue an invoice",
        "declaredSales": "Declared sales",
        "userList": "User list",
        "wine": {
          "create": "Add a wine",
          "update": "Update a wine",
          "list": "List of wines",
          "read": "Wine sheet",
          "favorite": "Favorite wines",
          "history": "Last seen wines"
        },
        "wineForm": {
          "list": "List of wine forms"
        },
        "domain": {
          "create": "Add a domain",
          "update": "Update a domain",
          "list": "List of domains",
          "read": "Domain sheet"
        },
        "ambassador": {
          "create": "Add an ambassadeur",
          "update": "Update an ambassadeur",
          "list": "List of ambassadeurs",
          "read": "Ambassadeur sheet",
        },
        "grape": {
          "create": "Add a grape",
          "update": "update a grape",
          "list": "List of grapes",
          "read": "Grape sheet"
        },
        "region": {
          "create": "Add a region",
          "update": "update a region",
          "list": "List of regions",
          "read": "Region sheet"
        },
        "company": {
          "create": "Add a company",
          "update": "Update a company",
          "list": "List of companies",
          "read": "Company sheet"
        },
        "appellation": {
          "create": "Add an appellation",
          "list": "List of appellations",
          "update": "Update an appellation",
          "read": "Appellation Sheet"
        }
      },
    },
    "profile": {
      "profile": "Profile",
      "editProfile": "Edit Profile",
      "editTastingProfile": "Edit Tasting Profile",
      "followings": "Followings",
      "followers": "Followers",
      "name": "User name",
      "description": "Description",
      "language": {
        "en": "English",
        "fr": "Français"
      },
      "button": {
        "deleteImage": "Remove avatar",
        "submit": "Submit",
        "uploadFile": "Upload a file",
        "cancel": "Cancel",
        "showcase": "Showcase"
      }
    },
    "search": {
      "wines": "Wines",
      "ambassadors": "Ambassadors",
      "domains": "Domains",
      "users": "Users",
      "searchWines": "Search for wines",
      "searchAmassadors": "Search for ambassadors",
      "searchDomains": "Search for domains",
      "searchUsers": "Search for users"
    },
    "user": {
      "follow": "Follow",
      "unfollow": "Unfollow",
      "noResultFound": "No result"
    },
    "newsfeed": {
      "scannedWines": "Scanned wines",
      "lastSeenWines": "Last wines seen",
      "myTastingProfile": "My tasting profile",
      "myTastingProfileText": "Fill in your tasting profile to discover new bottles you may like",
      "completeMyProfile": "Complete my profile",
      "myPreferences": "My preferences",
      "reviewsWritten": "Reviews Written",
      "seeAllReviews": "See my reviews",
      "seeAllBadges": "See my badges",
      "favoriteWines": "Favorite wines",
      "badges": "Badge collection",
      "badgesText": "Use the app, follow your friends, write wine reviews and unlock badges to show off on your profile !",
      "newBlogArticle": "New blog article",
      "readMore": "Read more",
      "newAveineAmbassador": "New Aveine Ambassador",
      "learnMore": "Learn more",
      "newUpdate": "New version ",
      "scannedAWine": " scanned a wine",
      "leftAReview": " left a review",
      "suggestionOfTheDay": "Suggestion of the day",
      "followOnInstagram": "Follow us on instagram !",
    },
    "badges": {
      "scan_red_level_1": "Red wine lover",
      "scan_red_level_2": "Red wine lover Bronze",
      "scan_red_level_3": "Red wine lover Silver",
      "scan_red_level_4": "Red wine lover Gold",
      "scan_red_level_1_text": "Scan a red wine",
      "scan_red_level_2_text": "Scan 50 red wines",
      "scan_red_level_3_text": "Scan 100 red wines",
      "scan_red_level_4_text": "Scan 500 red wines",
      "scan_white_level_1": "White wine lover",
      "scan_white_level_2": "White wine lover Bronze",
      "scan_white_level_3": "White wine lover Silver",
      "scan_white_level_4": "White wine lover Gold",
      "scan_white_level_1_text": "Scan a white wine",
      "scan_white_level_2_text": "Scan 50 white wines",
      "scan_white_level_3_text": "Scan 100 white wines",
      "scan_white_level_4_text": "Scan 500 white wines",
      "scan_rose_level_1": "Rose wine lover",
      "scan_rose_level_2": "Rose wine lover Bronze",
      "scan_rose_level_3": "Rose wine lover Silver",
      "scan_rose_level_4": "Rose wine lover Gold",
      "scan_rose_level_1_text": "Scan a rosé wine",
      "scan_rose_level_2_text": "Scan 50 rosé wines",
      "scan_rose_level_3_text": "Scan 100 rosé wines",
      "scan_rose_level_4_text": "Scan 500 rosé wines",
      "blog_level_1": "Punctual reader",
      "blog_level_2": "Occasional reader",
      "blog_level_3": "Assiduous reader",
      "blog_level_4": "Passionate reader",
      "blog_level_1_text": "Read a blog article",
      "blog_level_2_text": "Read 5 blog articles",
      "blog_level_3_text": "Read 10 blog articles",
      "blog_level_4_text": "Read 20 blog articles",
      "wine_form_level_1": "Novice contributor",
      "wine_form_level_2": "Confirmed contributor",
      "wine_form_level_3": "Passionate contributor",
      "wine_form_level_4": "Expert contributor",
      "wine_form_level_1_text": "Have completed a wine form",
      "wine_form_level_2_text": "Have completed 100 wine forms",
      "wine_form_level_3_text": "Have completed 200 wine forms",
      "wine_form_level_4_text": "Have completed 1000 wine forms",
      "tasting_note_level_1": "Beginner critic",
      "tasting_note_level_2": "Confirmed critic",
      "tasting_note_level_3": "Passionate critic",
      "tasting_note_level_4": "Expert critic",
      "tasting_note_level_1_text": "Have written a comment",
      "tasting_note_level_2_text": "Have written 100 comments",
      "tasting_note_level_3_text": "Have written 200 comments",
      "tasting_note_level_4_text": "Have written 500 comments",
      "backer": "Backer",
      "backer_text": "Have contributed to crowdfunding",
      "favorite": "Love at first sight",
      "favorite_text": "Favorite a wine",
      "aerator": "Successful connection",
      "aerator_text": "Connect an aerator",
      "aerator_rename": "My aerator is called…",
      "aerator_rename_text": "Renaming your aerator",
      "search_wine": "Looking for wine",
      "search_wine_text": "Search a wine",
      "tasting_profile": "Ready to taste!",
      "tasting_profile_text": "Complete your tasting profile"
    },
    "dashboard": {
      "submitAmbassadorInvoice": "Submit an Ambassador invoice",
      "submitSuperAmbassadorInvoice": "Submit a Super Ambassador invoice",
      "ambassadorInvoices": "Ambassador invoice in progress",
      "superAmbassadorInvoices": "Super Ambassador invoice in progress",
      "domainsWines": "My wines",
      "ambassadorSales": "My sales",
      "superAmbassadorSales": "My Ambassador's sales",
      "seeMore": "See more"
    },
    "invoices": {
      "ambassadorInvoices": "Ambassador Invoices",
      "superAmbassadorInvoices": "Super Ambassador Invoices",
      "totalDeclared": "Total income declared"
    },
    "sales": {
      "title": "My sales",
      "discountCode": "Discount code",
      "currency": "Currency",
      "unlockedMoney": "Declarable income available",
      "lockedMoney": "Locked income",
      "unlockedAt": "unlocked at"
    },
    "tastingProfile": {
      "profileCompletedAt": "Profile completed at",
      "color": "Color",
      "effervescence": "Effervescence",
      "origin": "Origin",
      "characteristics": "Characteristics",
      "colorText": "Red wine, white wine, rosé...At which frequency do you drink each of them?",
      "red": "Red wines",
      "white": "White wines",
      "rosé": "Rosés",
      "next": "Next",
      "previous": "Back",
      "effervescenceText": "Still or sparkling?",
      "sparkling": "Sparkling wines",
      "still": "Still",
      "regionText": "Favorite regions?",
      "appellationText": "Appellations?",
      "grapeText": "Grapes?",
      "organic": "Organic wines",
      "natural": "Natural wines",
      "vegan": "Vegan wines",
      "submit": "Save my profile",
      "profileSaved": "Profile saved !",
      "attributes": {
        "never": "Never",
        "no": "Rarely",
        "why_not": "Sometimes",
        "yes": "Often",
        "always": "Always"
      }
    },
    "onBoarding": {
      "next": "Next",
      "back": "Back",
      "submit": "Submit",
      "processing": "Processing...",
      "skip": "Skip",
      "introduction": "Introduction",
      "introText": "You have been invited to become an Aveine Ambassador, you will be guided through the process of establishing your new status.",
      "userNotLoggedIn": "If you already have an Aveine account (to connect to the mobile application for example), please log in to associate it with the Ambassador status.\nIf you wish to create a new Aveine account click on sign up",
      "existingAccount": "An Aveine account is necessary to continue the registration process.\nPlease login or create an account by clicking on the button below : ",
      "createProAccount": "Create an account",
      "searchDomain": "Find your domain",
      "selectDomainPrompt": "Please search for your domain first (if you can't find it you can ignore this step to add it)",
      "userDomainField": "Domain",
      "domainValidation": "We couldn't find your domain in our database",
      "domainAlreadyRegistered": "This domain is already registered",
      "tos": "Terms of service",
      "agreeTos": "I agree to the terms of service",
      "accountInfo": "Account information",
      "complementaryInfo": "Additional information",
      "payment": "Payment",
      "checkout": "Order your kit",
      "orderButton": "Order",
      "signIn": "Sign in",
      "logIn": "Log in",
      "storeSelection": "To become an Aveine Ambassador you will need an Ambassador kit containing an aerator, your personalized promo code, as well as various promotional tools.",
      "willShipTo": "the kit will ship to : ",
      "france": "France",
      "europe": "Europe",
      "northAmerica": "North America",
      "international": "Other",
      "alreadyHaveAPurchaseCode": "After the checkout is done you'll receive a purchase code, if you already have it, please fill in the field down below to continue to the next step of the process.",
      "purchaseCode": "Enter a purchase code",
      "purchaseCodeToolTip": "The purchase code can be found on the order confirmation page.",
      "accountCreationInfo": "To finalize the account creation, please enter a valid email address and a password. You will use these informations to log into the Aveine Pro Space :",
      "personalInfoText": "As the last step, fill in your activity information.\nThese information will be public and will appear on Aveine mobile and web applications.\n (*only name, company and address are mandatory)",
      "finalization": "Finalization",
      "signInSuccess": "Welcome to the Aveine Ambassador Program!",
      "proceedWithLogin": "You can now click on the button below to log in",
      "backToIndex": "Go back to the home page",
      "passwordChecking" : {
        "characterCount": "- At least 8 characters in length",
        "upperCase": "- An upper case",
        "lowerCase": "- A lower case",
        "digit": "- A digit",
        "special": "- A special character"
      }
    },
    "errors": {
      "error": "Error",
      "inputRequired": "This field is required",
      "cantRetrieveSelf": "Can't retrieve the related ambassador account",
      "errorPassword": "Passwords don't match",
      "emailValidation": "Invalid email",
      "passwordValidation": "The password doesn't fulfill the conditions",
      "registrationError": "An unexpeted error has occured, please refresh the page.\nIf the problem persists please contact our team",
      "invitationAlreadyConsumed": "The invitation you're trying to open has already been used or has expired",
      "roleAlreadyAssigned": "This user already possess this role",
      "noPurchaseCode": "A purchase code is required to continue",
      "notProError": "Your account doesn't have the permissions to access the Aveine Pro Space functionalities",
      "pageNotFound": "Page not found",
      "unknownError": "Unknown Error"
    }
  },
  "fr": {
    "homepage": {
      "tagline": "Gérez vos vins préférés\nen un seul clic ! ",
      "taglineSubtitle":"Accompagnant l'aérateur Aveine, découvrez l'Espace Aveine.\nSuivez vos dégustations de vins et découvrez\nvos prochaines bouteilles favorites ! ",
      "discover": "Je découvre",
      "login": "Je me connecte",
      "advantagesTitle": "Le réseau social du vin",
      "manageWinesTitle": "Gérez vos\nvins",
      "manageWinesText": "Gardez une trace de vos vins favoris, des dernières bouteilles que vous avez pu tester et apprenez-en plus sur elles.",
      "recommendationTitle": "Recevez des recommandations\npersonalisées",
      "recommendationText": "Remplissez votre profil de dégustation renseignant vos types de vins favoris et nous vous enverrons des recommandations de nouvelles bouteilles à découvrir quotidiennement.",
      "friendsTitle": "Suivez ce que vos\namis dégustent",
      "friendsText": "Prenez des nouvelles de vos amis, voyez quels vins ils dégustent en ce moment et lesquels ils recommandent.",
      "rewardsTitle": "Recevez des récompenses en\nutilisant l'application",
      "rewardsText": "Recevez des badges sur votre profil en scannant des vins, en écrivant des avis et en utilisant l'application quotidiennement.",
      "ambassadorTitle": "Êtes-vous Ambassadeur\nchez nous ?",
      "ambassadorText": "Gérez votre affaire, suivez les ventes que vous produisez, émettez des factures et touchez vos commissions !\nRetrouvez toutes les démarches sur l'espace professionnel de l'Espace Aveine.",
      "curiousAboutAmbassadorProgram":"Vous ne connaissez pas le programme Ambassadeur Aveine ?",
      "learnMore": "En savoir plus",
      "aboutAveineTitle": "A propos d'Aveine",
      "aboutAveineText": "Aveine est le premier aérateur de vin digital. Il vous permet d’aérer instantanément vos vins à la perfection, pour une expérience de dégustation optimale. Déjà adopté par les professionnels, il séduit aussi tous les passionnés de vin exigeants. À vous de le découvrir !"
    },
    "title": {
      "aveine": "Aveine",
      "myAmbassadorsList": "Mes Ambassadeurs",
      "language": "Langue:",
      "contactMessage": "En cas de problèmes techniques ou questions sur le Programme Ambassadeur, merci de nous contacter à l'adresse suivante : ",
      "becomeAmbassador": "Devenir Ambassadeur",
      "becomeAmbassadorMessage": "Veuillez renseigner votre adresse mail, vous recevrez ensuite une invitation pour devenir ambassadeur",
      "email": "Adresse mail",
      "invitationSent": "Invitation envoyée",
      "wineFormToolTip": "Les vins soumis devront être validés avant d'apparaître dans notre base de données",
      "tosSignature": "Mise à jour des conditions générales d'utilisation",
      "editProfile": "Edition du Profil",
      "declare": "Je déclare",
      "newTosAvailable": "Les CGV ont été mis à jour",
      "agreeTos": "J'accepte les conditions générales de vente",
      "submit": "Valider",
      "invitationEmail": "Email qui recevera l'invitation",
      "send": "Envoyer",
      "profileSaved": "Profil sauvegardé",
      "noScannedWine": "Scannez des vins via l'application mobile et retrouvez les ici",
      "cookieLaw": {
        "message": "Ce site web utilise des cookies pour vous garantir la meilleure expérience possible sur notre site.",
        "privacyPolicy": "Politique de confidentialité",
        "accept": "J'accepte",
        "refuse": "Je refuse"
      },
      "downloadApp": {
        "downloadAndroid": "Pour une meilleure expèrience sur mobile, vous pouvez télécharger l'application Aveine sur le Play Store !",
        "downloadIos": "Pour une meilleure expèrience sur mobile, vous pouvez télécharger l'application Aveine sur le l'App Store !",
        "download": "Télécharger",
        "close": "Ignorer"
      },
      "menu": {
        "navigation": "Navigation",
        "home": "Accueil",
        "ambassadorDashboard": "Tableau de bord",
        "editMyInformation": "Editer mes informations",
        "invoices": "Mes Factures",
        "mySales": "Mes ventes",
        "users": {
          "title": "Mon profil",
          "myReviews": "Mes avis",
          "myBadges": "Mes badges",
        },
        "wines": {
          "title": "Vins",
          "list": "Recherche",
          "wineForms": "Formulaires",
          "favourite": "Favoris",
          "history": "Derniers vins consultés",
          "scanHistory": "Derniers vins scannés"
        },
        "domains": "Domaines",
        "ambassadors": "Ambassadeurs Aveine",
        "contactUs": "Contactez-nous"
      },
      "page": {
        "home": "Tableau de bord",
        "editMyInformation": "Editer mes informations",
        "myInvoices": "Mes Factures",
        "mySales": "Mes ventes",
        "createInvoice": "Emettre une facture",
        "declaredSales": "Ventes déclarées",
        "userList": "Liste d'utilisateur",
        "wine": {
          "create": "Ajouter un vin",
          "update": "Modifier un vin",
          "list": "Liste des vins",
          "read": "Fiche vin",
          "favorite": "Vins favoris",
          "history": "Derniers vins consultés"
        },
        "wineForm": {
          "list": "Liste des vins à valider",
          "validate": "Valider un vin"
        },
        "domain": {
          "create": "Ajouter un domaine",
          "update": "Modifier un domaine",
          "list": "Liste des domaines",
          "read": "Fiche domaine"
        },
        "ambassador": {
          "create": "Ajouter un ambassadeur",
          "update": "Modifier un ambassadeur",
          "list": "Liste des ambassadeurs",
          "read": "Fiche ambassadeur"
        },
        "grape": {
          "create": "Ajouter un cépage",
          "update": "Modifier un cépage",
          "list": "Liste des cépages",
          "read": "Fiche cépage"
        },
        "region": {
          "create": "Ajouter une région",
          "update": "Modifier une région",
          "list": "Liste des régions",
          "read": "Fiche région"
        },
        "company": {
          "create": "Ajouter une société",
          "update": "Modifier une société",
          "list": "Liste des sociétés",
          "read": "Fiche societé"
        },
        "appellation": {
          "create": "Ajouter une appellation",
          "list": "Liste des appellations",
          "update": "Modifier une appellation",
          "read": "Fiche appellation"
        },
      }
    },
    "profile": {
      "profile": "Profil",
      "editProfile": "Edition du Profil",
      "editTastingProfile": "Edition Profil Dégustation",
      "followings": "Abonnements",
      "followers": "Abonnés",
      "name": "Nom utilisateur",
      "description": "Description",
      "language": {
        "en": "English",
        "fr": "Français"
      },
      "button": {
        "deleteImage": "Retirer l'avater",
        "submit": "Valider",
        "uploadFile": "Choisir un fichier",
        "cancel": "Annuler",
        "showcase": "Mettre en avant"
      }
    },
    "search": {
      "wines": "Vins",
      "ambassadors": "Ambassadors",
      "domains": "Domaines",
      "users": "Utilisateurs",
      "searchWines": "Rechercher des vins",
      "searchAmassadors": "Rechercher des ambassadeurs",
      "searchDomains": "Rechercher des domaines",
      "searchUsers": "Rechercher des utilisateurs"
    },
    "user": {
      "follow": "Suivre",
      "unfollow": "Arrèter de suivre",
      "noResultFound": "Aucun résultat"
    },
    "newsfeed": {
      "scannedWines": "Vins scannés",
      "lastSeenWines": "Derniers vins consultés",
      "myTastingProfile": "Mon profile de dégustation",
      "myTastingProfileText": "Renseignez votre profil de dégustation et découvrez les vins recommandés pour vous !",
      "completeMyProfile": "Je complète mon Profil",
      "myPreferences": "Mes préferences",
      "reviewsWritten": "Avis déposés",
      'favoriteWines': "Vins Favoris",
      "badges": "Collection de badges",
      "badgesText": "Utilisez l'application, suivez des amis, rédigez des avis et débloquez des badges à mettre en avant sur votre profil !",
      "seeAllReviews": "Voir mes avis",
      "seeAllBadges": "Voir mes badges",
      "newBlogArticle": "Nouvel article de blog",
      "readMore": "Lire la suite",
      "newAveineAmbassador": "Nouvel Ambassadeur Aveine",
      "learnMore": "En savoir plus",
      "newUpdate": "Nouvelle version ",
      "scannedAWine": " a scanné un vin",
      "leftAReview": " a laissé un avis",
      "suggestionOfTheDay": "Suggestion du jour",
      "followOnInstagram": "Suivez-nous sur instagram !",
    },
    "badges": {
      "scan_red_level_1": "Amoureux du vin rouge",
      "scan_red_level_2": "Amoureux du vin rouge Bronze",
      "scan_red_level_3": "Amoureux du vin rouge Argent",
      "scan_red_level_4": "Amoureux du vin rouge Or",
      "scan_red_level_1_text": "Scanner un vin rouge",
      "scan_red_level_2_text": "Scanner 50 vins rouges",
      "scan_red_level_3_text": "Scanner 100 vins rouges",
      "scan_red_level_4_text": "Scanner 500 vins rouges",
      "scan_white_level_1": "Amoureux du vin blanc",
      "scan_white_level_2": "Amoureux du vin blanc Bronze",
      "scan_white_level_3": "Amoureux du vin blanc Argent",
      "scan_white_level_4": "Amoureux du vin blanc Or",
      "scan_white_level_1_text": "Scanner un vin blanc",
      "scan_white_level_2_text": "Scanner 50 vins blancs",
      "scan_white_level_3_text": "Scanner 100 vins blancs",
      "scan_white_level_4_text": "Scanner 500 vins blancs",
      "scan_rose_level_1": "Amoureux du vin rosé",
      "scan_rose_level_2": "Amoureux du vin rosé Bronze",
      "scan_rose_level_3": "Amoureux du vin rosé Argent",
      "scan_rose_level_4": "Amoureux du vin rosé Or",
      "scan_rose_level_1_text": "Scanner un vin rosé",
      "scan_rose_level_2_text": "Scanner 50 vins rosés",
      "scan_rose_level_3_text": "Scanner 100 vins rosés",
      "scan_rose_level_4_text": "Scanner 500 vins rosés",
      "blog_level_1": "Lecteur ponctuel",
      "blog_level_2": "Lecteur occasionnel",
      "blog_level_3": "Lecteur assidu",
      "blog_level_4": "Lecteur passionné",
      "blog_level_1_text": "Avoir lu un article du blog",
      "blog_level_2_text": "Avoir lu 5 articles du blog",
      "blog_level_3_text": "Avoir lu 10 articles du blog",
      "blog_level_4_text": "Avoir lu 20 articles du blog",
      "wine_form_level_1": "Contributeur débutant",
      "wine_form_level_2": "Contributeur confirmé",
      "wine_form_level_3": "Contributeur passionné",
      "wine_form_level_4": "Contributeur expert",
      "wine_form_level_1_text": "Avoir rempli un formulaires vin",
      "wine_form_level_2_text": "Avoir rempli 100 formulaires vins",
      "wine_form_level_3_text": "Avoir rempli 200 formulaires vins",
      "wine_form_level_4_text": "Avoir rempli 1000 formulaires vins",
      "tasting_note_level_1": "Critique débutant",
      "tasting_note_level_2": "Critique confirmé",
      "tasting_note_level_3": "Critique passionné",
      "tasting_note_level_4": "Critique expert",
      "tasting_note_level_1_text": "Avoir écrit un commentaire",
      "tasting_note_level_2_text": "Avoir écrit 100 commentaires",
      "tasting_note_level_3_text": "Avoir écrit 200 commentaires",
      "tasting_note_level_4_text": "Avoir écrit 500 commentaires",
      "backer": "Contributeur",
      "backer_text": "Avoir contribué au financement participatif",
      "favorite": "Coup de coeur",
      "favorite_text": "Mettre un vin en favori",
      "aerator": "Connexion réussie",
      "aerator_text": "Connecter un aérateur à l'application",
      "aerator_rename": "Mon aérateur s’appelle…",
      "aerator_rename_text": "Renommer son aérateur",
      "search_wine": "Je cherche un vin",
      "search_wine_text": "Rechercher un vin",
      "tasting_profile": "Prêt pour déguster !",
      "tasting_profile_text": "Remplir son profil dégustation"
    },
    "dashboard": {
      "submitAmbassadorInvoice": "Soumettre une facture Ambassadeur",
      "submitSuperAmbassadorInvoice": "Soumettre une facture Super Ambassadeur",
      "ambassadorInvoices": "Facture Ambassadeur en cours de validation",
      "superAmbassadorInvoices": "Facture Super Ambassadeur en cours de validation",
      "domainsWines": "Mes vins",
      "ambassadorSales": "Mes ventes",
      "superAmbassadorSales": "Ventes de mes Ambassadeurs",
      "seeMore": "Voir plus"
    },
    "invoices": {
      "ambassadorInvoices": "Factures ambassadeur",
      "superAmbassadorInvoices": "Factures super ambassadeur",
      "totalDeclared": "Total des revenues déclarés"
    },
    "sales": {
      "title": "Mes ventes",
      "discountCode": "Code promo",
      "currency": "Devise",
      "unlockedMoney": "Montant déclarable disponible",
      "lockedMoney": "Montant déclarable verrouillé",
      "unlockedAt": "dévérouillé le"
    },
    "tastingProfile": {
      "profileCompletedAt": "Profil complèté à ",
      "color": "Couleur",
      "effervescence": "Effervescence",
      "origin": "Provenance",
      "characteristics": "Characteristiques",
      "colorText": "Vin rouge, vin blanc, rosé...A quelle fréquence les dégustez-vous ?",
      "red": "Vins rouges",
      "white": "Vins blancs",
      "rosé": "Vins rosés",
      "next": "Continuer",
      "previous": "Précedent",
      "effervescenceText": "Plutôt tranquille ou mousseux ?",
      "sparkling": "Vins Mousseux",
      "still": "Vins Tranquilles",
      "regionText": "Des régions préférées ?",
      "appellationText": "Des Appellations ?",
      "grapeText": "Des cépages ?",
      "organic": "Vins bio",
      "natural": "Vins naturels",
      "vegan": "Vins végans",
      "submit": "Enregistrer",
      "profileSaved": "Profil sauvegardé !",
      "attributes": {
        "never": "Jamais",
        "no": "Rarement",
        "why_not": "Parfois",
        "yes": "Souvent",
        "always": "Toujours"
      }
    },
    "onBoarding": {
      "next": "Suivant",
      "back": "Précédent",
      "skip": "Ignorer",
      "submit": "Envoyer",
      "introduction": "Introduction",
      "introText": "Vous avez été invité à devenir Ambassadeur Aveine. Vous allez être guidé étape par étape pour la création de votre nouveau statut d'Ambassadeur.",
      "userNotLoggedIn": "Si vous disposez déjà d'un compte Aveine (pour vous connecter à l'application mobile par exemple), veuillez vous connecter avec celui-ci pour lui associer le statut d'Ambassadeur. \n Si vous souhaitez créer un nouveau compte Aveine cliquez sur \"Créer un compte\".",
      "createProAccount": "Créer un compte Pro Aveine",
      "searchDomain": "Rechercher votre domaine",
      "selectDomainPrompt": "Veuillez rechercher votre domaine (Si vous ne le trouvez pas vous pouvez ignorer cette étape pour l'ajouter)",
      "userDomainField": "Domain",
      "domainValidation": "Le domaine que vous recherchez est introuvable",
      "domainAlreadyRegistered": "Ce domaine est déjà inscrit",
      "tos": "Conditions Générales d'Utilisation",
      "agreeTos": "J'accepte les conditions générales d'utilisation",
      "accountInfo": "Informations compte",
      "complementaryInfo": "Informations complémentaires",
      "payment": "Paiement",
      "checkout": "Commander votre kit",
      "orderButton": "Commander",
      "signIn": "S'inscrire",
      "logIn": "Se connecter",
      "storeSelection": "Pour devenir Ambassadeur, vous devez disposer d’un kit ambassadeur comprenant un aérateur, un code promo personnalisé et des supports de communication.",
      "willShipTo": "Le kit est à expédier : ",
      "france": "En France",
      "europe": "En Europe",
      "northAmerica": "En Amérique du Nord",
      "international": "Autre",
      "alreadyHaveAPurchaseCode": "Une fois le paiement effectué, vous allez recevoir un code d'achat.\nSi vous l'avez déjà, merci de le renseigner ci-dessous pour continuer l'inscription.",
      "purchaseCode": "Entrez un code d'achat",
      "purchaseCodeToolTip": "Le code d'achat vous est donné sur la page de confirmation de votre commande.",
      "accountCreationInfo": "Pour finaliser la création de votre compte, veuillez renseigner une adresse mail valide et un mot de passe. Vous utiliserez ensuite les identifiants créés pour vous connecter à l'espace Pro Aveine :",
      "personalInfoText": "Pour finir, veuillez remplir les informations en rapport à votre activité.\nCes informations seront publiques et apparaîtront sur les différentes applications Aveine.\n (*Seuls le nom, la socièté et l'adresse sont obligatoires)",
      "finalization": "Finalisation",
      "signInSuccess": "Bienvenue !\nVous êtes désormais Ambassadeur Aveine",
      "proceedWithLogin": "Veuillez maintenant vous connecter en cliquant sur le bouton ci-dessous",
      "backToIndex": "Retourner à la page d'accueil",
    },
    "errors": {
      "error": "Erreur",
      "inputRequired": "Ce champ est requis",
      "cantRetrieveSelf": "Impossible de trouver le compte ambassadeur associé",
      "errorPassword": "Le mot de passe ne correspond pas",
      "emailValidation": "L'email donné est invalide",
      "passwordValidation": "Le mot de passe ne remplit pas les conditions",
      "registrationError": "Une erreur innatendue s'est produite, veuillez rafraichir la page.\nSi le problème persiste veuillez nous contacter",
      "roleAlreadyAssigned": "Cet utilisateur possède déjà ce rôle",
      "invitationAlreadyConsumed": "L'invitation que vous essayez d'ouvrir a déjà été utilisée ou a expirée",
      "noPurchaseCode": "Veuillez entrer un code d'achat pour continuer",
      "notProError": "Votre compte n'a pas les droits d'accéder aux fonctionalités de l'Espace Pro Aveine",
      "pageNotFound": "Page introuvable",
      "unknownError": "Erreur inconnue"
    }
  }
}
<template>
  <div class="home py-5">
    <span class="d-flex flex-row page-title">{{$t("aveine-space.title.page.editMyInformation")}}</span>
    <div v-if="dataLoaded" class="form-container">
      <AveineAmbassadorForm :APIbaseURL="APIbaseURL" :ambassador="ambassador" />
    </div>
    <div v-else class="d-flex justify-center align-center mt-16">
      <v-progress-circular
        indeterminate
        :size="100"
        :width="10"
        color="secondary"
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import { AveineAmbassadorForm } from 'aveine-ambassador'
import { Ambassador } from 'aveine-toolbox'
import { errorHandler } from 'aveine-toolbox'
export default {
  data () {
    return {
      ambassador: {},
      invitation: {},
      dataLoaded: false,
      APIbaseURL: process.env.VUE_APP_API_PRO_HOST
    }
  },
  name: 'Update',
  components: {
    AveineAmbassadorForm
  },
  async created() {
    try {
      await Ambassador.api().get(`ambassadors/${this.$route.params.id}?lang=${this.$i18n.locale}`, {
          baseURL: this.APIbaseURL,
          params: {
            include:
            'super-ambassador,'+
            'address.country,address.spatial-information,' +
            'company.company-contact,company.address.spatial-information',
          }
        }
      );

      this.ambassador = await Ambassador.query().whereId(this.$route.params.id).withAllRecursive().first();

      this.dataLoaded = true;
    } catch (errors) {
      this.dataLoaded = true;
      errorHandler(errors, this)
    }
  }
}
</script>

<template>
  <div class="index py-5">
    <span class="d-flex flex-row page-title">{{$t("aveine-space.title.page.userList")}}</span>
    <div v-if="dataLoaded">
      <div class="form-container">
        <AveineUserList :key="currentPage" :APIbaseURL="APIbaseURL" :users="users"/>
      </div>
      <div class="text-center mb-10">
        <v-pagination
          v-model="currentPage"
          :length="paginationLength"
          :total-visible="paginationLength < 7 ? paginationLength : 7"
        ></v-pagination>
      </div>
    </div>
    <div v-else class="d-flex justify-center align-center mt-16">
      <v-progress-circular
        indeterminate
        :size="100"
        :width="10"
        color="secondary"
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import { User } from 'aveine-toolbox'
import AveineUserList from '@/components/AveineUserList.vue'
export default {
  name: 'Index',
    data () {
    return {
      users: [],
      currentPage: this.$route.query.page ? this.$route.query.page : 1,
      paginationLength: 0,
      dataLoaded: false,
      APIbaseURL: process.env.VUE_APP_API_CONSUMER_HOST
    }
  },
  watch: {
    async currentPage (val) {
      this.dataLoaded= false;
      this.$router.push({ name: 'UserIndex', query: { page: val, query: this.$route.query.query}});
      this.loadDate();
    },
  },
  created() {
    this.loadDate();
  },
  components: {
    AveineUserList
  },
  methods: {
    async loadDate() {
      let userReq = await User.api().get(`users?lang=${this.$i18n.locale}`, {
        params: {
          "query": this.$route.query.query,   
          "sort": "created-at",
          "page[number]": this.$route.query.page ? this.$route.query.page : 1,
          "page[size]": 40
        }
      });
      this.paginationLength = userReq.response.data.meta.last
      this.users = userReq.response.data;
      this.dataLoaded = true
    }
  }
}
</script>

<template>
  <div class="index py-5">
    <div class="searchable-list-container">
      <AveineWineList :APIbaseURL="APIbaseURL" />
    </div>
  </div>
</template>

<script>

import { AveineWineList } from 'aveine-wine'
export default {
  name: 'Index',
  components: {
    AveineWineList
  },
  data () {
    return {
      APIbaseURL: process.env.VUE_APP_API_CONSUMER_HOST
    }
  }
}
</script>

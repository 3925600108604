import Vue from 'vue'
import App from './App.vue'
import { Auth0Plugin } from "./auth/index";
import { store } from './store/index'
import router from './router'
import i18n from './plugins/i18n.js';
import VueToastr from 'vue-toastr'
import vuetify from './plugins/vuetify'
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_API_KEY,
    libraries: 'places'
  },
  installComponents: true
})

Vue.use(VueToastr, {
  defaultPosition: "toast-bottom-center",
  defaultStyle: { "font-family": "'Montserrat', sans-serif" },
});
Vue.use(Auth0Plugin, {
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
  audience: "https://pro.aveine.paris",
  responseType: "token id_token",
  scope: "openid profile email",
  useRefreshTokens: localStorage.getItem("cookie:accepted") ? true : false,
  cacheLocation: localStorage.getItem("cookie:accepted") ? 'localstorage' : null,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
})


Vue.config.productionTip = false

new Vue({
  i18n,
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')

<template>
  <div class="index py-5">
    <span class="d-flex flex-row page-title">{{$t("aveine-space.title.page.wine.favorite")}}</span>
    <div class="list-container">
      <div v-if="dataLoaded">
        <AveineWineUserSettingList :APIbaseURL="APIbaseURL" :wineUserSettings="wineUserSettings"/>
        <div v-if="wineUserSettings.length == 0">
          <div class="d-flex flex-column ma-16 justify-center align-center">
            <v-icon class="mx-16 mt-16" large color="primary">mdi-close-circle</v-icon>
            <h4 class="text-center mx-16 mb-16">
              {{$t("aveine-wine.title.noResultFound")}}
            </h4>
          </div>
        </div>
        <div class="text-center">
          <v-pagination
            v-model="currentPage"
            :length="paginationLength"
            :total-visible="paginationLength < 7 ? paginationLength : 7"
          ></v-pagination>
        </div>
      </div>
      <div v-else>
        <div class="d-flex flex-row mb-10 flex-wrap">
          <v-skeleton-loader
            class="ma-4"
            type="article, actions"
            v-for="index in 42" :key="index" 
          ></v-skeleton-loader>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AveineWineUserSettingList } from 'aveine-wine'
import { WineUserSetting } from 'aveine-toolbox'
import { errorHandler } from 'aveine-toolbox'
export default {
  name: 'WineFormIndex',
  components: {
    AveineWineUserSettingList
  },
    data () {
    return {
      wineUserSettings: [],
      currentPage: this.$route.query.page ? this.$route.query.page : 1,
      paginationLength: 0,
      dataLoaded: false,
      APIbaseURL: process.env.VUE_APP_API_CONSUMER_HOST
    }
  },
  watch: {
    async currentPage (val) {
      this.dataLoaded= false;
      this.$router.push({ path: '/authenticated/consumer/wine/favorite', query: { page: val }});
      this.loadDate();
    },
  },
  created() {
    this.loadDate();
  },
  methods: {
    async loadDate () {
      try {
        this.wineUserSettings = []
        let wineSettingRequest = await WineUserSetting.api().get(`users/self/wine-settings?lang=${this.$i18n.locale}`, {
            baseURL: process.env.VUE_APP_API_CONSUMER_HOST,
            params: {
              "include": "wine.template,wine.appellation,wine.primary-wine-label",
              "filter[favorite]": true,
              "sort": "-last-access",
              "page[number]": this.$route.query.page ? this.$route.query.page : 1,
              "page[size]": 40
            }
          }
        );

  
        wineSettingRequest.response.data.map(async (wineSetting) => {
          const wineUserSetting = await WineUserSetting.query().whereId(wineSetting.id).withAllRecursive().first()
          let wineToPush = wineUserSetting.wine

          if (wineToPush.cuvee) {
            wineToPush.designation = wineToPush.cuvee;
          } else if (wineToPush.appellation && wineToPush.appellation.name) {
            wineToPush.designation = wineToPush.appellation.name;
          } else if (wineToPush.template && wineToPush.template.color) {
            wineToPush.designation = this.$t(`aveine-wine.attributes.wineTypes.${wineToPush.template.color}`)
          } else {
            wineToPush.designation = "-"
          }
          this.wineUserSettings.push(wineUserSetting);
        });

        this.paginationLength = wineSettingRequest.response.data.meta.last
        this.dataLoaded = true;
      } catch (errors) {
        this.dataLoaded = true;
        errorHandler(errors, this)
      }
    }
  }
}
</script>

<template>
  <div class="home py-5">
    <span class="d-flex flex-row page-title">{{$t("aveine-space.title.page.createInvoice")}}</span>
    <div v-if="dataLoaded" class="form-container">
      <AveineInvoiceForm :APIbaseURL="APIbaseURL" :invoice="invoice" :invoiceSummary="invoiceSummary" :ambassador="ambassador" />
    </div>
    <div v-else class="d-flex justify-center align-center mt-16">
      <v-progress-circular
        indeterminate
        :size="100"
        :width="10"
        color="secondary"
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import { AveineInvoiceForm } from 'aveine-invoice'
import { Invoice, Ambassador } from 'aveine-toolbox'
export default {
  data () {
    return {
      invoice: {},
      invoiceSummary: {},
      ambassador: {},
      dataLoaded: false,
      APIbaseURL: process.env.VUE_APP_API_PRO_HOST
    }
  },
  name: 'Create',
  components: {
    AveineInvoiceForm
  },
  async created() {
    this.authToken = await this.$auth.getTokenSilently();
    let fetchTotalSummary = await fetch(`${this.APIbaseURL}/orders/ambassadors/${this.$route.params.id}/summary`, {
      method: "GET",
      headers: {
        "Authorization": `Bearer ` + this.authToken
      }
    })

    const invoiceSummaryJson = await fetchTotalSummary.json();
    this.invoiceSummary = invoiceSummaryJson.meta;

    await Ambassador.api().get(`ambassadors/${this.$route.params.id}?lang=${this.$i18n.locale}`, {
        baseURL: this.APIbaseURL,
        params: {
          include:
          'super-ambassador,'+
          'address.country,address.spatial-information,' +
          'company.company-contact,company.address.spatial-information',
        }
      }
    );

    this.ambassador = await Ambassador.query().whereId(this.$route.params.id).withAllRecursive().first();

    this.invoice = await Invoice.new()
    
    this.invoice.$isNew = true;
    this.invoice.currency = "EUR"
    this.dataLoaded = true;
  },
}
</script>

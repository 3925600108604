<template>
  <div class="index py-5">
    <div class="searchable-list-container">
      <AveineAmbassadorList :APIbaseURL="APIbaseURL" />
    </div>
  </div>
</template>

<script>
import { AveineAmbassadorList } from 'aveine-ambassador'
export default {
  name: 'Index',
  components: {
    AveineAmbassadorList
  },
  data () {
    return {
      APIbaseURL: process.env.VUE_APP_API_CONSUMER_HOST
    }
  }
}
</script>

<template>
  <v-container class="stepper-container" v-if="dataLoaded">
    <div class="d-flex flex-column justify-center align-center ma-6">
      <span class="mb-4">{{$t("aveine-space.tastingProfile.profileCompletedAt")}}</span>
      <v-progress-circular
        :rotate="-90"
        :size="90"
        :width="10"
        :value="tastingProfile.completionRate"
        color="secondary"
      >
        {{ tastingProfile.completionRate }}%
      </v-progress-circular>
    </div>
    <v-stepper v-model="currentStep">
      <v-stepper-header>
        <v-stepper-step
          :complete="currentStep > 1"
          step="1"
        >
          {{$t("aveine-space.tastingProfile.color")}}
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
          :complete="currentStep > 2"
          step="2"
        >
          {{$t("aveine-space.tastingProfile.effervescence")}}
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="3">
          {{$t("aveine-space.tastingProfile.origin")}}
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="4">
          {{$t("aveine-space.tastingProfile.characteristics")}}
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card
            class="mb-12 pl-xl-16 pl-lg-14"
          >
            <span class="d-flex justify-center mr-xl-16 mr-lg-14 mt-2 text-subtitle-1">{{$t("aveine-space.tastingProfile.colorText")}}</span>
            <v-row class="d-flex flex-xl-row flex-lg-row flex-column">
              <v-col>
                <v-radio-group
                  v-model="tastingProfile.red"
                  class="d-flex flex-xl-row flex-lg-row flex-column justify-space-between  ml-4 radio-wine-types"
                  :row="$vuetify.breakpoint.name !== 'xs'"
                >
                  <span class="mr-4 mt-3 attribute-title">{{$t("aveine-space.tastingProfile.red")}} : </span>
                  <v-radio
                    class="my-3"
                    :label="$t('aveine-space.tastingProfile.attributes.never')"
                    value="never"
                  ></v-radio>
                  <v-radio
                    class="my-3"
                    :label="$t('aveine-space.tastingProfile.attributes.no')"
                    value="no"
                  >
                  </v-radio>
                  <v-radio
                    class="my-3"
                    :label="$t('aveine-space.tastingProfile.attributes.why_not')"
                    value="why_not"
                  >
                  </v-radio>
                  <v-radio
                    class="my-3"
                    :label="$t('aveine-space.tastingProfile.attributes.yes')"
                    value="yes"
                  >
                  </v-radio>
                  <v-radio
                    class="my-3"
                    :label="$t('aveine-space.tastingProfile.attributes.always')"
                    value="always"
                  >
                  </v-radio>
                </v-radio-group>
              </v-col>
            </v-row>

            <v-row class="d-flex flex-xl-row flex-lg-row flex-column ">
              <v-col>
                <v-radio-group
                  v-model="tastingProfile.white"
                  class="d-flex flex-xl-row flex-lg-row flex-column justify-space-between  ml-4 radio-wine-types"
                  :row="$vuetify.breakpoint.name !== 'xs'"
                >
                  <span class="mr-4 mt-3 attribute-title">{{$t("aveine-space.tastingProfile.white")}} : </span>
                  <v-radio
                    class="my-3"
                    :label="$t('aveine-space.tastingProfile.attributes.never')"
                    value="never"
                  ></v-radio>
                  <v-radio
                    class="my-3"
                    :label="$t('aveine-space.tastingProfile.attributes.no')"
                    value="no"
                  >
                  </v-radio>
                  <v-radio
                    class="my-3"
                    :label="$t('aveine-space.tastingProfile.attributes.why_not')"
                    value="why_not"
                  >
                  </v-radio>
                  <v-radio
                    class="my-3"
                    :label="$t('aveine-space.tastingProfile.attributes.yes')"
                    value="yes"
                  >
                  </v-radio>
                  <v-radio
                    class="my-3"
                    :label="$t('aveine-space.tastingProfile.attributes.always')"
                    value="always"
                  >
                  </v-radio>
                </v-radio-group>
              </v-col>
            </v-row>

            <v-row class="d-flex flex-xl-row flex-lg-row flex-column ">
              <v-col>
                <v-radio-group
                  v-model="tastingProfile.rose"
                  class="d-flex flex-xl-row flex-lg-row flex-column justify-space-between  ml-4 radio-wine-types"
                  :row="$vuetify.breakpoint.name !== 'xs'"
                >
                  <span class="mr-4 mt-3 attribute-title">{{$t("aveine-space.tastingProfile.rosé")}} : </span>
                  <v-radio
                    class="my-3"
                    :label="$t('aveine-space.tastingProfile.attributes.never')"
                    value="never"
                  ></v-radio>
                  <v-radio
                    class="my-3"
                    :label="$t('aveine-space.tastingProfile.attributes.no')"
                    value="no"
                  >
                  </v-radio>
                  <v-radio
                    class="my-3"
                    :label="$t('aveine-space.tastingProfile.attributes.why_not')"
                    value="why_not"
                  >
                  </v-radio>
                  <v-radio
                    class="my-3"
                    :label="$t('aveine-space.tastingProfile.attributes.yes')"
                    value="yes"
                  >
                  </v-radio>
                  <v-radio
                    class="my-3"
                    :label="$t('aveine-space.tastingProfile.attributes.always')"
                    value="always"
                  >
                  </v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-divider class="my-8"></v-divider>
          </v-card>
          <div class="d-flex justify-end">
            <v-btn
              color="primary"
              @click="currentStep += 1"
            >
            {{$t("aveine-space.tastingProfile.next")}}
            </v-btn>
          </div>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-card
            class="mb-12 pl-xl-16 pl-lg-14"
          >
            <span class="d-flex justify-center mr-xl-16 mr-lg-14 text-subtitle-1">{{$t("aveine-space.tastingProfile.effervescenceText")}}</span>
            <v-row class="d-flex flex-xl-row flex-lg-row flex-column ">
              <v-col>
                <v-radio-group
                  v-model="tastingProfile.effervescent"
                  class="d-flex flex-xl-row flex-lg-row flex-column justify-space-between  ml-4 radio-wine-types"
                  :row="$vuetify.breakpoint.name !== 'xs'"
                >
                  <span class="mr-4 mt-3 attribute-title">{{$t("aveine-space.tastingProfile.sparkling")}} : </span>
                  <v-radio
                    class="my-3"
                    :label="$t('aveine-space.tastingProfile.attributes.never')"
                    value="never"
                  ></v-radio>
                  <v-radio
                    class="my-3"
                    :label="$t('aveine-space.tastingProfile.attributes.no')"
                    value="no"
                  >
                  </v-radio>
                  <v-radio
                    class="my-3"
                    :label="$t('aveine-space.tastingProfile.attributes.why_not')"
                    value="why_not"
                  >
                  </v-radio>
                  <v-radio
                    class="my-3"
                    :label="$t('aveine-space.tastingProfile.attributes.yes')"
                    value="yes"
                  >
                  </v-radio>
                  <v-radio
                    class="my-3"
                    :label="$t('aveine-space.tastingProfile.attributes.always')"
                    value="always"
                  >
                  </v-radio>
                </v-radio-group>
              </v-col>
            </v-row>

            <v-row class="d-flex flex-xl-row flex-lg-row flex-column">
              <v-col>
                <v-radio-group
                  v-model="tastingProfile.still"
                  class="d-flex flex-xl-row flex-lg-row flex-column justify-space-between  ml-4 radio-wine-types"
                  :row="$vuetify.breakpoint.name !== 'xs'"
                >
                <span class="mr-4 mt-3 attribute-title">{{$t("aveine-space.tastingProfile.still")}}: </span>
                  <v-radio
                    class="my-3"
                    :label="$t('aveine-space.tastingProfile.attributes.never')"
                    value="never"
                  ></v-radio>
                  <v-radio
                    class="my-3"
                    :label="$t('aveine-space.tastingProfile.attributes.no')"
                    value="no"
                  >
                  </v-radio>
                  <v-radio
                    class="my-3"
                    :label="$t('aveine-space.tastingProfile.attributes.why_not')"
                    value="why_not"
                  >
                  </v-radio>
                  <v-radio
                    class="my-3"
                    :label="$t('aveine-space.tastingProfile.attributes.yes')"
                    value="yes"
                  >
                  </v-radio>
                  <v-radio
                    class="my-3"
                    :label="$t('aveine-space.tastingProfile.attributes.always')"
                    value="always"
                  >
                  </v-radio>
                </v-radio-group>
              </v-col>
            </v-row>

            <v-divider class="my-8"></v-divider>
          </v-card>
          <div class="d-flex justify-space-between">
            <v-btn text @click="currentStep -= 1">
              {{$t("aveine-space.tastingProfile.previous")}}
            </v-btn>

            
            <v-btn
              color="primary"
              @click="currentStep += 1"
            >
            {{$t("aveine-space.tastingProfile.next")}}
            </v-btn>
          </div>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-card
            class="mb-12 d-flex flex-column justify-center pl-10"
          >
            <span class="text-subtitle-1">
              {{$t("aveine-space.tastingProfile.regionText")}}
            </span> 

            <v-row class="d-flex mb-2 mt-2" v-for="(region, index) in tastingProfile.regions.slice(0, 3)" :key="region.index">
              <v-col class="ml-xl-16 ml-lg-16" cols="7" v-if="tastingProfile.regions[index] && tastingProfile.regions[index].name && (tastingProfile.regions[index].name.fr || tastingProfile.regions[index].name.en)">
                <v-text-field
                  readonly
                  v-model="tastingProfile.regions[index].name[$i18n.locale]"
                  append-icon="mdi-close"
                  @click:append="tastingProfile.regions[index] = {}"
                  label="Région"
                ></v-text-field>
              </v-col>
              <v-col cols="7" class="d-flex ml-xl-16 ml-lg-16" v-else>
                <v-autocomplete
                  :items="searchedRegions[index]"
                  :loading="isLoading"
                  :search-input.sync="regionSearch[index]"
                  @update:search-input="onRegionSearch(index)"
                  @change="updateRegion($event, index)"
                  :no-filter="true"
                  color="white"
                  hide-no-data
                  hide-selected
                  item-text="name.fr"
                  label="Région"
                  placeholder="Région"
                  return-object
                >
                </v-autocomplete>
              </v-col>
            </v-row>

            <v-divider class="my-8"></v-divider>

            <span class="text-subtitle-1">
              {{$t("aveine-space.tastingProfile.appellationText")}}
            </span> 

            <v-row class="d-flex mb-2 mt-2" v-for="(appellation, index) in tastingProfile.appellations.slice(0, 3)" :key="appellation.id">
              <v-col class="ml-xl-16 ml-lg-16" cols="7" v-if="tastingProfile.appellations[index] && tastingProfile.appellations[index].name">
                <v-text-field
                  readonly
                  v-model="tastingProfile.appellations[index].name"
                  append-icon="mdi-close"
                  @click:append="tastingProfile.appellations[index] = {}"
                  label="Appellation"
                ></v-text-field>
              </v-col>
              <v-col cols="7" class="d-flex ml-xl-16 ml-lg-16" v-else>
                <v-autocomplete
                  :items="searchedAppellations[index]"
                  :search-input.sync="appellationSearch[index]"
                  @update:search-input="onAppellationSearch(index)"
                  @change="updateAppellation($event, index)"
                  :no-filter="true"
                  color="white"
                  hide-no-data
                  hide-selected
                  item-text="name"
                  label="Appellation"
                  placeholder="Appellation"
                  return-object
                >
                </v-autocomplete>
              </v-col>
            </v-row>

            <v-divider class="my-8"></v-divider>

            <span class="text-subtitle-1">
              {{$t("aveine-space.tastingProfile.grapeText")}}
            </span> 

            <v-row class="d-flex mb-2 mt-2" v-for="(grape, index) in tastingProfile.grapes.slice(0, 3)" :key="grape.id">
              <v-col class="ml-xl-16 ml-lg-16" cols="7" v-if="tastingProfile.grapes[index] && tastingProfile.grapes[index].grape_variety && tastingProfile.grapes[index].grape_variety.name">
                <v-text-field
                  readonly
                  v-model="tastingProfile.grapes[index].grape_variety.name"
                  append-icon="mdi-close"
                  @click:append="tastingProfile.grapes[index] = null"
                  :label="$t('aveine-wine.attributes.grapesComposesWine.grape')"
                ></v-text-field>
              </v-col>
              <v-col cols="7" class="d-flex ml-xl-16 ml-lg-16" v-else>
                <v-autocomplete
                  :items="searchedGrapes[index]"
                  :search-input.sync="grapeSearch[index]"
                  @update:search-input="onGrapeSearch(index)"
                  @change="updateGrape($event, index)"
                  :no-filter="true"
                  color="white"
                  hide-no-data
                  hide-selected
                  item-text="grape_variety.name"
                  :label="$t('aveine-wine.attributes.grapesComposesWine.grape')"
                  :placeholder="$t('aveine-wine.attributes.grapesComposesWine.grape')"
                  return-object
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-card>
          <div class="d-flex justify-space-between">
            <v-btn text @click="currentStep -= 1">
              {{$t("aveine-space.tastingProfile.previous")}}
            </v-btn>

            <v-btn
              color="primary"
              @click="currentStep += 1"
            >
            {{$t("aveine-space.tastingProfile.next")}}
            </v-btn>
          </div>
        </v-stepper-content>

        <v-stepper-content step="4">
          <v-card
            class="mb-12 pl-xl-16 pl-lg-14"
          >
            <v-row class="d-flex flex-xl-row flex-lg-row flex-column">
              <v-col>
                <v-radio-group
                  v-model="tastingProfile.organic"
                  class="d-flex flex-xl-row flex-lg-row flex-column justify-space-between  ml-4 radio-wine-types"
                  row
                >
                  <span class="mr-4 mt-3 attribute-title">{{$t("aveine-space.tastingProfile.organic")}} : </span>
                  <v-radio
                    class="my-3"
                    :label="$t('aveine-space.tastingProfile.attributes.never')"
                    value="never"
                  ></v-radio>
                  <v-radio
                    class="my-3"
                    :label="$t('aveine-space.tastingProfile.attributes.no')"
                    value="no"
                  >
                  </v-radio>
                  <v-radio
                    class="my-3"
                    :label="$t('aveine-space.tastingProfile.attributes.why_not')"
                    value="why_not"
                  >
                  </v-radio>
                  <v-radio
                    class="my-3"
                    :label="$t('aveine-space.tastingProfile.attributes.yes')"
                    value="yes"
                  >
                  </v-radio>
                  <v-radio
                    class="my-3"
                    :label="$t('aveine-space.tastingProfile.attributes.always')"
                    value="always"
                  >
                  </v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row class="d-flex flex-xl-row flex-lg-row flex-column ">
              <v-col>
                <v-radio-group
                  v-model="tastingProfile.natural"
                  class="d-flex flex-xl-row flex-lg-row flex-column justify-space-between  ml-4 radio-wine-types"
                  row
                >
                <span class="mr-4 mt-3 attribute-title">{{$t("aveine-space.tastingProfile.natural")}} : </span>
                  <v-radio
                    class="my-3"
                    :label="$t('aveine-space.tastingProfile.attributes.never')"
                    value="never"
                  ></v-radio>
                  <v-radio
                    class="my-3"
                    :label="$t('aveine-space.tastingProfile.attributes.no')"
                    value="no"
                  >
                  </v-radio>
                  <v-radio
                    class="my-3"
                    :label="$t('aveine-space.tastingProfile.attributes.why_not')"
                    value="why_not"
                  >
                  </v-radio>
                  <v-radio
                    class="my-3"
                    :label="$t('aveine-space.tastingProfile.attributes.yes')"
                    value="yes"
                  >
                  </v-radio>
                  <v-radio
                    class="my-3"
                    :label="$t('aveine-space.tastingProfile.attributes.always')"
                    value="always"
                  >
                  </v-radio>
                </v-radio-group>
              </v-col>
            </v-row>

            <v-row class="d-flex flex-xl-row flex-lg-row flex-column ">
              <v-col>
                <v-radio-group
                  v-model="tastingProfile.vegan"
                  class="d-flex flex-xl-row flex-lg-row flex-column justify-space-between  ml-4 radio-wine-types"
                  row
                >
                <span class="mr-4 mt-3 attribute-title">{{$t("aveine-space.tastingProfile.vegan")}} : </span>
                  <v-radio
                    class="my-3"
                    :label="$t('aveine-space.tastingProfile.attributes.never')"
                    value="never"
                  ></v-radio>
                  <v-radio
                    class="my-3"
                    :label="$t('aveine-space.tastingProfile.attributes.no')"
                    value="no"
                  >
                  </v-radio>
                  <v-radio
                    class="my-3"
                    :label="$t('aveine-space.tastingProfile.attributes.why_not')"
                    value="why_not"
                  >
                  </v-radio>
                  <v-radio
                    class="my-3"
                    :label="$t('aveine-space.tastingProfile.attributes.yes')"
                    value="yes"
                  >
                  </v-radio>
                  <v-radio
                    class="my-3"
                    :label="$t('aveine-space.tastingProfile.attributes.always')"
                    value="always"
                  >
                  </v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-divider class="my-8"></v-divider>
          </v-card>
          <div class="d-flex justify-space-between">
            <v-btn text @click="currentStep -= 1">
              {{$t("aveine-space.tastingProfile.previous")}}
            </v-btn>

            <v-btn
              color="primary"
              @click="submit()"
            >
            {{$t("aveine-space.tastingProfile.submit")}}
            </v-btn>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
  <div v-else class="d-flex justify-center align-center mt-16">
    <v-progress-circular
      indeterminate
      :size="100"
      :width="10"
      color="secondary"
    ></v-progress-circular>
  </div>
</template>
<script>
import algoliasearch from 'algoliasearch/lite';
import { errorHandler } from 'aveine-toolbox'
import { Appellation, TastingProfile, Region, Grape } from 'aveine-toolbox';

export default {
  props: { 
    /**
     * Store the tastingprofile ressource to work on
     * 
     * @type {Object}
     */
    tastingProfile: Object,
    /**
     * Callback after submit
     * 
     * @type {Function}
     */
    callback: Function,
    /**
     * The base URL of the API used for calls in the current instantiation of the component
     * 
     * @type {String}
     */
    APIbaseURL: String
  },
  data: () => ({
    /**
     * Stepper current step
     * 
     * @type {String}
     */
    currentStep: 1,
    /**
     * The algolia search query for regions 
     * 
     * @type {String}
     */
    regionSearch: [],
    /**
     * Store the list of result from the algolia region search
     * 
     * @type {Array}
     */
    searchedRegions: [],
    /**
     * The algolia search query for appellations 
     * 
     * @type {String}
     */
    appellationSearch: [],
    /**
     * Store the list of result from the algolia appellation search
     * 
     * @type {Array}
     */
    searchedAppellations: [],
    /**
     * The algolia search queries for each grape search
     * 
     * @type {Array}
     */
    grapeSearch: [],
    /**
     * Store the list of result from the algolia grape search for each input
     * 
     * @type {Array}
     */
    searchedGrapes: [],
    /**
     * Flag to determine if the algolia search is loading
     * 
     * @type {Boolean}
     */
    isLoading: false,
    /**
     * Flag to determine if the form is correcly filled
     * 
     * @type {Boolean}
     */
    isFormValid: false,
    /**
     * Flag to determine when the data is fully loaded
     * 
     * @type {Boolean}
     */
    dataLoaded: true,
  }),
	computed: {
    /**
     * Algolia Search configuration
     * 
     * @return {Object}
     */
		algoliaClient() {
      return algoliasearch(process.env.VUE_APP_ALGOLIA_APP_ID, process.env.VUE_APP_ALGOLIA_API_KEY);
    },

    /**
     * Configuring Algolia Search for region search
     * 
     * @return {Object}
     */
    algoliaIndexRegions () {
      return this.algoliaClient.initIndex(`${process.env.VUE_APP_ALGOLIA_ENV}_regions`);
    },

    /**
     * Configuring Algolia Search for grape search
     * 
     * @return {Object}
     */
		algoliaIndexGrapes () {
      return this.algoliaClient.initIndex(`${process.env.VUE_APP_ALGOLIA_ENV}_grapes`);
    },

    /**
     * Configuring Algolia Search for region search
     * 
     * @return {Object}
     */
    algoliaIndexAppellations () {
      return this.algoliaClient.initIndex(`${process.env.VUE_APP_ALGOLIA_ENV}_appellations`);
    },
	},
  async created () {
    for (let index = this.tastingProfile.grapes.length; index < 3; index++) {
      this.tastingProfile.grapes[index] =  await Grape.insert({data: {$isnew: true}})
      this.tastingProfile.grapes[index].$isNew = true
    }

    for (let index = this.tastingProfile.appellations.length; index < 3; index++) {
      this.tastingProfile.appellations[index] = await Appellation.new();
      this.tastingProfile.appellations[index].$isNew = true
    }

    for (let index = this.tastingProfile.regions.length; index < 3; index++) {
      this.tastingProfile.regions[index] = await Region.new();
      this.tastingProfile.regions[index].$isNew = true
    }

    this.completionRateCalculation();
  },
  watchers: {
    currentStep () {
      this.completionRateCalculation();
    }
  },
  methods: {
    /**
     * Completion rate calculation
     * 
     */
    completionRateCalculation () {
      // Completion rate calcultation
      let completion = 0;
      if (this.tastingProfile.red) {
        completion += 1
      }
      if (this.tastingProfile.white) {
        completion += 1
      }
      if (this.tastingProfile.rose) {
        completion += 1
      }
      if (this.tastingProfile.effervescent) {
        completion += 1
      }
      if (this.tastingProfile.still) {
        completion += 1
      }
      if (this.tastingProfile.regions[0] && this.tastingProfile.regions[0].id && !this.tastingProfile.regions[0].$isNew) {
        completion += 1
      }
      if (this.tastingProfile.appellations[0] && this.tastingProfile.appellations[0].id && !this.tastingProfile.appellations[0].$isNew) {
        completion += 1
      }
      if (this.tastingProfile.grapes[0] && this.tastingProfile.grapes[0].id && !this.tastingProfile.grapes[0].$isNew) {
        completion += 1
      }
      if (this.tastingProfile.vegan) {
        completion += 1
      }
      if (this.tastingProfile.organic) {
        completion += 1
      }
      if (this.tastingProfile.natural) {
        completion += 1
      }
      this.tastingProfile.completionRate = Math.round((completion / 11) * 100);
    },
    /**
     * Trigger an Algolia search for regions whenever search input has been change
     * 
     * @param index the index of region input on which to search
     */
    async onRegionSearch (index) {
      let result = await this.algoliaIndexRegions.search(this.regionSearch[index]);
      this.searchedRegions[index] = result.hits;
    },

    /**
     * Triggered on region selection from algolia results
     * Set the selected region
     * 
     * @param event autocompelete input state
     */
    async updateRegion (event, index) {
      const reqRegion = await Region.insert({data: event});
      this.tastingProfile.regions[index] = reqRegion.regions[0]
    },

    /**
     * Trigger an Algolia search for appellations whenever search input has been change
     * 
     * @param index the index of appellation input on which to search
     */
    async onAppellationSearch (index) {
      let result = await this.algoliaIndexAppellations.search(this.appellationSearch[index]);
      this.searchedAppellations[index] = result.hits;
    },

    /**
     * Triggered on appellation selection from algolia results
     * Set the selected appellation
     * 
     * @param event autocompelete input state
     */
    async updateAppellation (event, index) {
      const reqAppellation = await Appellation.insert({data: event});
      this.tastingProfile.appellations[index] = reqAppellation.appellations[0]
    },

    /**
     * Trigger an Algolia search for grapes whenever search input has been change
     * 
     * @param index the index of grapes input on which to search
     */
    async onGrapeSearch (index) {
      let result = await this.algoliaIndexGrapes.search(this.grapeSearch[index]);
      this.searchedGrapes[index] = result.hits;
    },

    /**
     * Triggered on grape selection from algolia results
     * Request the selected grape from the api to set it on the passed grape compsoes appellation
     * 
     * @param event autocompelete input state
     * @param index the index of the corresponding grape composes appellation
     */
    async updateGrape (event, index) {
      const reqGrape = await Grape.insert({data: event})
      this.tastingProfile.grapes[index] = reqGrape.grapes[0];
      this.tastingProfile.grapes[index].grape_variety = event.grape_variety
    },
    
    
    /**
     * Triggered when the form is submitted
     * 
     */
    async submit() {
      try {
        this.dataLoaded = false;
          for (let i = this.tastingProfile.grapes.length - 1; i >= 0; i--) {
            if (!this.tastingProfile.grapes[i].id || this.tastingProfile.regions[i].id.charAt() == '$' || i > 3)
              this.tastingProfile.grapes.splice(i, 1);
          }
          for (let i = this.tastingProfile.appellations.length - 1; i >= 0; i--) {
            if (!this.tastingProfile.appellations[i].id || this.tastingProfile.appellations[i].id.charAt() == '$' || i > 3)
              this.tastingProfile.appellations.splice(i, 1);
          }
          for (let i = this.tastingProfile.regions.length - 1; i >= 0; i--) {
            if (!this.tastingProfile.regions[i].id || this.tastingProfile.regions[i].id.charAt() == '$' || i > 3)
              this.tastingProfile.regions.splice(i, 1);
          }

          await TastingProfile.api().post(`users/self/tasting-profile?lang=${this.$i18n.locale}`, this.tastingProfile, {baseURL: this.APIbaseURL});
          this.$toastr.s(this.$t("aveine-space.tastingProfile.profileSaved"));
          if (this.callback) {
            this.callback(this.tastingProfile);
            this.currentStep = 1;
          }
          
          this.dataLoaded = true;
      } catch (errors) {
        this.dataLoaded = true;
        errorHandler(errors, this)
      }
    },
   
    /**
     * Remove the selected grape from the current tasting profile
     */
    async removeGrape (index) {
      this.tastingProfile.grapes[index] = {}
    },
   
    /**
     * Remove the selected grape from the current tasting profile
     */
    async removeRegion (index) {
      this.tastingProfile.regions[index] = {}
    },
   
    /**
     * Remove the selected grape from the current tasting profile
     */
    async removeAppellation (index) {
      this.tastingProfile.appellations[index] = {}
    }
  }
}
</script>

<style lang="scss">
  div.stepper-container {
    padding: 0 !important;
  }
  span.attribute-title {
    width: 8vw;
  }

  @media all and (max-width: 720px) {
    span.attribute-title {
      width: 100vw;
    }
  }
</style>
import Vue from 'vue'
import VueRouter from 'vue-router'
import Authenticated from '@/views/Authenticated.vue'

import Pro from '@/views/authenticated/pro/Pro.vue'
import UpdateSelfAmbassador from '@/views/authenticated/pro/UpdateSelfAmbassador.vue'
import SalesRead from '@/views/authenticated/pro/Sales.vue'
import AmbassadorDashboard from '@/views/authenticated/pro/AmbassadorDashboard.vue'
import Invoice from '@/views/authenticated//pro/invoices/Invoice.vue'
import NotRegistered from '@/views/authenticated/pro/NotRegistered.vue'
import InvoiceCreate from '@/views/authenticated/pro/invoices/Create.vue'

import Consumer from '@/views/authenticated/consumer/Consumer.vue'
import ConsumerDashboard from '@/views/authenticated/consumer/Dashboard.vue'
import Wine from '@/views/authenticated/consumer/wines/Wine.vue'
import WineIndex from '@/views/authenticated/consumer/wines/Index.vue'
import WineRead from '@/views/authenticated/consumer/wines/Read.vue'
import WineFavorite from '@/views/authenticated/consumer/wines/Favorite.vue'
import WineHistory from '@/views/authenticated/consumer/wines/History.vue'
import WineScanHistory from '@/views/authenticated/consumer/wines/ScanHistory.vue'
import WineFormIndex from '@/views/authenticated/consumer/wines/WineFormIndex.vue'
import Ambassador from '@/views/authenticated/consumer/ambassadors/Ambassador.vue'
import AmbassadorRead from '@/views/authenticated/consumer/ambassadors/Read.vue'
import AmbassadorIndex from '@/views/authenticated/consumer/ambassadors/Index.vue'
import Domain from '@/views/authenticated/consumer/domains/Domain.vue'
import DomainRead from '@/views/authenticated/consumer/domains/Read.vue'
import DomainIndex from '@/views/authenticated/consumer/domains/Index.vue'
import User from '@/views/authenticated/consumer/users/User.vue'
import UserRead from '@/views/authenticated/consumer/users/Read.vue'
import UserIndex from '@/views/authenticated/consumer/users/Index.vue'
import BadgeIndex from '@/views/authenticated/consumer/users/badges.vue'

import Login from '@/views/Login.vue'
import Registration from '@/views/Registration.vue'
import PageNotFound from '@/views/PageNotFound.vue'
import { authenticationGuard } from '@/auth/authenticationGuard'
import { nonProUserGuard } from '@/auth/nonProUserGuard'

Vue.use(VueRouter)

const routes = [
  {
    path: '/authenticated',
    beforeEnter: authenticationGuard,
    component: Authenticated,
    children: [
      {
        path: 'pro',
        beforeEnter: nonProUserGuard,
        component: Pro,
        children: [
          {
            path: 'ambassadors/update/:id',
            name: 'UpdateSelfAmbassador',
            component: UpdateSelfAmbassador
          },
          {
            path: 'sales/:id',
            name: 'AmbassadorSales',
            component: SalesRead
          },
          {
            path: 'dashboard',
            name: 'AmbassadorDashboard',
            component: AmbassadorDashboard
          },
          {
            path: 'invoices/:id',
            name: 'Invoices',
            component: Invoice
          },
          {
            path: 'invoices/add/:id',
            name: 'InvoiceCreate',
            component: InvoiceCreate
          }
        ]
      },
      {
        path: 'consumer',
        name: 'Consumer',
        component: Consumer,
        children: [
          {
            path: 'dashboard',
            name: 'ConsumerDashboard',
            component: ConsumerDashboard
          },
          {
            path: 'wines',
            name: 'wines',
            component: Wine,
            children: [
              {
                path: 'list',
                name: 'WineIndex',
                component: WineIndex
              },
              {
                path: 'read/:id',
                name: 'WineRead',
                component: WineRead
              },
              {
                path: 'favorite',
                name: 'WineFavorite',
                component: WineFavorite
              },
              {
                path: 'history',
                name: 'WineHistory',
                component: WineHistory
              },
              {
                path: 'scan-history',
                name: 'WineScanHistory',
                component: WineScanHistory
              },
              {
                path: 'forms',
                name: 'WineFormIndex',
                component: WineFormIndex
              },
            ]
          },
          {
            path: 'domains',
            name: 'domains',
            component: Domain,
            children: [
              {
                path: 'list',
                name: 'DomainIndex',
                component: DomainIndex
              },
              {
                path: 'read/:id',
                name: 'DomainRead',
                component: DomainRead
              }
            ]
          },
          {
            path: 'ambassadors',
            name: 'ambassadors',
            component: Ambassador,
            children: [
              {
                path: 'list',
                name: 'AmbassadorIndex',
                component: AmbassadorIndex
              },
              {
                path: 'read/:id',
                name: 'AmbassadorRead',
                component: AmbassadorRead
              }
            ]
          },
          {
            path: 'users',
            name: 'users',
            component: User,
            children: [
              {
                path: 'list',
                name: 'UserIndex',
                component: UserIndex
              },
              {
                path: 'read/:id',
                name: 'UserRead',
                component: UserRead
              },
              {
                path: 'badges',
                name: 'BadgeIndex',
                component: BadgeIndex
              }
            ]
          }
        ]
      },
      {
        path: 'not-registered',
        name: 'NotRegistered',
        component: NotRegistered
      },
    ]
  },
  {
    path: '/login',
    alias: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/registration/:invitation_type/:id',
    name: 'Registration',
    component: Registration
  },
  { path: "*", component: PageNotFound }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

import { getInstance } from './index';

export const authenticationGuard = async (to, from, next) => {
  const authService = getInstance();
  const guardAction = () => {
    if (authService.isAuthenticated) {
      return next();
    }

    authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
  };

  // If the Auth0Plugin has loaded already, check the authentication state
  if (!authService.loading && authService.roleLoaded) {
    return guardAction();
  }

  authService.$watch('roleLoaded', (roleLoaded) => {
    if (roleLoaded) {
      return guardAction();
    }
  });
};
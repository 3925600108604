<template>
  <v-container id="aveine-user-list" class="px-0">
    <v-list v-if="users.length > 0 && dataLoaded" class="px-0" flat>
      <v-list-item
        class="px-0" 
        v-for="(user, i) in users"
        :key="i"
      >
        <v-list-item-content class="d-flex flex-xl-row flex-lg-row flex-column justify-space-between">
          <v-row class="info-container d-flex flex-row justify-xl-center justify-lg-center my-3">
            <v-col class="d-flex justify-center pa-0" xl="2" cols="3">
              <v-avatar v-if="user.profile.avatar" class="icon-container align-center justify-start" size="50px">
                <img class="ambassador-img" :src="user.profile.avatar"/>
              </v-avatar>
            </v-col>
            <v-col class="d-flex flex-column mx-2" cols="8">
              <v-row class="d-flex flex-row justify-space-between">
                <v-col class="pa-0 mb-6 " cols="6">
                  <a @click="$router.push({ name: 'UserRead', params: { id: user.id }}); callback()" class="font-weight-bold mb-3">
                    {{user.profile.name}}<br>
                  </a>
                  <p class="font-weight-light mt-2">
                    {{user.profile.roles[0] == "ambassador" ? "Ambassadeur" : (user.profile.roles[0] == "domain" ? "Domaine" : user.profile.roles[0] == "aveine" ? "Staff Aveine": null) }}
                  </p> 
                </v-col>
                <v-col class="pa-0" cols="4">
                  <v-btn class="toggle-follow-btn" v-if="user.followable == 'true' && user.followed == 'false'" outlined @click="toggleFollow(i)">{{$t("aveine-space.user.follow")}}</v-btn>
                  <v-btn class="toggle-follow-btn" color="secondary" v-else-if="user.followable == 'true' && user.followed == 'true'" outlined @click="toggleFollow(i)">{{$t("aveine-space.user.unfollow")}}</v-btn>
                </v-col>
              </v-row>
              <v-row class="mt-0 mr-5">
                <span class="font-weight-light">{{user.profile.description}}</span>
              </v-row>
            </v-col>
          </v-row>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <div v-else-if="dataLoaded && users.length == 0">
      <div class="d-flex flex-column ma-16 justify-center align-center">
        <v-icon class="mx-16 mt-16" large color="primary">mdi-close-circle</v-icon>
        <h4 class="text-center mx-16 mb-16">
          {{$t("aveine-space.user.noResultFound")}}
        </h4>
      </div>
    </div>
    <div v-else class="d-flex justify-center align-center mt-16">
      <v-progress-circular
        indeterminate
        :size="100"
        :width="10"
        color="secondary"
      ></v-progress-circular>
    </div>
  </v-container>
</template>
<script>
import { UserProfile } from 'aveine-toolbox'
import { errorHandler } from 'aveine-toolbox'
export default {
  props: {
    /**
     * The list of loaded recent invoices
     * 
     * @type {Array}
     */
    users: Array,
    /**
     * Callback function from the parent component
     * 
     * @type {String}
     */
     callback: Function,
    /**
     * The base URL of the API used for calls in the current instantiation of the component
     * 
     * @type {String}
     */
    APIbaseURL: String
  },
  data() {
    return {
      /**
       * Flag determining if all of the data is fully loaded
       * 
       * @type {Boolean}
       */
      dataLoaded: false
    }
  },
  async mounted() {
    this.dataLoaded = false
    await Promise.all(this.users.map(async (user, index) => {
      const userFollowable = await UserProfile.api().get(`users/${user.id}/followable?lang=${this.$i18n.locale}`, {baseURL: this.APIbaseURL});
      this.users[index].followable = userFollowable.response.request.response;
      const userFollowed = await UserProfile.api().get(`users/${user.id}/followed?lang=${this.$i18n.locale}`, {baseURL: this.APIbaseURL});
      this.users[index].followed = userFollowed.response.request.response;
    }))
    this.dataLoaded = true
  },
  methods: {
    async toggleFollow (index) {
      try {
        if (this.users[index].followed == "false") {
          await UserProfile.api().post(`users/${this.users[index].id}/follow?lang=${this.$i18n.locale}`, null, {baseURL: this.APIbaseURL});
          this.users[index].followed = "true"; 
          this.$set(this.users, index,  this.users[index])
        } else {
          await UserProfile.api().delete(`users/${this.users[index].id}/unfollow?lang=${this.$i18n.locale}`, {baseURL: this.APIbaseURL});
          this.users[index].followed = "false"; 
          this.$set(this.users, index, this.users[index] )
        }
      } catch (errors) {
        this.dataLoaded = true;
        errorHandler(errors, this)
      }
    }  
  },
}
</script>

<style lang="scss" scoped>
  div#aveine-user-list {
    max-width: 30vw;
    h3 {
      font-size: 20px;
      font-weight: bolder;
      text-transform: uppercase;
      letter-spacing: 2px;
    }

    div.info-container {
      width: 22vw
    }

    @media only screen and (max-width: 600px) {
      div.info-container {
        width: 98vw
      }
      .toggle-follow-btn {
        font-size: 8px;
      }
    }
  }
</style>
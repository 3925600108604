import { getInstance } from './index';

export const nonProUserGuard = async (to, from, next) => {
  const authService = getInstance();
  const guardAction = () => {
    if (authService.isAuthenticated) {
      if (authService.ambassador && authService.ambassador.id) {
        return next();
      } else {
        return next({ path: "authenticated/consumer/dashboard" })
      }
    }
    authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
  };

  // If the Auth0Plugin has loaded already, check the authentication state
  if (authService.roleLoaded) {
    return guardAction();
  }

  authService.$watch('roleLoaded', (roleLoaded) => {
    if (roleLoaded === true) {
      return guardAction();
    }
  });
};